import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import HoverableChip from "../HoverableChip";
import AlertDialog from "../AlertDialog";
import LoadingAutoComplete from "../LoadingAutoComplete";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  addCptDiagnosisCodeFunc,
  removeCptDiagnosisCodeFunc,
} from "../../actions/CptCodesActions";
import Cookies from "js-cookie";

const AddDiagnosisCodeSection = ({ diagnosticCodesArr, cptCodeId }) => {
  const [chips, setChips] = useState(diagnosticCodesArr || []);
  const [singleChip, setSingleChip] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [modalContentData, setModalContentData] = useState({});
  const [openDiagnosisAddModal, setOpenDiagnosisAddModal] = useState(false);
  const [medicalCode, setMedicalCode] = useState([]);

  const dispatch = useDispatch();
  const agencyId = Cookies.get("agency_id_vita");

  const removeCptDiagnosisCode = useSelector(
    (state) => state.removeCptDiagnosisCode
  );
  const {
    loading: loadingRemoveDiagnosisCode,
    error,
    removedCptDiagnosisCode,
  } = removeCptDiagnosisCode;

  const addCptDiagnosticCodes = useSelector(
    (state) => state.addCptDiagnosticCodes
  );
  const {
    loading: loadingAddDiagnosticCode,
    error: errorAddCptDiagnosticCode,
    addedCptDiagnosticCodes,
  } = addCptDiagnosticCodes;

  const openDeleteModalHandler = (chip) => {
    setSingleChip(chip);
    setOpenDeleteModal(true);
    setModalContentData({
      title: "Are you sure?",
      subtitle: `The diagnosis code (${chip?.code} - ${chip?.description}) will be removed.`,
    });
  };

  const openDiagnosisAddModalHandler = () => {
    setOpenDiagnosisAddModal(true);
  };

  const addDiagnosisCodesHandler = () => {
    const diagnosisCodeObj = {
      agency_id: agencyId,
      cpt_code_id: cptCodeId,
      diagnostic_codes: medicalCode,
    };

    dispatch(addCptDiagnosisCodeFunc(diagnosisCodeObj));
  };

  useEffect(() => {
    if (addedCptDiagnosticCodes?.ok) {
      setOpenDiagnosisAddModal(false);
    }
  }, [addedCptDiagnosticCodes]);

  const deleteCptDiagnosisCodeHandler = () => {
    const cptObj = {
      diagnostic_code_id: singleChip?.id,
    };

    dispatch(removeCptDiagnosisCodeFunc(cptObj));
  };

  useEffect(() => {
    if (removedCptDiagnosisCode?.ok) {
      setOpenDeleteModal(false);
    }
  }, [removedCptDiagnosisCode]);

  useEffect(() => {
    setChips(diagnosticCodesArr);
  }, [diagnosticCodesArr]);

  return (
    <>
      <AlertDialog
        submitID="confirm-delete-diagnosis"
        openModal={openDeleteModal}
        isLoading={loadingRemoveDiagnosisCode}
        closeModal={() => setOpenDeleteModal(false)}
        data={modalContentData}
        formSubmitFunction={deleteCptDiagnosisCodeHandler}
        color="error"
      />

      <AlertDialog
        submitID="add-diagnosis-modal"
        isLoading={loadingAddDiagnosticCode}
        disabled={!medicalCode?.length}
        openModal={openDiagnosisAddModal}
        closeModal={() => {
          setOpenDiagnosisAddModal(false);
          setMedicalCode([]);
        }}
        data={{ title: "Add Diagnosis Code" }}
        submitText="Add"
        formSubmitFunction={addDiagnosisCodesHandler}
      >
        <LoadingAutoComplete
          medicalCode={medicalCode}
          setMedicalCode={setMedicalCode}
        />
      </AlertDialog>

      <Stack gap="16px">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
        >
          <Typography
            variant="body1"
            fontWeight={500}
            textTransform="uppercase"
            color="secondary"
          >
            Diagnosis Code
          </Typography>

          <Button
            variant="text"
            startIcon={<AddIcon />}
            sx={{ fontSize: "13px" }}
            onClick={openDiagnosisAddModalHandler}
          >
            Add
          </Button>
        </Box>
        {chips?.length === 0 ? (
          "-"
        ) : (
          <Box display="flex" gap="10px" flexWrap="wrap" overflow="auto">
            {chips?.map((chip) => (
              <HoverableChip
                key={chip?.id}
                label={`${chip?.code} - ${chip?.description}`}
                onDelete={() => openDeleteModalHandler(chip)}
              />
            ))}
          </Box>
        )}
      </Stack>
    </>
  );
};

export default AddDiagnosisCodeSection;
