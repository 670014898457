// /step-count/get

import {
  GET_MEDICINE_FAIL,
  GET_MEDICINE_REQUEST,
  GET_MEDICINE_SUCCESS,
  GET_STEPSCOUNT_FAIL,
  GET_STEPSCOUNT_REQUEST,
  GET_STEPSCOUNT_SUCCESS,
  RESET_MEDICINE,
  RESET_STEPSCOUNT,
} from "../../assets/constants/stepsCountConstants";

// GET STEPS COUNTREDUCER
export const getStepsCountReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STEPSCOUNT_REQUEST:
      return { loading: true };
    case GET_STEPSCOUNT_SUCCESS:
      return { loading: false, stepsCount: action.payload };
    case GET_STEPSCOUNT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_STEPSCOUNT:
      return {};
    default:
      return state;
  }
};

// GET MEDICINES REDUCER
export const getMedicinesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MEDICINE_REQUEST:
      return { loading: true };
    case GET_MEDICINE_SUCCESS:
      return { loading: false, medicineStats: action.payload };
    case GET_MEDICINE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_MEDICINE:
      return {};
    default:
      return state;
  }
};
