import {
  CPT_INIT_SETUP_FAIL,
  CPT_INIT_SETUP_REQUEST,
  CPT_INIT_SETUP_SUCCESS,
  EXPORT_REPORT_FAIL,
  EXPORT_REPORT_REQUEST,
  EXPORT_REPORT_SUCCESS,
  GET_CPT_CODES_FAIL,
  GET_CPT_CODES_REQUEST,
  GET_CPT_CODES_SUCCESS,
  REMOVE_CPT_DIAGNOSIS_CODE_FAIL,
  REMOVE_CPT_DIAGNOSIS_CODE_REQUEST,
  REMOVE_CPT_DIAGNOSIS_CODE_SUCCESS,
  RESET_CPT_CODES,
  RESET_CPT_INIT_SETUP,
  RESET_EXPORT_REPORT,
  RESET_REMOVE_CPT_DIAGNOSIS_CODE,
  RESET_UPDATE_CPT_CODE,
  UPDATE_CPT_CODES_FAIL,
  UPDATE_CPT_CODES_REQUEST,
  UPDATE_CPT_CODES_SUCCESS,
} from "../../assets/constants/cptCodesConstants";
import {
  ADD_CPT_DIAGNOSIS_CODE_FAIL,
  ADD_CPT_DIAGNOSIS_CODE_REQUEST,
  ADD_CPT_DIAGNOSIS_CODE_SUCCESS,
  RESET_ADD_CPT_DIAGNOSIS_CODE,
} from "../../assets/constants/diagnosisConstants";

// INIT CPT CODE SETUP REDUCER
export const initCptSetupReducer = (state = {}, action) => {
  switch (action.type) {
    case CPT_INIT_SETUP_REQUEST:
      return { loading: true };
    case CPT_INIT_SETUP_SUCCESS:
      return { loading: false, cptSetup: action.payload };
    case CPT_INIT_SETUP_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CPT_INIT_SETUP:
      return {};
    default:
      return state;
  }
};

// GET CPT CODES REDUCER
export const getCptCodesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CPT_CODES_REQUEST:
      return { loading: true };
    case GET_CPT_CODES_SUCCESS:
      return { loading: false, allCptCodes: action.payload };
    case GET_CPT_CODES_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CPT_CODES:
      return {};
    default:
      return state;
  }
};

// UPDATE CPT CODE REDUCER
export const updateCptCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CPT_CODES_REQUEST:
      return { loading: true };
    case UPDATE_CPT_CODES_SUCCESS:
      return { loading: false, updatedCptCode: action.payload };
    case UPDATE_CPT_CODES_FAIL:
      return { loading: false, error: action.payload };
    case RESET_UPDATE_CPT_CODE:
      return {};
    default:
      return state;
  }
};

// APP CPT DIAGNOSIS CODE REDUCER
export const addCptDiagnosticCodesReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_CPT_DIAGNOSIS_CODE_REQUEST:
      return { loading: true };
    case ADD_CPT_DIAGNOSIS_CODE_SUCCESS:
      return { loading: false, addedCptDiagnosticCodes: action.payload };
    case ADD_CPT_DIAGNOSIS_CODE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ADD_CPT_DIAGNOSIS_CODE:
      return {};
    default:
      return state;
  }
};

// REMOVE CPT DIAGNOSIS CODE REDUCER
export const removeCptDiagnosisCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_CPT_DIAGNOSIS_CODE_REQUEST:
      return { loading: true };
    case REMOVE_CPT_DIAGNOSIS_CODE_SUCCESS:
      return { loading: false, removedCptDiagnosisCode: action.payload };
    case REMOVE_CPT_DIAGNOSIS_CODE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_REMOVE_CPT_DIAGNOSIS_CODE:
      return {};
    default:
      return state;
  }
};

// EXPORT REPORT  REDUCER
export const exportReportReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPORT_REPORT_REQUEST:
      return { loading: true };
    case EXPORT_REPORT_SUCCESS:
      return { loading: false, downloadedReport: action.payload };
    case EXPORT_REPORT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_EXPORT_REPORT:
      return {};

    default:
      return state;
  }
};
