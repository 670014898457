import { Box } from "@mui/material";
import bgImage from "../../assets/images/bgImage.png";

const bgImageStyles = {
  backgroundImage: `url(${bgImage}) `,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center top",
  backgroundAttachment: "fixed",
  height: "max-content",
  minHeight: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "40px",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px",
};

// const Styles = makeStyles((theme) => Style(theme));

const BgBox = ({ children }) => {
  // const classes = Styles();
  return (
    <Box component="div" style={bgImageStyles}>
      {children}
    </Box>
  );
};

export default BgBox;
