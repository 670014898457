import dayjs from "dayjs";
import {
  ALERT_NOTIFICATIONS_FAIL,
  ALERT_NOTIFICATIONS_REQUEST,
  ALERT_NOTIFICATIONS_SUCCESS,
  ALL_NOTIFICATIONS_FAIL,
  ALL_NOTIFICATIONS_REQUEST,
  ALL_NOTIFICATIONS_SUCCESS,
  CHANGED_AGENCY_ID,
  CHAT_NOTIFICATIONS_FAIL,
  CHAT_NOTIFICATIONS_REQUEST,
  CHAT_NOTIFICATIONS_SUCCESS,
  CLOSE_TIME_MODAL,
  CREATE_CUSTOM_NOTE_FAIL,
  CREATE_CUSTOM_NOTE_REQUEST,
  CREATE_CUSTOM_NOTE_SUCCESS,
  DISABLE_APP_ACCESS_FAIL,
  DISABLE_APP_ACCESS_REQUEST,
  DISABLE_APP_ACCESS_SUCCESS,
  ENABLE_APP_ACCESS_FAIL,
  ENABLE_APP_ACCESS_REQUEST,
  ENABLE_APP_ACCESS_SUCCESS,
  GET_BODY_PARTS_FAIL,
  GET_BODY_PARTS_REQUEST,
  GET_BODY_PARTS_SUCCESS,
  GET_CASE_SUMMARY_LINK_FAIL,
  GET_CASE_SUMMARY_LINK_REQUEST,
  GET_CASE_SUMMARY_LINK_SUCCESS,
  GET_EXPENSE_DETAIL_FAIL,
  GET_EXPENSE_DETAIL_REQUEST,
  GET_EXPENSE_DETAIL_SUCCESS,
  MARK_NOTIFICATIONS_FAIL,
  MARK_NOTIFICATIONS_REQUEST,
  MARK_NOTIFICATIONS_SUCCESS,
  OPEN_TIME_MODAL,
  RESET_ALERT_NOTIFICATIONS,
  RESET_ALL_NOTIFICATIONS,
  RESET_APP_ACCESS_STATE,
  RESET_BODY_PARTS,
  RESET_CASE_SUMMARY_LINK,
  RESET_CHAT_NOTIFICATIONS,
  RESET_CREATE_CUSTOM_NOTE,
  RESET_GET_EXPENSE_DETAIL,
  RESET_MARK_NOTIFICATIONS,
  RESET_SEND_PAIN_LOG_REMINDER,
  RESET_TIMER_ITEMS,
  RESET_USER_ACTIVITY_NOTIFICATIONS,
  RESET_USER_FEED_NOTIFICATIONS,
  SEND_PAIN_LOG_REMINDER_FAIL,
  SEND_PAIN_LOG_REMINDER_REQUEST,
  SEND_PAIN_LOG_REMINDER_SUCCESS,
  SET_CLICKED_LOGOUT,
  SET_COMM_INTERACTION,
  SET_DATE_VALUE,
  SET_ELAPSED_TIME,
  SET_END_TIME,
  SET_GO_BACK,
  SET_IS_PAUSED,
  SET_IS_RUNNING,
  SET_IS_STOPPED,
  SET_LOG_TYPE_VALUE,
  SET_NAVIGATE_ITEM,
  SET_NOTES_VALUE,
  SET_PATIENT_ID,
  SET_PAUSED_START_TIME,
  SET_PAUSED_TIME,
  SET_PREVIOUS_PAGE,
  SET_START_TIME,
  SET_TIME_CHANGED,
  USER_ACTIVITY_NOTIFICATIONS_FAIL,
  USER_ACTIVITY_NOTIFICATIONS_REQUEST,
  USER_ACTIVITY_NOTIFICATIONS_SUCCESS,
  USER_FEED_NOTIFICATIONS_FAIL,
  USER_FEED_NOTIFICATIONS_REQUEST,
  USER_FEED_NOTIFICATIONS_SUCCESS,
} from "../../assets/constants/appConstants";

// OPEN CLOSE TIME MODAL
const initModalState = {
  openModal: false,
  startTimeValue: null,
  endTimeValue: null,
  logTypeValue: "Therapeutic Data Review",
  dateValue: dayjs(),
  notesValue: "",
  communicationInteraction: false,
  isRunning: false,
  isPaused: false,
  elapsedTime: 0,
  goBackClicked: false,
  pausedTime: 0,
  pausedStartTime: null,
  navigateItem: null,
  isStopped: false,
  previousPage: false,
  logoutClicked: false,
  patientId: null,
  timeChanged: false,
};

export const timerReducer = (state = initModalState, action) => {
  switch (action.type) {
    case OPEN_TIME_MODAL:
      return {
        ...state,
        openModal: true,
        logTypeValue: "Therapeutic Data Review",
        dateValue: dayjs(),
        notesValue: "",
        communicationInteraction: false,
      };
    case CLOSE_TIME_MODAL:
      return {
        ...state,
        openModal: false,
      };
    case SET_ELAPSED_TIME:
      return { ...state, elapsedTime: action.payload };

    case SET_START_TIME:
      return {
        ...state,
        startTimeValue: action.payload,
        // isRunning: true,
      };

    case SET_END_TIME:
      return { ...state, endTimeValue: action.payload };

    case SET_TIME_CHANGED:
      return { ...state, timeChanged: action.payload };

    case SET_NAVIGATE_ITEM:
      return { ...state, navigateItem: action.payload };

    case SET_PAUSED_TIME:
      return { ...state, pausedTime: action.payload };

    case SET_PAUSED_START_TIME:
      return { ...state, pausedStartTime: action.payload };

    case SET_LOG_TYPE_VALUE:
      return { ...state, logTypeValue: action.payload };

    case SET_DATE_VALUE:
      return { ...state, dateValue: action.payload };

    case SET_NOTES_VALUE:
      return { ...state, notesValue: action.payload };

    case SET_COMM_INTERACTION:
      return { ...state, communicationInteraction: action.payload };

    case SET_GO_BACK:
      return { ...state, goBackClicked: action.payload };

    case SET_PREVIOUS_PAGE:
      return { ...state, previousPage: action.payload };

    case SET_IS_RUNNING:
      return { ...state, isRunning: action.payload };

    case SET_IS_PAUSED:
      return { ...state, isPaused: action.payload };

    case SET_PATIENT_ID:
      return { ...state, patientId: action.payload };

    case SET_IS_STOPPED:
      return { ...state, isStopped: action.payload };
    case SET_CLICKED_LOGOUT:
      return { ...state, logoutClicked: action.payload };

    case RESET_TIMER_ITEMS:
      return {
        openModal: false,
        startTimeValue: null,
        endTimeValue: null,
        timeChanged: false,
        logTypeValue: "Therapeutic Data Review",
        dateValue: dayjs(),
        notesValue: "",
        communicationInteraction: false,
        isRunning: false,
        isPaused: false,
        elapsedTime: 0,
        goBackClicked: false,
        pausedTime: 0,
        pausedStartTime: null,
        navigateItem: null,
        isStopped: false,
        logoutClicked: false,
        previousPage: false,
        patientId: null,
      };

    default:
      return {
        ...state,
      };
  }
};

export const changedAgencyIdReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGED_AGENCY_ID:
      return { changed: action.payload };

    default:
      return { changed: false };
  }
};

// DISABLE APP ACCESS REDUCER
export const disableAppAccessReducer = (state = {}, action) => {
  switch (action.type) {
    case DISABLE_APP_ACCESS_REQUEST:
      return { loading: true };
    case DISABLE_APP_ACCESS_SUCCESS:
      return { loading: false, disabledApp: action.payload };
    case DISABLE_APP_ACCESS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_APP_ACCESS_STATE:
      return {};
    default:
      return state;
  }
};

// ENABLE APP ACCESS REDUCER
export const enableAppAccessReducer = (state = {}, action) => {
  switch (action.type) {
    case ENABLE_APP_ACCESS_REQUEST:
      return { loading: true };
    case ENABLE_APP_ACCESS_SUCCESS:
      return { loading: false, enabledApp: action.payload };
    case ENABLE_APP_ACCESS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_APP_ACCESS_STATE:
      return {};
    default:
      return state;
  }
};

// DELETE DOCUMENT REDUCER
const initDeleteDocumentState = {
  loading: false,
  deletedDocument: null,
  error: null,
};

// GET USER FEED NOTIFICATIONS REDUCER
export const getUserFeedNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_FEED_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case USER_FEED_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        userFeeds: action.payload,
      };
    case USER_FEED_NOTIFICATIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_USER_FEED_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
};

// GET ALL NOTIFICATIONS REDUCER
export const getAllNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allNotifications: action.payload,
      };
    case ALL_NOTIFICATIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_ALL_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
};

// GET CHAT NOTIFICATIONS REDUCER
export const getChatNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case CHAT_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case CHAT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        chatNotifications: action.payload,
      };
    case CHAT_NOTIFICATIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_CHAT_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
};

// GET ALERT NOTIFICATIONS REDUCER
export const getAlertNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case ALERT_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case ALERT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        alertNotifications: action.payload,
      };
    case ALERT_NOTIFICATIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_ALERT_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
};

// GET USER ACTIVITY NOTIFICATIONS REDUCER
export const getUserActivityNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACTIVITY_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case USER_ACTIVITY_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        userActivityNotifications: action.payload,
      };
    case USER_ACTIVITY_NOTIFICATIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_USER_ACTIVITY_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
};

// MARK NOTIFICATIONS REDUCER
export const markAsReadNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case MARK_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case MARK_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        markedNotifications: action.payload,
      };
    case MARK_NOTIFICATIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_MARK_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
};

// GET CASE SUMMARY LINK REDUCER
const initCaseSummaryState = {
  loading: false,
  error: null,
  caseSummaryPdfLink: {},
};
export const getCaseSummaryLinkReducer = (
  state = initCaseSummaryState,
  action
) => {
  switch (action.type) {
    case GET_CASE_SUMMARY_LINK_REQUEST:
      return { ...state, loading: true };
    case GET_CASE_SUMMARY_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        caseSummaryPdfLink: action.payload,
      };
    case GET_CASE_SUMMARY_LINK_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_CASE_SUMMARY_LINK:
      return { ...state, caseSummaryPdfLink: {} };
    default:
      return state;
  }
};

// GET BODY PARTS REDUCER
const initState = {
  loading: false,
  error: null,
  allBodyParts: null,
};
export const getBodyPartsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_BODY_PARTS_REQUEST:
      return { ...state, loading: true };
    case GET_BODY_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allBodyParts: action.payload,
      };
    case GET_BODY_PARTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_BODY_PARTS:
      return {};
    default:
      return state;
  }
};

// SEND MISSED PAIN LOG REMINDERS
export const sendPainLogReminderReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_PAIN_LOG_REMINDER_REQUEST:
      return { loading: true };
    case SEND_PAIN_LOG_REMINDER_SUCCESS:
      return {
        loading: false,
        sentReminder: action.payload,
      };
    case SEND_PAIN_LOG_REMINDER_FAIL:
      return { loading: false, error: action.payload };
    case RESET_SEND_PAIN_LOG_REMINDER:
      return {};
    default:
      return state;
  }
};

// GET EXPENSES REDUCER
export const getExpensesDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_EXPENSE_DETAIL_REQUEST:
      return { loading: true };
    case GET_EXPENSE_DETAIL_SUCCESS:
      return {
        loading: false,
        expenses: action.payload,
      };
    case GET_EXPENSE_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case RESET_GET_EXPENSE_DETAIL:
      return {};
    default:
      return state;
  }
};

// CREATE CUSTOM NOTES REDUCER
export const createCustomNotesReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CUSTOM_NOTE_REQUEST:
      return { loading: true };
    case CREATE_CUSTOM_NOTE_SUCCESS:
      return {
        loading: false,
        customNotes: action.payload,
      };
    case CREATE_CUSTOM_NOTE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CREATE_CUSTOM_NOTE:
      return {};
    default:
      return state;
  }
};
