export const GET_DIAGNOSIS_OPTIONS_REQUEST = "GET_DIAGNOSIS_OPTIONS_REQUEST";
export const GET_DIAGNOSIS_OPTIONS_SUCCESS = "GET_DIAGNOSIS_OPTIONS_SUCCESS";
export const GET_DIAGNOSIS_OPTIONS_FAIL = "GET_DIAGNOSIS_OPTIONS_FAIL";

export const ADD_DIAGNOSIS_CODE_REQUEST = "ADD_DIAGNOSIS_CODE_REQUEST";
export const ADD_DIAGNOSIS_CODE_SUCCESS = "ADD_DIAGNOSIS_CODE_SUCCESS";
export const ADD_DIAGNOSIS_CODE_FAIL = "ADD_DIAGNOSIS_CODE_FAIL";
export const RESET_ADD_DIAGNOSIS_CODE = "RESET_ADD_DIAGNOSIS_CODE";

// CPT CODE DIAGNOSTIC CODES
export const ADD_CPT_DIAGNOSIS_CODE_REQUEST = "ADD_CPT_DIAGNOSIS_CODE_REQUEST";
export const ADD_CPT_DIAGNOSIS_CODE_SUCCESS = "ADD_CPT_DIAGNOSIS_CODE_SUCCESS";
export const ADD_CPT_DIAGNOSIS_CODE_FAIL = "ADD_CPT_DIAGNOSIS_CODE_FAIL";
export const RESET_ADD_CPT_DIAGNOSIS_CODE = "RESET_ADD_CPT_DIAGNOSIS_CODE";

export const REMOVE_DIAGNOSIS_CODE_REQUEST = "REMOVE_DIAGNOSIS_CODE_REQUEST";
export const REMOVE_DIAGNOSIS_CODE_SUCCESS = "REMOVE_DIAGNOSIS_CODE_SUCCESS";
export const REMOVE_DIAGNOSIS_CODE_FAIL = "REMOVE_DIAGNOSIS_CODE_FAIL";
export const RESET_REMOVE_DIAGNOSIS_CODE = "RESET_REMOVE_DIAGNOSIS_CODE";
