import { Alert, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { EmailMarketingIcon, SmsIcon } from "../../assets/constants";
import CallIcon from "../../icons/CallIcon";
import { LoadingButton } from "@mui/lab";
import AppContactDetailCard from "../AppContactDetailCard";

const PendingIconContent = ({ patientDetail }) => {
  return (
    <Stack gap="24px">
      <Alert severity="warning">
        {patientDetail?.name} has not completed the app signup yet. Please
        remind them to finish the registration process to begin monitoring their
        health.
      </Alert>
      <AppContactDetailCard patientDetail={patientDetail} status="pending" />
    </Stack>
  );
};

export default PendingIconContent;
