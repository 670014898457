import {
  CREATE_ORGANIZATION_FAIL,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  RESET_CREATE_ORGANIZATION,
} from "../../assets/constants/organizationConstants";

//================   CREATE ORGANIZATION REDUCER ================
export const createOrganizationReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ORGANIZATION_REQUEST:
      return { loading: true };
    case CREATE_ORGANIZATION_SUCCESS:
      return { loading: false, organization: action.payload };
    case CREATE_ORGANIZATION_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CREATE_ORGANIZATION:
      return {};
    default:
      return state;
  }
};
