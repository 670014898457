import React from "react";
import { Avatar, Box } from "@mui/material";
import styled from "@emotion/styled";

const StyledBox = styled(Box)(() => ({
  "& img": {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
  },
}));

const ProfilePicture = ({ src, alt, width, height }) => {
  return (
    <StyledBox borderRadius="50%" component="div" border>
      <Avatar src={src} alt={alt} sx={{ width: width, height: height }} />
    </StyledBox>
  );
};

export default ProfilePicture;
