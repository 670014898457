import React from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Cpt98975Content from "../Cpt98975Content";
import Cpt98980Content from "../Cpt98980Content";
import { getCptCodeSubtitleText } from "../../utils/helpers";
import AddDiagnosisCodeSection from "../AddDiagnosisCodeSection";
import Cpt98977Content from "../Cpt98977Content";
import { useSelector } from "react-redux";

const CptDrawerContent = ({
  cptCodeDetail,
  patientDetail,
  onClose,
  selectValue,
  allCptCodesArr,
}) => {
  const updateCptCode = useSelector((state) => state.updateCptCode);
  const { loading: loadingUpdatedCptCode, error } = updateCptCode;

  const cptDiagnosticArrs = allCptCodesArr?.find(
    (item) => item?.id === cptCodeDetail?.id
  );

  return (
    <>
      <Stack flex="1" sx={{ overflowY: "auto" }} gap="32px" height="100%">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
          paddingBottom="32px"
        >
          <Stack>
            <Typography variant="h5">
              CPT Code {cptCodeDetail?.cpt_code}
            </Typography>
            <Typography variant="body1" color="secondary">
              {getCptCodeSubtitleText(cptCodeDetail?.cpt_code)}
            </Typography>
          </Stack>
          <IconButton
            size="small"
            onClick={loadingUpdatedCptCode ? null : onClose}
          >
            <CancelOutlinedIcon />
          </IconButton>
        </Box>

        {/* DIAGNOSIS CODE  */}
        {(cptCodeDetail?.cpt_code === "98977" ||
          cptCodeDetail?.cpt_code === "98975") && (
          <AddDiagnosisCodeSection
            diagnosticCodesArr={cptDiagnosticArrs?.diagnostic_codes}
            cptCodeId={cptCodeDetail?.id}
          />
        )}

        {cptCodeDetail?.cpt_code === "98977" && (
          <Cpt98977Content cptCodeDetail={cptCodeDetail} />
        )}

        {cptCodeDetail?.cpt_code === "98975" && (
          <Cpt98975Content patientDetail={patientDetail} />
        )}

        {(cptCodeDetail?.cpt_code === "98980" ||
          cptCodeDetail?.cpt_code === "98981") && (
          <Cpt98980Content
            cptCodeDetail={cptCodeDetail}
            selectValue={selectValue}
            onClose={onClose}
            patientDetail={patientDetail}
          />
        )}
      </Stack>
    </>
  );
};

export default CptDrawerContent;
