import React, { useState } from "react";
import { Chip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const HoverableChip = ({ label, onDelete }) => {
  return (
    <Chip
      label={label}
      onDelete={onDelete}
      sx={{
        cursor: "pointer",
        "& .MuiChip-deleteIcon": {
          display: "none",
        },
        "&:hover": {
          "& .MuiChip-deleteIcon": {
            display: "block",
          },
        },
      }}
    />
  );
};

export default HoverableChip;
