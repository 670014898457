import React from "react";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import Textfield from "../../common/Textfield";

const AddPractiseContent = ({
  email,
  emailChangeHandler,
  emailError,
  practiseName,
  setPractiseName,
  ownerName,
  setOwnerName,
}) => {
  return (
    <Stack gap="16px">
      <Textfield
        value={practiseName}
        onChange={(e) => setPractiseName(e.target.value)}
        label="Practice name"
        placeholder="Health care experts"
        variant="outlined"
        name="practise_name"
      />
      <Textfield
        value={ownerName}
        onChange={(e) => setOwnerName(e.target.value)}
        label="Owner's Name"
        placeholder="John Doe"
        variant="outlined"
        name="owner_name"
      />
      <Textfield
        value={email}
        onChange={emailChangeHandler}
        label="Owner's Email"
        placeholder="example@gmail.com"
        variant="outlined"
        name="email"
        errorContent={emailError && "Enter valid email"}
        helperText={emailError && "Enter valid email"}
      />

      <Typography variant="body2" color="secondary" fontSize="15px">
        Password will be automatically generated and sent to your email address.
      </Typography>
    </Stack>
  );
};

export default AddPractiseContent;
