import Cookies from "js-cookie";
import {
  GET_NOTIFICATIONS_LIST_FAIL,
  GET_NOTIFICATIONS_LIST_REQUEST,
  GET_NOTIFICATIONS_LIST_SUCCESS,
  GET_REFERRALS_BYAGENCY_FAIL,
  GET_REFERRALS_BYAGENCY_REQUEST,
  GET_REFERRALS_BYAGENCY_SUCCESS,
  POST_NOTIFICATION_FAIL,
  POST_NOTIFICATION_REQUEST,
  POST_NOTIFICATION_SUCCESS,
} from "../../assets/constants/referralsConstants";
import axios from "axios";
import { BASE_URL } from "..";

// ================== GET REFERRALS LIST ACTION =========================
export const getReferralsListFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_REFERRALS_BYAGENCY_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/referral/get-by-agency?agency_id=${agencyId}`,
        config
      );

      // console.log("RES", response);

      dispatch({
        type: GET_REFERRALS_BYAGENCY_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_REFERRALS_BYAGENCY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET NOTIFICATIONS LIST ACTION =========================
export const getNotificationsListFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_NOTIFICATIONS_LIST_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/notification/get-by-agency?agency_id=${agencyId}`,
        config
      );

      // console.log("RES", response);

      dispatch({
        type: GET_NOTIFICATIONS_LIST_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_NOTIFICATIONS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== POST NOTIFICATION ACTION =========================
export const postNotificationFunc = (notificationData) => async (dispatch) => {
  try {
    dispatch({ type: POST_NOTIFICATION_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/notification/send-notification`,
        notificationData,
        config
      );

      // console.log("RES", response);

      dispatch({
        type: POST_NOTIFICATION_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: POST_NOTIFICATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetPostNotification = () => async (dispatch) => {
  dispatch({
    type: POST_NOTIFICATION_SUCCESS,
    payload: { ok: false },
  });
};
