import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#fff",
      main: "#2196F3",
      dark: "#0C80F3",
      contrastText: "#fff",
      success: "#31c274",
      heading: "#29264A",
    },
    primaryButton: {
      main: "rgba(105, 33, 196, .1)",
      contrastText: "#00a66e",
    },
    primarylight: {
      main: "#00a66e",
      contrastText: "#fff",
    },
    cancelButton: {
      main: "rgba(109, 125, 139, 0.3)",
      contrastText: "#6D7D8B",
    },
    disabledButton: {
      main: "rgba(109, 125, 139, 0.2)",
      contrastText: "rgba(109, 125, 139, 0.4)",
    },
    secondary: {
      main: "#566875",
    },
    secondaryButton: {
      main: "#FFD9C8",
      contrastText: "#ff6019",
    },
    warningButton: {
      main: "rgba(255, 25, 25, 0.2)",
      contrastText: "#ff3636",
    },
    success: {
      main: "#3DC583",
    },
    successChip: {
      main: "rgba(12, 200, 87, 0.2)",
      contrastText: "#31c274",
    },
    successWhiteButton: {
      main: "#31c274",
      contrastText: "#fff",
    },
    error: {
      main: "#ff3636",
    },
    disabled: {
      main: "#A4A8AC",
      light: "#6D7D8B",
      hot: "#545B7D",
      black: "#000",
      boxHeading: "#566875",
      customs: "rgba(0, 0, 0, 0.87)",
    },
    disSelect: {
      contrastText: "#6D7D8B",
      main: "#F4F7FB",
    },
    inactive: {
      main: "#F7F8FB",
      contrastText: "#A6BCBF",
    },
  },
  typography: {
    h1: {
      fontSize: "48px",
      fontWeight: "bold",
      lineHeight: "48px",
    },
    h2: {
      fontSize: "36px",
      fontWeight: "bold",
      lineHeight: "48px",
    },
    h3: {
      fontSize: "32px",
      fontWeight: 500,
      lineHeight: "44px",
    },
    h4: {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "19px",
    },
    h6: {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "19px",
    },
    h7: {
      fontWeight: "500",
      fontSize: "13px",
      lineHeight: "15px",
    },
    boxHeading: {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "28px",
    },
    listSecondary: {
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "15px",
    },
    tableHeading: {
      fontWeight: "500",
      fontSize: "11px",
      lineHeight: "13px",
    },
    tableSemiHeading: {
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "14px",
    },
    listText: {
      fontSize: "20px",
      lineHeight: "40px",
      fontWeight: "400",
    },
    subtitle1: {
      fontSize: "24px",
      lineHeight: "28px",
      fontWeight: "500",
    },
    mainLabel: {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "19px",
    },
    labelSmall: {
      fontSize: "13px",
      fontWeight: "400",
      lineHeight: "18px",
    },
    helperText: {
      fontSize: ".75rem",
      fontWeight: "400",
      lineHeight: "1.66",
      letterSpacing: "0.03333em",
    },
  },

  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkitAutofill": {
            WebkitBoxShadow: "0 0 0 30px #fff inset",
            // WebkitTextFillColor: "var(--color-disabled-hot)",
          },
        },
      },
    },
  },
});

export default theme;
