export const CPT_INIT_SETUP_REQUEST = "CPT_INIT_SETUP_REQUEST";
export const CPT_INIT_SETUP_SUCCESS = "CPT_INIT_SETUP_SUCCESS";
export const CPT_INIT_SETUP_FAIL = "CPT_INIT_SETUP_FAIL";
export const RESET_CPT_INIT_SETUP = "RESET_CPT_INIT_SETUP";

export const GET_CPT_CODES_REQUEST = "GET_CPT_CODES_REQUEST";
export const GET_CPT_CODES_SUCCESS = "GET_CPT_CODES_SUCCESS";
export const GET_CPT_CODES_FAIL = "GET_CPT_CODES_FAIL";
export const RESET_CPT_CODES = "RESET_CPT_CODES";

export const UPDATE_CPT_CODES_REQUEST = "UPDATE_CPT_CODES_REQUEST";
export const UPDATE_CPT_CODES_SUCCESS = "UPDATE_CPT_CODES_SUCCESS";
export const UPDATE_CPT_CODES_FAIL = "UPDATE_CPT_CODES_FAIL";
export const RESET_UPDATE_CPT_CODE = "RESET_UPDATE_CPT_CODE";

export const REMOVE_CPT_DIAGNOSIS_CODE_REQUEST =
  "REMOVE_CPT_DIAGNOSIS_CODE_REQUEST";
export const REMOVE_CPT_DIAGNOSIS_CODE_SUCCESS =
  "REMOVE_CPT_DIAGNOSIS_CODE_SUCCESS";
export const REMOVE_CPT_DIAGNOSIS_CODE_FAIL = "REMOVE_CPT_DIAGNOSIS_CODE_FAIL";
export const RESET_REMOVE_CPT_DIAGNOSIS_CODE =
  "RESET_REMOVE_CPT_DIAGNOSIS_CODE";

export const EXPORT_REPORT_REQUEST = "EXPORT_REPORT_REQUEST";
export const EXPORT_REPORT_SUCCESS = "EXPORT_REPORT_SUCCESS";
export const EXPORT_REPORT_FAIL = "EXPORT_REPORT_FAIL";
export const RESET_EXPORT_REPORT = "RESET_EXPORT_REPORT";
