import React from "react";
import MCCLogo from "../../assets/images/mcc-logo.png";
import { Box } from "@mui/material";

const MccLogo = () => {
  return (
    <Box
      boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
      width="98px"
      height="98px"
      borderRadius="50%"
      padding="12px"
      position="absolute"
      bgcolor="#fff"
      top="0"
      left="50%"
      zIndex="99"
      sx={{ transform: "translate(-50%, -50%)" }}
    >
      <img src={MCCLogo} alt="mcc-logo" width="100%" height="100%" />
    </Box>
  );
};

export default MccLogo;
