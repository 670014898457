import axios from "axios";
import {
  CREATE_ORGANIZATION_FAIL,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  RESET_CREATE_ORGANIZATION,
} from "../../assets/constants/organizationConstants";
import Cookies from "js-cookie";
import { BASE_URL } from "..";

// ================= CREATE WORKSPACE/AGENCY REDUCER ==================
export const createOrganizationFunc =
  (organizationObj) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_ORGANIZATION_REQUEST });

      const token = Cookies.get("token");

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/organization/create`,
        organizationObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: CREATE_ORGANIZATION_SUCCESS, payload: response?.data });
      Cookies.set("org_id_vita", response?.data?.organization?.id);
    } catch (error) {
      dispatch({
        type: CREATE_ORGANIZATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetCreateOrgFunc = () => (dispatch) =>
  dispatch({ type: RESET_CREATE_ORGANIZATION });
