import {
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ProfilePicture from "../../../common/ProfilePicture";
import HeaderText from "../../../common/HeaderText";
import Textfield from "../../../common/Textfield";
import { SendIcon } from "../../../assets/constants";
import axios from "axios";
import AlertMessage from "../../../common/AlertMessage";
import Cookies from "js-cookie";
import {
  getAgencyDetailFunc,
  getClientDataFunc,
} from "../../../actions/Agency";
import { useDispatch, useSelector } from "react-redux";
import {
  segmentError,
  segmentTrack,
} from "../../../utils/helpers/tracking_utils";
import { getClientCasesDocsFunc } from "../../../actions/DocumentsActions";
import { LoadingButton } from "@mui/lab";
import { appInsights } from "../../../utils/helpers/AppInsights";

const RequestFromClient = ({
  agent,
  client,
  setOpen,
  onClose,
  getClientsData,
  selectedDocument,
}) => {
  const [documentName, setDocumentName] = useState("");
  const [notes, setNotes] = useState("");
  const [status, setStatus] = useState({ type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const dispatch = useDispatch();

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  const handleAlertClick = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  // var userInfo = localStorage.getItem("userInfo");

  const [token, setToken] = useState("");

  const agentToken = Cookies.get("token");

  useEffect(() => {
    // if (userInfo) {
    //   userInfo = JSON.parse(userInfo);
    //   setToken(userInfo.token);
    // }
    if (agentToken) {
      setToken(agentToken);
      // setAgentID(cookieAgentId);
      // setAgencyID(cookieAgencyId);
    }
  }, [token]);

  useEffect(() => {
    if (selectedDocument) {
      setDocumentName(selectedDocument?.name);
    }
  }, [selectedDocument]);

  const documentNameHandler = (e) => {
    const value = e.target.value;
    if (value.length <= 255) {
      setDocumentName(value);
    }
  };

  const addNotesHandler = (e) => {
    const value = e.target.value;
    if (value.length <= 255) {
      setNotes(value);
    }
  };

  const submitDocumentHandler = async (e) => {
    e.preventDefault();

    setStatus({
      type: "",
      message: "",
    });

    setLoading(true);

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      // let case_id;
      const doc_type = "client_level";
      const case_id = selectedDocument ? selectedDocument?.case_id : null;

      const doc_data = {
        client_id: client?.id,
        case_id: case_id,
        folder_id: selectedDocument
          ? selectedDocument?.id
          : client?.root_folder_id,
        folder_name: selectedDocument ? selectedDocument?.name : documentName,
        request_type: selectedDocument
          ? selectedDocument?.folder_type
          : doc_type,
        message: notes ? notes : "",
      };

      const url = `${process.env.REACT_APP_BASE_URL}/storage/request-document`;

      const res = await axios.post(url, doc_data, config);

      if (!res?.data?.ok) {
        appInsights.trackTrace({
          message: `Error while requesting documents in client: ${
            res?.data?.message || "Requesting documents failed in client!"
          }`,
        });
        segmentError({
          event: "ERR_BACKEND_REQUESTING_DOCUMENT_IN_CLIENT",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
          error: `${
            res?.data?.message || "Error while requesting documents in client!"
          } `,
        });
        setStatus({
          type: "error",
          message: res?.data?.message,
        });
        handleAlertClick();
        setLoading(false);

        return;
      }

      setStatus({
        type: "success",
        message: `Request sent!`,
      });
      handleAlertClick();
      dispatch(getClientDataFunc(client?.id));
      dispatch(getClientCasesDocsFunc(client?.id));
      setLoading(false);
      setDocumentName("");
      setNotes("");
      onClose("right", false);
      setOpen(false);
      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "document_requested",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    } catch (error) {
      appInsights.trackTrace({
        message: `Error while requesting documents in client: ${
          error || "Requesting documents failed in client!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_REQUESTING_DOCUMENT_IN_CLIENT",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${error || "Error while requesting documents in client!"} `,
      });
      setStatus({
        type: "error",
        message:
          error.data && error.data.message ? error.data.message : error.message,
      });
      handleAlertClick();
      setLoading(false);
      onClose("right", false);
      setOpen(false);
      console.log(error);
    }
  };

  return (
    <>
      {!loading && (status?.type === "success" || status?.type === "error") && (
        <AlertMessage
          message={status?.message}
          severity={status?.type}
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}

      <Stack
        gap="24px"
        mt={3}
        // position="relative"
        component="form"
        onSubmit={submitDocumentHandler}
        height="100%"
      >
        <Stack gap={3} flex="1">
          <Stack gap="8px">
            <Typography
              variant="boxHeading"
              color="secondary"
              textTransform="uppercase"
            >
              Requesting From
            </Typography>
            <Stack direction="row" gap="8px" alignItems="flex-start">
              <ProfilePicture
                width="40px"
                height="40px"
                src={
                  client?.profile_picture_url
                    ? client?.profile_picture_url
                    : "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
                }
                alt={client?.name}
              />
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                alignItems="flex-start"
              >
                <HeaderText
                  textContent={client?.name}
                  variant="h6"
                  fontSize="16px"
                  fontWeight="400"
                />
                <HeaderText
                  textContent={client.email}
                  variant="body1"
                  fontSize="14px"
                  fontWeight="400"
                  color="secondary"
                />
              </Box>
            </Stack>
          </Stack>
          <Typography
            variant="boxHeading"
            color="secondary"
            textTransform="uppercase"
          >
            Document Details
          </Typography>{" "}
          <Textfield
            name="documentName"
            value={documentName}
            onChange={documentNameHandler}
            label="Name"
            variant="outlined"
            readOnly={selectedDocument}
          />
          <Typography
            variant="boxHeading"
            color="secondary"
            textTransform="uppercase"
          >
            Additional Message
          </Typography>{" "}
          <Textfield
            margin="dense"
            label="Notes/comments"
            value={notes}
            variant="outlined"
            name="notes"
            multiline
            maxRows={8}
            onChange={addNotesHandler}
            // error={isTouched && !!error}
            // helperText={error?.message}
            // value={value || ''}
            // onChange={onChange}
          />
        </Stack>
        <Stack alignItems="flex-end" py={2}>
          <LoadingButton
            // startIcon={<SendIcon />}
            variant="contained"
            disabled={!documentName?.length}
            type="submit"
            loading={loading}
          >
            Send Request
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
};

export default RequestFromClient;
