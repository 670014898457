import {
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Popover,
} from "@mui/material";
import React, { useEffect } from "react";
import BodyPartFiltericon from "../../icons/BodyPartFiltericon";

const BodyPartsFilter = ({
  filterButtonRef,
  selectBodyPartsHandler,
  bodyPartsAnchorEl,
  selectedBodyParts,
  changeBodyPartsHandler,
  setSelectedBodyParts,
  allBodyPartsData,
  loading,
  loadingCaseDetail,
}) => {
  useEffect(() => {
    if (selectedBodyParts?.length === 0 && allBodyPartsData?.length > 0) {
      setSelectedBodyParts(allBodyPartsData);
    }
  }, [selectedBodyParts, allBodyPartsData, setSelectedBodyParts]);

  return (
    <>
      <IconButton
        disabled={loading || loadingCaseDetail}
        ref={filterButtonRef}
        onClick={selectBodyPartsHandler}
        sx={{ backgroundColor: "#F4F6F8 !important", borderRadius: "4px" }}
      >
        <BodyPartFiltericon />
      </IconButton>
      {/* POPOVER BODY PARTS FILTERS */}
      <Popover
        open={Boolean(bodyPartsAnchorEl)}
        anchorEl={bodyPartsAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List style={{ maxHeight: 200, overflowY: "auto" }}>
          {allBodyPartsData?.length === 0 ? (
            <ListItem>No body parts available</ListItem>
          ) : (
            allBodyPartsData?.map((uniqueBodyPart, i) => (
              <ListItem
                key={i}
                onClick={(e) => e.stopPropagation()}
                sx={{ textTransform: "capitalize" }}
              >
                <FormControlLabel
                  label={uniqueBodyPart}
                  control={
                    <Checkbox
                      checked={selectedBodyParts.includes(uniqueBodyPart)}
                      onChange={(e) =>
                        changeBodyPartsHandler(e, uniqueBodyPart)
                      }
                    />
                  }
                />
              </ListItem>
            ))
          )}
        </List>
      </Popover>
    </>
  );
};

export default BodyPartsFilter;
