import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CardUI from "../../common/Card";
import TimeBillCard from "../TimeBillCard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllAgencyPatientsFunc,
  getUnpaginatedPatientsFunc,
} from "../../actions/Cases";
import RectangleSkeletons from "../RectangleSkeletons";
import {
  createBillingSubscriptionFunc,
  resetCreateBillingSubscription,
} from "../../actions/BillingSubscription";
import Cookies from "js-cookie";
import { LoadingButton } from "@mui/lab";
import { getAgencyDetailFunc } from "../../actions/Agency";

const BillingModalContent = ({ allPractices }) => {
  const dispatch = useDispatch();
  const [allPatientsCount, setAllPatientsCount] = useState(0);
  const [includeFuturePatients, setIncludeFuturePatients] = useState(true);

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const purchasedPlan = agencyDetail?.agency?.subscription?.subscription_status;
  const subscriptionId =
    agencyDetail?.agency?.subscription?.stripe_subscription_id;

  const organizationId = Cookies.get("org_id_vita");

  const allAgencyIds = allPractices
    .map((item) => `agencyIDs[]=${item?.id}`)
    .join("&");

  const allAgenciesId = allPractices?.map((item) => item.id);

  const getAllAgencyPatients = useSelector(
    (state) => state.getAllAgencyPatients
  );
  const {
    loading: loadingPatients,
    error: errorPatients,
    allAgencyPatients: allPatients,
  } = getAllAgencyPatients;

  const createBillingSubscription = useSelector(
    (state) => state.createBillingSubscription
  );
  const {
    loading: loadingCreateBillingSubsc,
    error: errorCreateBillingSubsc,
    createdBillingSubscription,
  } = createBillingSubscription;

  useEffect(() => {
    if (allAgencyIds) {
      dispatch(getAllAgencyPatientsFunc(allAgencyIds));
    }
  }, []);

  useEffect(() => {
    if (allPatients?.ok) {
      setAllPatientsCount(allPatients?.patients_count);
    }
  }, [allPatients]);

  const totalBill = (allPatientsCount * 30)?.toFixed(2);

  const purchasePlanHandler = () => {
    const billingObj = {
      include_future_patients: includeFuturePatients,
      agency_IDs: allAgenciesId,
      organization_id: organizationId,
      number_of_patients: allPatientsCount,
    };

    dispatch(createBillingSubscriptionFunc(billingObj));
  };

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  useEffect(() => {
    if (purchasedPlan !== "active") {
      const timerId = setInterval(() => {
        dispatch(getAgencyDetailFunc());
      }, 5000);

      return () => clearInterval(timerId);
    }
  }, [purchasedPlan]);

  useEffect(() => {
    if (createdBillingSubscription?.ok) {
      const linkURL = createdBillingSubscription?.payment_link;

      window.open(linkURL, "_blank");
      dispatch(resetCreateBillingSubscription());
    }
  }, [createdBillingSubscription]);

  return (
    <Box display="flex" alignItems="flex-start" gap="24px">
      {/* PATIENT AND PRACTICE DETAILS */}
      <Stack gap="40px" width="100%">
        <Stack gap="16px">
          <Stack bgcolor="#E5F6FD" padding="16px" borderRadius="4px">
            <Typography variant="body1" color="secondary" fontWeight={500}>
              PLAN DETAILS
            </Typography>

            <Typography padding="8px 0" variant="body2" color="#014361">
              For all patients in every workspace you will be charged{" "}
              <Typography component="span" fontWeight={600} variant="body2">
                $30/patient/month.
              </Typography>
            </Typography>
          </Stack>

          <Grid container spacing="18px">
            <Grid item md={4} sm={6}>
              <TimeBillCard
                showBoxShadow={false}
                border="1px solid #EBEBEB"
                cardNum={allPatientsCount}
                cardHeading="Total Patients"
                dollarSign={false}
                height="149px"
                titleFontWeight="500"
                justifyContent="space-between"
                loadingTimeEntry={loadingPatients}
              />
            </Grid>
            <Grid item md={4} sm={6}>
              <TimeBillCard
                height="149px"
                titleFontWeight="500"
                cardNum={allPractices?.length}
                showBoxShadow={false}
                border="1px solid #EBEBEB"
                cardHeading="Total Practices"
                dollarSign={false}
              />
            </Grid>
          </Grid>
        </Stack>
        {/* {purchasedPlan && (
          <Stack gap="12px">
            <Typography
              variant="body1"
              fontWeight={500}
              color="secondary"
              textTransform="uppercase"
            >
              Billing Details
            </Typography>
            <Stack padding="8px 0" gap="12px">
              <Box display="flex" alignItems="center">
                <Box width="166px">
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color="secondary"
                  >
                    Billing Name
                  </Typography>
                </Box>
                <Typography variant="body2" color="#0D0D0D">
                  Javiyon Simon{" "}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Box width="166px">
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color="secondary"
                  >
                    Billing Address{" "}
                  </Typography>
                </Box>
                <Typography variant="body2" color="#0D0D0D">
                  19034 Verna Unions Apt. 164 - Honolulu, RI / 87535
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Box width="166px">
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color="secondary"
                  >
                    Billing Phone Number{" "}
                  </Typography>
                </Box>
                <Typography variant="body2" color="#0D0D0D">
                  365-374-4961
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Box width="166px">
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color="secondary"
                  >
                    Payment Method
                  </Typography>
                </Box>
                <Typography variant="body2" color="#0D0D0D">
                  Credit Card
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Box width="166px">
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    color="secondary"
                  >
                    Last Payment{" "}
                  </Typography>
                </Box>
                <Typography variant="body2" color="#0D0D0D">
                  1 Feb 2024{" "}
                </Typography>
              </Box>
            </Stack>
            <Box>
              <Button
                variant="contained"
                sx={{ textTransform: "uppercase", background: "#0C80F3" }}
              >
                Edit Payment details
              </Button>
            </Box>
          </Stack>
        )} */}
      </Stack>

      {/* PURCHASE PLAN DETAIL */}
      <Stack gap="32px" maxWidth="347px" width="100%">
        <CardUI
          padding="32px"
          paddingTop="32px"
          boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
        >
          <Stack gap="24px">
            <Stack gap="16px">
              <Typography variant="body1" fontWeight={500} color="secondary">
                {purchasedPlan === "active" && subscriptionId
                  ? "CURRENT PLAN SUMMARY"
                  : "PLAN SUMMARY"}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="4px"
              >
                <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
                  All Patients ({allPatientsCount} x $30):
                </Typography>
                {loadingPatients ? (
                  <Box width="20%">
                    <RectangleSkeletons totalRows={1} height="10" />
                  </Box>
                ) : (
                  <Typography variant="body2">${totalBill}</Typography>
                )}
              </Box>
              <Divider />
              <FormControlLabel
                disabled={purchasedPlan === "active" && subscriptionId}
                sx={{ marginRight: "0", flex: "1" }}
                control={
                  <Checkbox
                    size="small"
                    checked={includeFuturePatients}
                    onChange={(e) => setIncludeFuturePatients(e.target.checked)}
                    sx={{ padding: "0 9px" }}
                  />
                }
                label={
                  <Typography
                    color={
                      purchasedPlan === "active" && subscriptionId
                        ? "rgba(0, 0, 0, 0.38)"
                        : "rgba(0, 0, 0, 0.87)"
                    }
                    component="span"
                    fontSize="14px"
                  >
                    {" "}
                    Add all new future patients to my plan
                  </Typography>
                }
              />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="4px"
              >
                <Typography variant="body1" color="secondary" fontWeight={500}>
                  Total Monthly Bill
                </Typography>
                {loadingPatients ? (
                  <Box width="20%">
                    <RectangleSkeletons totalRows={1} height="10" />
                  </Box>
                ) : (
                  <Typography variant="body1" fontWeight={500}>
                    ${totalBill}
                  </Typography>
                )}
              </Box>
            </Stack>
            <Divider />
            {purchasedPlan !== "active" && !subscriptionId && (
              <LoadingButton
                variant="contained"
                loading={loadingCreateBillingSubsc}
                sx={{ background: "#0C80F3" }}
                onClick={purchasePlanHandler}
              >
                Purchase plan
              </LoadingButton>
            )}
            {purchasedPlan !== "active" && !subscriptionId && (
              <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
                By purchasing this plan, you agree to our company{" "}
                <Typography
                  variant="body2"
                  component="a"
                  color="#0C80F3"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  href="https://vitatrax.co/privacy-policy/"
                  target="_blank"
                >
                  Privacy Policy {""}
                </Typography>
                and{" "}
                <Typography
                  variant="body2"
                  component="a"
                  color="#0C80F3"
                  href="https://vitatrax.co/practice-terms-of-services/"
                  target="_blank"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  Terms of Service{""}{" "}
                </Typography>
              </Typography>
            )}
          </Stack>
        </CardUI>

        {/* {purchasedPlan && (
          <CardUI
            padding="32px"
            paddingTop="32px"
            boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
          >
            <Stack gap="24px">
              <Typography
                textTransform="uppercase"
                variant="body1"
                fontWeight={500}
                color="secondary"
              >
                Invoice history
              </Typography>
              <Stack gap="16px">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="4px"
                >
                  <Stack gap="4px" width="129px">
                    <Typography variant="body2" fontWeight={500}>
                      INV-2000
                    </Typography>
                    <Typography variant="caption" color="secondary">
                      1 Feb 2024{" "}
                    </Typography>
                  </Stack>
                  <Typography flex="1" variant="body1" fontWeight={500}>
                    $13460.00
                  </Typography>
                  <Typography
                    variant="caption"
                    color="secondary"
                    sx={{ textDecoration: "underline" }}
                  >
                    PDF
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="4px"
                >
                  <Stack gap="4px" width="129px">
                    <Typography variant="body2" fontWeight={500}>
                      INV-2000
                    </Typography>
                    <Typography variant="caption" color="secondary">
                      1 Feb 2024{" "}
                    </Typography>
                  </Stack>
                  <Typography flex="1" variant="body1" fontWeight={500}>
                    $13460.00
                  </Typography>
                  <Typography
                    variant="caption"
                    color="secondary"
                    sx={{ textDecoration: "underline" }}
                  >
                    PDF
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </CardUI>
        )} */}
      </Stack>
    </Box>
  );
};

export default BillingModalContent;
