// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notifications_all_container__Ff2U- {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  border-radius: 8px;
  padding: 16px;
}

.Notifications_lightText__lq2ZQ {
  color: rgba(0, 0, 0, 0.38);
}

.Notifications_blueTextLine__wuXGJ {
  color: #0c80f3;
  text-decoration: underline;
  cursor: pointer;
}

.Notifications_description__ZSrBy {
  color: #566875;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/NotificationDrawer/Notifications.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,2BAA2B;EAC3B,SAAS;EACT,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".all_container {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  gap: 12px;\n  border-radius: 8px;\n  padding: 16px;\n}\n\n.lightText {\n  color: rgba(0, 0, 0, 0.38);\n}\n\n.blueTextLine {\n  color: #0c80f3;\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.description {\n  color: #566875;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"all_container": `Notifications_all_container__Ff2U-`,
	"lightText": `Notifications_lightText__lq2ZQ`,
	"blueTextLine": `Notifications_blueTextLine__wuXGJ`,
	"description": `Notifications_description__ZSrBy`
};
export default ___CSS_LOADER_EXPORT___;
