// import * as React from "react";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormHelperText from "@mui/material/FormHelperText";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";

// const countries = ["United States", "India", "Pakistan", "Australia"];

// const CssSelectField = styled(Select)({
//   "&.MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "#0000008F",
//     },
//     "& label.Mui-focused": {
//       color: "red !important",
//     },
//     "&:hover fieldset": {
//       borderColor: "#0000008F",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "#0000008F",
//     },
//   },
// });

// export default function Dropdown({
//   label,
//   noHelperText,
//   size,
//   maxWidth,
//   options = countries,
// }) {
//   const [country, setCountry] = React.useState("");

//   const handleChange = (event) => {
//     setCountry(event.target.value);
//   };

//   return (
//     <FormControl
//       sx={{ maxWidth: maxWidth ? `${maxWidth}` : `100%`, width: "100%" }}
//     >
//       <InputLabel
//         id="country"
//         sx={{
//           "&.Mui-focused": {
//             color: "#0000008F",
//           },
//         }}
//       >
//         {label}
//       </InputLabel>
//       <CssSelectField
//         labelId="country"
//         id="country-id"
//         value={country}
//         label={label}
//         className="select_label"
//         // sx={{ borderRadius: "10px" }}
//         onChange={handleChange}
//       >
//         {options.map((c, i) => (
//           <MenuItem value={c} key={i}>
//             {c}
//           </MenuItem>
//         ))}
//       </CssSelectField>
//       {/* <FormHelperText>{helperText ? helperText : null}</FormHelperText> */}
//     </FormControl>
//   );
// }
