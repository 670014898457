import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { DownloadedReport, DownloadingIcon } from "../../assets/constants";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const DownloadReportStatus = ({
  monthName,
  downloadReportDone,
  setDownloadReportDone,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="15px"
      bgcolor="#F4F6F8"
      padding="16px"
      borderRadius="8px"
      width="468px"
      position="absolute"
      bottom="20px"
      right="40px"
      zIndex="9999"
    >
      <Box borderRadius="7px" padding="10px" bgcolor="#FFF">
        {downloadReportDone ? <DownloadedReport /> : <DownloadingIcon />}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="6px"
        width="100%"
      >
        <Typography color="#000" variant="subtitle1" fontSize="15px">
          Evidence of care{" "}
          <Typography
            component="span"
            color="rgba(0, 0, 0, 0.38)"
            variant="body1"
          >
            {monthName}
          </Typography>
        </Typography>
        {downloadReportDone ? (
          <IconButton onClick={() => setDownloadReportDone(false)}>
            <HighlightOffOutlinedIcon />
          </IconButton>
        ) : (
          <Typography color="#0D0D0D" variant="body1">
            Downloading
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DownloadReportStatus;
