import React from "react";

const WaitingClockIcon = ({
  width = "20",
  height = "20",
  color = "#566875",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18.3327 10.0003C18.3327 14.6003 14.5993 18.3337 9.99935 18.3337C5.39935 18.3337 1.66602 14.6003 1.66602 10.0003C1.66602 5.40033 5.39935 1.66699 9.99935 1.66699C14.5993 1.66699 18.3327 5.40033 18.3327 10.0003Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0914 12.6505L10.5081 11.1088C10.0581 10.8421 9.69141 10.2005 9.69141 9.67546V6.25879"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WaitingClockIcon;
