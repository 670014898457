import {
  Box,
  Breadcrumbs,
  Chip,
  CircularProgress,
  Link,
  List,
  ListItemButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import AddFolderIcon from "../../icons/AddFolderIcon";
import { FolderIcon } from "../../assets/constants";
import { timeDateFormat } from "../../utils/helpers";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const StyledBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "12px !important",
  borderRadius: "8px !important",
  padding: "12px !important",
  border: "1px solid #EBEBEB !important",
  // padding: "12px",
}));
// {

//   checked_files_container: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     gap: "16px",
//     borderRadius: "8px",
//     background: " #D9ECFF",
//     padding: "8px 16px",
//   },
// };

const MoveFileLayout = ({
  breadcrumbs,
  emptyBreadcrumbHandler,
  setShowNewFolderBox,
  showNewFolderBox,
  caseDetail,
  onlyFoldersList,
  selectedMoveFolderList,
  selectedMoveFolderListHandler,
  createFolderHandler,
  newFolderName,
  setNewFolderName,
  showError,
  documentRequestTitle,
  fromClientPage = false,
  loadingCreateEmptyFolder,
}) => {
  return (
    <Stack gap="16px">
      <Box
        gap="12px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" gap="12px">
          <Typography variant="subtitle1" fontSize="16px" fontWeight="400">
            Destination:
          </Typography>
          <Breadcrumbs
            sx={{
              cursor: "pointer",
              padding: "8px 16px",
              border: "1px solid #EBEBEB",
              background: "#F4F6F8",
            }}
          >
            {breadcrumbs.map((crumb, index) => (
              <Link
                key={index}
                color={
                  index === breadcrumbs?.length - 1
                    ? "rgba(0, 0, 0, 0.80)"
                    : "inherit"
                }
                sx={{
                  textDecoration: "none",
                  fontWeight: index === breadcrumbs?.length - 1 ? "500" : "400",
                }}
                onClick={() =>
                  index === breadcrumbs?.length - 1
                    ? null
                    : emptyBreadcrumbHandler()
                }
              >
                {crumb}
              </Link>
            ))}
          </Breadcrumbs>
        </Box>

        <Box
          borderRadius="4px"
          component="button"
          border="1px solid rgba(0, 0, 0, 0.12)"
          padding="8px"
          height="40px"
          bgcolor="#fff"
          sx={{ cursor: "pointer" }}
          onClick={() => setShowNewFolderBox(true)}
        >
          <AddFolderIcon />
        </Box>
      </Box>
      <Stack gap="8px" maxHeight="335px" overflow="auto">
        {showNewFolderBox && (
          <StyledBox gap="32px !important" justifyContent="initial !important">
            <Box display="flex" alignItems="center" gap="8px" flex="1">
              <Box
                width="48px"
                height="48px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="#fff"
                borderRadius="8px"
              >
                <FolderIcon />
              </Box>
              <TextField
                id="folder-name"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
                label="Folder Name"
                variant="standard"
                error={showError}
                fullWidth
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="10px"
            >
              <Box
                bgcolor="#F4F6F8"
                padding="8px 10px"
                borderRadius="4px"
                color="disabled"
                onClick={() => {
                  setShowNewFolderBox(false);
                  setNewFolderName("");
                }}
                boxShadow="0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
              >
                <CloseIcon />
              </Box>
              <Box
                bgcolor="#3DC583"
                padding="8px 10px"
                borderRadius="4px"
                color="#fff"
                boxShadow="0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
                onClick={createFolderHandler}
              >
                {loadingCreateEmptyFolder ? (
                  <CircularProgress size={22} sx={{ color: "#fff" }} />
                ) : (
                  <CheckIcon />
                )}
              </Box>
            </Box>
          </StyledBox>
        )}
        <List
          component="nav"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            paddingTop: "0 ",
          }}
        >
          {onlyFoldersList?.map((doc, i) => {
            return (
              <ListItemButton
                key={i}
                component="a"
                selected={selectedMoveFolderList === doc}
                onClick={() => selectedMoveFolderListHandler(doc)}
                disableRipple={true}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "12px !important",
                  borderRadius: "8px !important",
                  padding: "12px !important",
                  border: "1px solid #EBEBEB !important",
                }}
              >
                <Box display="flex" alignItems="center" flex="1" gap="8px">
                  <Box
                    width="48px"
                    height="48px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="#fff"
                    borderRadius="8px"
                  >
                    <FolderIcon />
                  </Box>

                  <Typography
                    variant="subtitle1"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    {doc.name}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" flex="1">
                  <Chip
                    label={
                      doc?.file_type === "client_level" ||
                      doc?.folder_type === "client_level"
                        ? "Personal"
                        : fromClientPage
                        ? documentRequestTitle(doc)
                        : documentRequestTitle
                    }
                    size="small"
                    sx={{
                      marginLeft: 0,
                      padding: "4px",
                      color: `${
                        doc?.file_type === "client_level" ||
                        doc?.folder_type === "client_level"
                          ? "#2196F3"
                          : "#3DC583"
                      }`,
                      background: `${
                        doc?.file_type === "client_level" ||
                        doc?.folder_type === "client_level"
                          ? "rgba(33, 150, 243, 0.15)"
                          : "rgba(61, 197, 131, 0.15)"
                      }`,
                      // "& .MuiChip-deleteIcon": {
                      //   color: `${
                      //     doc?.document_type === "client_level"
                      //       ? "#2196F3"
                      //       : "#3DC583"
                      //   }`,
                      // },
                    }}
                  />
                </Box>

                <Stack>
                  <Typography variant="body2">Last Modified </Typography>
                  <Typography variant="caption" color="secondary">
                    {doc?.updated_at
                      ? timeDateFormat(doc?.updated_at)
                      : timeDateFormat(doc?.created_at)}
                  </Typography>
                </Stack>
              </ListItemButton>
            );
          })}
        </List>
      </Stack>
    </Stack>
  );
};

export default MoveFileLayout;
