import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Stack,
  Avatar,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import { ChatClient } from "@azure/communication-chat";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import Cookies from "js-cookie";

import CardUI from "../../../common/Card";
import HeaderText from "../../../common/HeaderText";
import ProfilePicture from "../../../common/ProfilePicture";
import { DoubleCheckIcon, SendIcon } from "../../../assets/constants";
import CircularProgressLoading from "../../../common/CircularProgress";
import {
  segmentError,
  segmentTrack,
} from "../../../utils/helpers/tracking_utils";
import { useDispatch, useSelector } from "react-redux";
import { getAgentProfile, getAgencyDetailFunc } from "../../../actions/Agency";
import axios from "axios";
import AlertMessage from "../../../common/AlertMessage";
import axiosRetry from "axios-retry";
import SkeletonGrid from "../../../components/SkeletonGrid";
import ChatSkeleton from "../../../components/ChatSkeleton";
import ChatProfileSkeleton from "../../../components/ChatProfileSkeleton";
import { appInsights } from "../../../utils/helpers/AppInsights";
import { onlyDateFormat } from "../../../utils/helpers";
import ChatTickIcon from "../../../icons/ChatTickIcon";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

axiosRetry(axios, { retries: 3 });

const ChatTab = ({ caseDetail, loadingCaseDetail }) => {
  const chatWindowRef = useRef(null);
  const location = useLocation();

  let prevDate = null;

  const [errorMsg, setErrorMsg] = useState(null);
  const [chatClient, setChatClient] = useState(null);
  const [chatThreadClient, setChatThreadClient] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [toastMessage, setToastMessage] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [clientIsTyping, setClientIsTyping] = useState(false);
  const [message, setMessage] = useState("");

  const agentToken = Cookies.get("token");
  const dispatch = useDispatch();

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading, error, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const threadID = caseDetail?.patient?.comm_thread_id;
  const agencyId = Cookies.get("agency_id_vita");

  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const includeAgent = async () => {
    appInsights.trackTrace({ message: "Including agent function called!" });
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${agentToken}`,
        },
      };

      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/chat/add-to-thread`,
        { threadId: threadID, patientId: caseDetail?.patient?.id },
        config
      );

      // segment logs + AI traces but only for error
      if (!res?.data?.ok) {
        appInsights.trackTrace({
          message: `Error while adding agent in chat ${
            res?.data?.message || "Could not add agent in chat message!"
          }`,
        });
        segmentError({
          event: "ERR_BACKEND_CHAT_ADD_TO_THREAD",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
          error: res?.data?.message || "Could not add agent in chat message!",
        });
      }

      appInsights.trackTrace({ message: "Agent included in chat thread!" });
    } catch (error) {
      console.log(error);
      appInsights.trackTrace({
        message: `Error while adding agent in chat ${error}`,
      });
      segmentError({
        event: "ERR_BACKEND_INCLUDING_AGENT_IN_CHAT",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: error || `Error while adding agent in chat ${error}!`,
      });
      setErrorMsg(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  const fetchTokens = async () => {
    appInsights.trackTrace({
      message: "Inside fetch tokens function",
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${agentToken}`,
      },
    };

    const endpoint_response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/chat/get-endpoint-url`,
      config
    );

    // segment logs + AI traces but only for error
    if (!endpoint_response?.data?.ok) {
      appInsights.trackTrace({
        message: `Error while getting chat endpoint url ${
          endpoint_response?.data?.message || "Could not get chat endpoint url!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_GET_CHAT_ENDPOINT_URL",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${
          endpoint_response?.data?.message || "Could not get chat endpoint url!"
        }`,
      });
      setMessagesLoading(false);
    }

    appInsights.trackTrace({
      message: "Getting the chat endpoint url on frontend!",
    });

    const token_response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/chat/get-token`,
      config
    );

    // segment logs + AI traces but only for error
    if (!token_response?.data?.ok) {
      appInsights.trackTrace({
        message: `Error while getting chat token ${
          token_response?.data?.message || "Could not get chat token!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_GETTING_CHAT_TOKEN",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: ` ${
          token_response?.data?.message || "Could not get chat token!"
        }`,
      });
      setMessagesLoading(false);
    }

    appInsights.trackTrace({
      message: "Get Chat token received!",
    });

    const endpointURL = endpoint_response?.data?.endpointURL;
    const userAccessToken = token_response?.data?.token;

    const chatClient = new ChatClient(
      endpointURL,
      new AzureCommunicationTokenCredential(userAccessToken)
    );
    const chatThreadClient = chatClient.getChatThreadClient(threadID);

    appInsights.trackTrace({
      message: "Chat client is set in chatClient state!",
    });
    appInsights.trackTrace({
      message: "Chat thread client is set in chatThreadClient state!",
    });
    setChatClient(chatClient);
    setChatThreadClient(chatThreadClient);
    setMessagesLoading(false);
  };

  useEffect(() => {
    if (agentToken) {
      appInsights.trackTrace({
        message: "getting agent token and messages!",
      });
      setMessagesLoading(true);
      dispatch(getAgentProfile());
      dispatch(getAgencyDetailFunc());
    }
  }, [agentToken]);

  useEffect(() => {
    if (threadID) {
      appInsights.trackTrace({
        message: "Getting the list of messages!",
      });
      setMessagesLoading(true);
      fetchTokens();
    }
  }, [threadID]);

  useEffect(() => {
    const initChat = async () => {
      setMessagesLoading(true);

      // If logged in user is not included in case.agents
      const agentIncluded = caseDetail?.patient?.agents?.some(
        (agent) => agent?.id === agentUser?.agent?.id
      );

      if (!agentIncluded) {
        appInsights.trackTrace({
          message: "Agent is not included in chat, then including agent!",
        });
        setMessagesLoading(false);
        await includeAgent();
      }

      appInsights.trackTrace({
        message: "Agent is already included in chat thread",
      });

      await chatClient.startRealtimeNotifications();

      await chatClient.on("typingIndicatorReceived", (event) => {
        if (
          event.sender.communicationUserId !== agentUser?.agent?.azure_comm_id
        ) {
          if (event?.senderDisplayName === "start") {
            setClientIsTyping(true);
          } else {
            setTimeout(() => setClientIsTyping(false), 2000);
          }
        }
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${agentToken}`,
        },
      };

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/chat/list-messages?threadId=${threadID}`,
          config
        );
        // segment logs + AI traces but only for error

        if (!data.ok) {
          appInsights.trackTrace({
            message: `Error while getting chat message listing ${
              data?.message || "Could not get chat messages list!"
            }`,
          });
          segmentError({
            event: "ERR_BACKEND_CHAT_MESSAGES_LISTING",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
            error: `${data?.message || "Could not get chat messages list!"}`,
          });
          setErrorMsg(data.message);
          setMessagesLoading(false);
        }

        appInsights.trackTrace({
          message: "List of Chat messages received!",
        });

        const existing_messages = await data?.messages;

        if (existing_messages?.length > 0) {
          const messageId = existing_messages[0]?.id;

          const { data } = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/chat/mark-as-read`,
            { messageId: messageId },
            config
          );

          if (!data.ok) {
            // segment logs + AI traces but only for error
            appInsights.trackTrace({
              message: `Error while read chat messages ${
                data?.message || "Could not read the chat message!"
              }`,
            });
            segmentError({
              event: "ERR_BACKEND_MARK_MESSAGES_AS_READ",
              agent: agentUser?.agent,
              agency: agencyDetail?.agency,
              error: `${data?.message || "Could not read the chat message!"}`,
            });
            setErrorMsg(data.message);
            setMessagesLoading(false);
          }

          appInsights.trackTrace({
            message: "Chat message read successfully!",
          });
        }

        const messagesArray = [];
        for (const message of existing_messages) {
          if (message?.content?.message) {
            messagesArray.push(message);
          }
        }

        setChatClient(chatClient);
        setChatThreadClient(chatThreadClient);
        setMessages(messagesArray.reverse());
        setMessagesLoading(false);

        await chatClient.startRealtimeNotifications();

        chatClient.on("chatMessageReceived", async (e) => {
          const newMessage = {
            id: e.id,
            type: e.type.toLowerCase(),
            sequenceId: e.version,
            version: e.version,
            senderDisplayName: e.senderDisplayName,
            createdOn: e.createdOn,
            content: {
              message: e.message,
            },
            sender: e.sender,
          };

          // chatClient.on("typingIndicatorReceived", (event) => {
          //   console.log("INSIDE", event);
          //   // if (event.from !== currentUser) {
          //   // setIsTyping(true);
          //   // Hide the typing indicator after some time
          //   // setTimeout(() => setIsTyping(false), 3000); // Adjust time as needed
          //   // }
          // });

          if (
            !messages.some((message) => message.id === newMessage.id) &&
            e.sender.communicationUserId !== agentUser?.agent?.azure_comm_id &&
            e.threadId === caseDetail?.patient?.comm_thread_id
          ) {
            setMessages((prevMessages) => [...prevMessages, newMessage]);

            // wait 2 seconds before marking the latest received message as read
            if (
              `${location.pathname}${location.search}` ===
              `/${caseDetail?.patient?.id}?tab=chat`
            ) {
              setTimeout(async () => {
                const { data } = await axios.post(
                  `${process.env.REACT_APP_BASE_URL}/chat/mark-as-read`,
                  { messageId: newMessage?.id },
                  config
                );

                // segment logs + AI traces but only for error

                if (!data.ok) {
                  appInsights.trackTrace({
                    message: `Error while read chat messages ${
                      data?.message || "Could not read the chat message!"
                    }`,
                  });
                  segmentError({
                    event: "ERR_BACKEND_MARK_MESSAGES_AS_READ",
                    agent: agentUser?.agent,
                    agency: agencyDetail?.agency,
                    error: `${
                      data?.message || "Could not read the chat message!"
                    }`,
                  });
                  console.log(data.message);
                }

                appInsights.trackTrace({
                  message: "Chat message read successfully!",
                });
              }, 2000);
            }
          }
        });
      } catch (error) {
        setMessagesLoading(false);
        // segment logs + AI traces but only for error
        appInsights.trackTrace({
          message: `Error while listing chat messages ${error}`,
        });
        segmentError({
          event: "ERR_BACKEND_CHAT_MESSAGES_LISTING",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
          error: `${error || "Could not get chat messages list!"}`,
        });
        console.log(error);
      }
    };

    if (caseDetail?.patient && chatClient) {
      appInsights.trackTrace({
        message:
          "calling initChat function when caseDetail or chatClient is changed",
      });
      setMessagesLoading(true);
      initChat();
    }
  }, [caseDetail, chatClient]);

  useEffect(() => {
    if (agentUser) {
      setDisplayName(
        agentUser?.agent?.name || agentUser?.agent?.email.split("@")[0]
      );
    }
  }, [agentUser]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = async (content) => {
    if (chatThreadClient) {
      appInsights.trackTrace({ message: "Inside send message function" });
      var messageId;

      const tempMessageId = uuidv4();
      const newMessage = {
        id: tempMessageId,
        content: { message: content },
        senderDisplayName: displayName,
        createdOn: new Date(),
        senderId: agentUser?.agent?.id,
        sender: { communicationUserId: agentUser?.agent?.azure_comm_id },
        status: "sending",
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);

      try {
        const sendMessageRequest = { content };
        const sendMessageOptions = { senderDisplayName: displayName };
        const sendChatMessageResult = await chatThreadClient.sendMessage(
          sendMessageRequest,
          sendMessageOptions
        );

        if (!sendChatMessageResult?.id) {
          messageId = tempMessageId;
          setMessagesLoading(false);
          appInsights.trackTrace({
            message: `Error sending chat message to Azure: ${sendChatMessageResult}`,
          });
          segmentError({
            event: "ERR_AZURE_CHAT_SEND",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
            error: `${sendChatMessageResult}`,
          });

          setToastMessage("Message not sent. Please try again.");
          setOpen(true);
          setMessagesLoading(false);

          return;
        } else {
          appInsights.trackTrace({ message: "Chat message sent to Azure" });
          setMessagesLoading(false);
        }

        messageId = sendChatMessageResult?.id;
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === tempMessageId
              ? { ...msg, id: messageId, status: "sending" }
              : msg
          )
        );
        // const newMessage = {
        //   id: messageId,
        //   content: { message: content },
        //   senderDisplayName: displayName,
        //   createdOn: new Date(),
        //   senderId: agentUser?.agent?.id,
        //   sender: { communicationUserId: agentUser?.agent?.azure_comm_id },
        // };
        // setMessages((prevMessages) => [...prevMessages, newMessage]);
        appInsights.trackTrace({
          message: "Messages are set inside setMessages state",
        });
        setMessagesLoading(false);
      } catch (error) {
        messageId = tempMessageId;
        console.log(`Error sending chat message to Azure: ${error}`);
        appInsights.trackTrace({
          message: `Error sending chat message to Azure: ${error}`,
        });
        setMessagesLoading(false);
      }

      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${agentToken}`,
          },
        };

        const messageData = {
          id: messageId,
          content: { message: content },
          senderDisplayName: displayName,
          threadId: threadID,
          patientId: caseDetail?.patient?.id,
          createdOn: new Date(),
          sentFrom: "pc",
          agencyId,
        };

        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/chat/send-message`,
          messageData,
          config
        );

        if (!res?.data?.ok) {
          appInsights.trackTrace({
            message: `Error sending chat message on Backend: ${
              res?.data?.message || "Error sending chat message!"
            }`,
          });
          segmentError({
            event: "ERR_BACKEND_CHAT_SEND",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
            error: `${res?.data?.message || "Error sending chat message!"}`,
          });
          setToastMessage("Message not sent. Please try again.");
          setOpen(true);
          return;
        }

        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === messageId
              ? { ...msg, id: messageId, status: "sent" }
              : msg
          )
        );
        appInsights.trackTrace({ message: "Chat message sent to backend!" });

        if (agentUser?.agent && agencyDetail?.agency) {
          segmentTrack({
            event: "message_sent",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
          });
        }
      } catch (error) {
        appInsights.trackTrace({
          message: `Error sending chat message on Azure: ${error}`,
        });

        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === tempMessageId ? { ...msg, status: "failed" } : msg
          )
        );
        setToastMessage("Message not sent. Please try again.");
        setOpen(true);
        segmentError({
          event: "ERR_AZURE_CHAT_SEND",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
          error: `${error || "Error sending chat message on Azure"} `,
        });
        console.log(error);
        setErrorMsg(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const content = message.trim();
    if (!content) return;
    chatThreadClient.sendTypingNotification({ senderDisplayName: "stop" });
    appInsights.trackTrace({ message: "Send message button clicked!" });
    sendMessage(content);

    setMessage("");
  };

  const changeMessageHandler = async (e) => {
    setMessage(e.target.value);
    setIsTyping(true);
    if (!isTyping) {
      chatThreadClient.sendTypingNotification({ senderDisplayName: "start" });
    }

    const lastTypingTime = new Date().getTime();

    setTimeout(() => {
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - lastTypingTime;

      if (timeDiff >= 3000) {
        setIsTyping(false);
        chatThreadClient.sendTypingNotification({ senderDisplayName: "stop" });
      }
    }, 3000);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  return (
    <>
      {toastMessage && (
        <AlertMessage
          message={toastMessage}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      <Box
        display="flex"
        width="100%"
        alignItems="flex-start"
        justifyContent="space-between"
        gap={3}
      >
        <Stack
          gap="20px"
          width="100%"
          boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
          padding="24px 32px"
          borderRadius="8px"
          height="calc(100vh - 374px)"
          position="relative"
          justifyContent="flex-end"
        >
          {messagesLoading || loadingAgency || loading || loadingCaseDetail ? (
            <ChatSkeleton />
          ) : messages?.length > 0 ? (
            <Box
              height="auto"
              sx={{ overflowY: "auto" }}
              padding="6px"
              ref={chatWindowRef}
              display="flex"
              flexDirection="column"
              justifyContent={messages?.length > 4 ? "flex-start" : "flex-end"}
              position="relative"
            >
              {messages.map((message, index) => {
                const messageDate = new Date(
                  message.createdOn
                ).toLocaleDateString();
                const showDateSeparator = messageDate !== prevDate;

                prevDate = messageDate;

                return (
                  <Stack
                    key={index}
                    mb={2}
                    gap="3px"
                    alignItems={
                      (message.senderCommId ===
                        agentUser?.agent?.azure_comm_id &&
                        message.senderId === agentUser?.agent?.id) ||
                      message.sender?.communicationUserId ===
                        agentUser?.agent?.azure_comm_id
                        ? "flex-end"
                        : "flex-start"
                    }
                  >
                    {showDateSeparator && (
                      <Box textAlign="center" width="100%" mb={2}>
                        <Typography
                          variant="body1"
                          color="rgba(0, 0, 0, 0.38)"
                          fontSize="14px"
                        >
                          {onlyDateFormat(message?.createdOn)}
                        </Typography>
                      </Box>
                    )}

                    <Box
                      bgcolor={
                        (message.senderCommId ===
                          agentUser?.agent?.azure_comm_id &&
                          message.senderId === agentUser?.agent?.id) ||
                        message.sender?.communicationUserId ===
                          agentUser?.agent?.azure_comm_id
                          ? "#0C80F3"
                          : "#ECECEC"
                      }
                      borderRadius="8px"
                      padding="16px 24px"
                      maxWidth="554px"
                      width="100%"
                    >
                      {(message.senderCommId !==
                        agentUser?.agent?.azure_comm_id ||
                        message.senderId !== agentUser?.agent?.id) &&
                        message.sender?.communicationUserId !==
                          agentUser?.agent?.azure_comm_id && (
                          <Typography
                            fontSize="12px"
                            color="#0D0D0D"
                            variant="body1"
                            pb={1}
                          >
                            {message.senderDisplayName}
                          </Typography>
                        )}
                      <Typography
                        component="p"
                        fontSize="14px"
                        color={
                          (message.senderCommId ===
                            agentUser?.agent?.azure_comm_id &&
                            message.senderId === agentUser?.agent?.id) ||
                          message.sender?.communicationUserId ===
                            agentUser?.agent?.azure_comm_id
                            ? "#fff"
                            : "#0D0D0D"
                        }
                        variant="body1"
                        width="100%"
                        sx={{ wordWrap: "break-word" }}
                      >
                        {message?.content?.message
                          ?.split("\n")
                          .map((item, index) => (
                            <React.Fragment key={index}>
                              {" "}
                              {item}{" "}
                              {index <
                                message?.content?.message?.split("\n")?.length -
                                  1 && <br />}{" "}
                            </React.Fragment>
                          ))}
                      </Typography>
                    </Box>
                    {message.status === "sending" ? (
                      <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
                        Sending...
                      </Typography>
                    ) : (
                      <Box display="flex" gap="4px" alignItems="center">
                        {(message.senderCommId ===
                          agentUser?.agent?.azure_comm_id &&
                          message.senderId === agentUser?.agent?.id) ||
                        message.sender?.communicationUserId ===
                          agentUser?.agent?.azure_comm_id ? (
                          <>
                            {" "}
                            <ChatTickIcon />
                            <Typography
                              variant="body1"
                              fontSize="12px"
                              color="rgba(0, 0, 0, 0.38)"
                            >
                              {dayjs(message?.createdOn).format("hh:mm A")}
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            variant="body1"
                            fontSize="12px"
                            color="rgba(0, 0, 0, 0.38)"
                          >
                            {dayjs(message?.createdOn).format("hh:mm A")}
                          </Typography>
                        )}

                        {/* ERROR ON THIS LINE */}
                        {/* {message.senderId === agentUser?.agent?.id ||
            (message.sender?.communicationUserId ===
              agentUser?.agent?.azure_comm_id && (
              <IconButton
                sx={{ padding: 0, width: "16px", height: "16px" }}
              >
                <DoubleCheckIcon />
              </IconButton>
            ))} */}
                      </Box>
                    )}
                  </Stack>
                );
              })}

              {clientIsTyping && (
                <Stack gap="8px">
                  <Box display="flex" alignItems="center" gap="8px">
                    <Avatar
                      src={caseDetail?.patient?.profile_picture_url}
                      alt={caseDetail?.patient?.name}
                    />
                    <Typography variant="body2">
                      {caseDetail?.patient?.name}
                    </Typography>
                  </Box>
                  <Box
                    bgcolor="#F4FAFF"
                    padding="16px"
                    borderRadius="8px"
                    width="422px"
                  >
                    <Typography variant="body2" color="#0D0D0D">
                      Typing...
                    </Typography>
                  </Box>
                </Stack>
              )}
            </Box>
          ) : (
            <Box
              height="100%"
              display="flex"
              width="100%"
              alignItems="flex-end"
              justifyContent="center"
            >
              <Typography color="secondary" variant="body1">
                Start chatting with patient{" "}
              </Typography>
            </Box>
          )}

          {/* {clientIsTyping && (
            <Stack gap="8px">
              <Box
                bgcolor={
                  (message.senderCommId === agentUser?.agent?.azure_comm_id &&
                    message.senderId === agentUser?.agent?.id) ||
                  message.sender?.communicationUserId ===
                    agentUser?.agent?.azure_comm_id
                    ? "#0C80F3"
                    : "#ECECEC"
                }
                borderRadius="8px"
                padding="16px 24px"
                maxWidth="554px"
                width="100%"
              >
                {(message.senderCommId !== agentUser?.agent?.azure_comm_id ||
                  message.senderId !== agentUser?.agent?.id) &&
                  message.sender?.communicationUserId !==
                    agentUser?.agent?.azure_comm_id && (
                    <Typography
                      fontSize="12px"
                      color="#0D0D0D"
                      variant="body1"
                      pb={1}
                    >
                      {message.senderDisplayName}
                    </Typography>
                  )}
              </Box>
            </Stack>
          )} */}

          {/* SUBMIT MESSAGE FORM */}
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            gap="16px"
            width="100%"
            component="form"
            onSubmit={handleSubmit}
          >
            <Box flex="1">
              <TextField
                multiline
                // disabled={
                //   messagesLoading ||
                //   loadingAgency ||
                //   loading ||
                //   loadingCaseDetail
                // }
                maxRows={4}
                value={message}
                name="message"
                placeholder="Write your message here"
                onChange={changeMessageHandler}
                size="small"
                fullWidth
                autoComplete="off"
                onKeyDown={handleKeyDown}
              />
            </Box>
            <Button startIcon={<SendIcon />} variant="contained" type="submit">
              Send
            </Button>
          </Box>
        </Stack>

        {/* USER CHAT PROFILE */}
        <CardUI maxWidth="267px" padding="16px" paddingTop="16px">
          <Stack gap="24px" alignItems="center" pb={1}>
            <Typography
              variant="h3"
              fontSize="16px"
              fontWeight="500"
              color="secondary"
              alignSelf="flex-start"
            >
              CHATTING WITH
            </Typography>
            {loadingCaseDetail ? (
              <ChatProfileSkeleton />
            ) : (
              <>
                {" "}
                <ProfilePicture
                  width="134px"
                  height="134px"
                  src={caseDetail?.patient?.profile_picture_url}
                  alt={caseDetail?.patient?.name}
                />
                <Stack gap="9px" alignItems="center">
                  <HeaderText
                    textContent={caseDetail?.patient?.name}
                    fontSize="28px"
                    fontWeight="500"
                    color="#000"
                    variant="h3"
                    textAlign="center"
                  />
                  <Typography
                    variant="body1"
                    color="#0D0D0D"
                    fontWeight="400"
                    fontSize="14px"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {caseDetail?.patient?.email}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#0D0D0D"
                    fontWeight="400"
                    fontSize="14px"
                  >
                    {caseDetail?.patient?.contact_number}
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
        </CardUI>
      </Box>
    </>
  );
};

export default ChatTab;
