import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import DashboardImg from "../../assets/images/dashboard-img.png";
import bellIcon from "../../assets/images/bell-img2.png";
import DashboardCard from "../DashboardCard";
import FolderImg from "../../assets/images/folder-img.png";
import PaperImg from "../../assets/images/paper-img.png";
import NetworkImg from "../../assets/images/network-img.png";
import { InfoCircle, RiskCaseEmptyState } from "../../assets/constants";
import NewMessageCard from "../NewMessageCard";
import RiskCasesCard from "../RiskCasesCard";
import EmptyBox from "../../assets/images/empty-box.png";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardAnalyticsFunc,
  getHighRiskCasesFunc,
  getUnreadChatMessagesFunc,
} from "../../actions/Cases";
import { getAgencyDetailFunc } from "../../actions/Agency";
import CircularProgressLoading from "../../common/CircularProgress";
import AlertMessage from "../../common/AlertMessage";
import { dateTimeFormat, timeDateFormat } from "../../utils/helpers";
import { segmentTrack } from "../../utils/helpers/tracking_utils";
import UserFeedCard from "../UserFeedCard";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getWebNotificationsFunc,
  userFeedNotificationsFunc,
} from "../../actions/AppConfig";
import Cookies from "js-cookie";
import SkeletonGrid from "../SkeletonGrid";
import RectangleSkeletons from "../RectangleSkeletons";
import dashboardImage from "../../assets/images/dashboard-img.png";

const page_size = 10;

const DashboardComponent = () => {
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [userFeedNotifications, setUserFeedNotifications] = useState([]);
  const [renderUserFeed, setRenderUserFeed] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const dispatch = useDispatch();

  const agentProfile = useSelector((state) => state.agentProfile);
  const {
    loading: loadingAgentProfile,
    error: errorAgentProfile,
    agentUser,
  } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const dashboardAnalytics = useSelector((state) => state.dashboardAnalytics);
  const { loading, error, dashboardAnalytic } = dashboardAnalytics;

  const atHighRiskCases = useSelector((state) => state.atHighRiskCases);
  const {
    loading: loadingHighRiskCases,
    error: errorHighRiskcases,
    highRiskCases,
  } = atHighRiskCases;

  const getUnreadChatMessages = useSelector(
    (state) => state.getUnreadChatMessages
  );
  const {
    loading: loadingUnreadChatMessages,
    error: errorUnreadChatMessages,
    unreadChatMessages,
  } = getUnreadChatMessages;

  // USER FEED NOTIFICATIONS
  const getUserFeedNotifications = useSelector(
    (state) => state.getUserFeedNotifications
  );
  const {
    loading: loadingUserFeeds,
    error: errorUserFeeds,
    userFeeds,
  } = getUserFeedNotifications;

  useEffect(() => {
    // dispatch(getDashboardAnalyticsFunc());
    // dispatch(getHighRiskCasesFunc());
    // dispatch(getUnreadChatMessagesFunc());
    if (!agencyDetail) {
      dispatch(getAgencyDetailFunc());
    }
    // dispatch(userFeedNotificationsFunc(page, page_size));
  }, []);

  const fetchMoreData = () => {
    if (hasNext) {
      const nextPage = page + 1;

      dispatch(userFeedNotificationsFunc(nextPage, page_size));

      setPage(nextPage);
    }
  };

  useEffect(() => {
    if (!loadingUserFeeds && userFeeds?.ok) {
      setUserFeedNotifications((prevState) => [
        ...prevState,
        ...userFeeds?.user_feed,
      ]);
      setHasNext(userFeeds?.has_next);
    }
  }, [userFeeds]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // setRenderUserFeed(false);
      // dispatch(getUnreadChatMessagesFunc());
      setPage(1);
      // setUserFeedNotifications([]);
      // dispatch(userFeedNotificationsFunc(page, page_size));
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "dashboard",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  return (
    <Stack
      sx={{
        background: "linear-gradient(180deg, #FCFCFC 0.03%, #E9F4FF 99.97%)",
      }}
      height="100%"
      alignItems="center"
      justifyContent="center"
      gap="19px"
      position="relative"
      overflow="hidden"
    >
      <Box width="560px" height="439px">
        <img
          width="100%"
          height="100%"
          src={dashboardImage}
          alt="coming soon"
        />
      </Box>
      <Stack gap="8px" alignItems="center" width="646px">
        <Typography variant="h3" fontSize="44px" color="#212121">
          All in one place. Stay tuned!
        </Typography>
        <Typography
          variant="body1"
          color="secondary"
          fontSize="20px"
          textAlign="center"
        >
          A seamless way to track your patients, manage CPT codes, and
          streamline billing.
        </Typography>
      </Stack>
      <Stack
        position="absolute"
        bgcolor="#0C80F3"
        width="442px"
        bottom="61px"
        right="-125px"
        boxShadow="15px 8px 13px 0px rgba(0, 0, 0, 0.10)"
        padding="8px 0"
        textAlign="center"
        sx={{ transform: "rotate(-40.193deg)" }}
      >
        <Typography color="#fff" fontSize="29px" variant="body1">
          Coming Soon
        </Typography>
      </Stack>
    </Stack>
    // <>
    //   {!loading && error && (
    //     <AlertMessage
    //       message={error}
    //       severity="error"
    //       open={open}
    //       handleClose={handleClose}
    //     />
    //   )}
    //   {!loadingAgentProfile && errorAgentProfile && (
    //     <AlertMessage
    //       message={errorAgentProfile}
    //       severity="error"
    //       open={open}
    //       handleClose={handleClose}
    //     />
    //   )}
    //   {!loadingHighRiskCases && errorHighRiskcases && (
    //     <AlertMessage
    //       message={errorHighRiskcases}
    //       severity="error"
    //       open={open}
    //       handleClose={handleClose}
    //     />
    //   )}
    //   {!loadingUnreadChatMessages && errorUnreadChatMessages && (
    //     <AlertMessage
    //       message={errorUnreadChatMessages}
    //       severity="error"
    //       open={open}
    //       handleClose={handleClose}
    //     />
    //   )}

    //   <Stack flexDirection="column" rowGap={3}>
    //     {/* 1st ROW DASHBOARD MAIN*/}
    //     <Box display="flex" gap={3}>
    //       <Stack
    //         gap={3}
    //         maxWidth="100%"
    //         width="100%"
    //         justifyContent="space-between"
    //       >
    //         {/* 1st BOX WELCOME */}
    //         <Box
    //           boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
    //           padding="32px"
    //           borderRadius="8px"
    //           bgcolor="#E7F3FF"
    //           width="100%"
    //         >
    //           <Box
    //             display="flex"
    //             justifyContent="space-between"
    //             alignItems="center"
    //           >
    //             {loadingAgentProfile ? (
    //               <Stack gap="24px">
    //                 <Skeleton variant="rounded" width={342} height={64} />
    //                 <Skeleton variant="rounded" width={281} height={22} />
    //                 <Skeleton variant="rounded" width={281} height={22} />
    //               </Stack>
    //             ) : (
    //               <Stack maxWidth="412px" gap="24px">
    //                 <Typography
    //                   id="welcome-text"
    //                   color="#0C3D6D"
    //                   // fontSize="28px"
    //                   variant="h5"
    //                   fontWeight="500"
    //                 >
    //                   Welcome back {agentUser?.agent?.name} 👋 <br /> Did you
    //                   know?
    //                 </Typography>
    //                 <Typography variant="body1" fontSize="18px">
    //                   Slip and fall accidents are one of the most common causes
    //                   of personal injury cases?
    //                 </Typography>
    //               </Stack>
    //             )}

    //             <Box width="277px" sx={{ flexShrink: 0 }} alignSelf="center">
    //               <img src={DashboardImg} width="100%" alt="dashboard" />
    //             </Box>
    //           </Box>
    //         </Box>

    //         {/* 2nd ROW CARDS CASES */}
    //         {/* <Box display="flex" alignItems="center" gap="32px">
    //           <DashboardCard
    //             headerText="Total Cases"
    //             number={dashboardAnalytic?.total_cases}
    //             imgName={FolderImg}
    //             alt="folder image"
    //             loading={loading}
    //           />
    //           <DashboardCard
    //             headerText="High Value Cases"
    //             number={dashboardAnalytic?.high_value_cases}
    //             imgName={PaperImg}
    //             alt="paper image"
    //             loading={loading}
    //           />
    //           <DashboardCard
    //             headerText="Referrals"
    //             number={dashboardAnalytic?.total_referrals}
    //             imgName={NetworkImg}
    //             alt="network image"
    //             borderRadius={true}
    //             loading={loading}
    //           />
    //         </Box> */}
    //       </Stack>

    //       {/* 2nd BOX USER FEED */}
    //       {/* <Stack
    //         boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
    //         padding="24px"
    //         borderRadius="8px"
    //         maxWidth="347px"
    //         width="100%"
    //         sx={{ flexGrow: "1" }}
    //       >
    //         <Stack gap="16px" height="418px" sx={{ flexGrow: 1 }}>
    //           <Box
    //             display="flex"
    //             justifyContent="flex-start"
    //             gap="4px"
    //             alignItems="flex-start"
    //           >
    //             <Typography
    //               variant="h2"
    //               fontSize="16px"
    //               fontWeight="500"
    //               color="secondary"
    //               textTransform="uppercase"
    //               lineHeight="28px"
    //             >
    //               User Feed
    //             </Typography>
    //             <Tooltip
    //               placement="top"
    //               arrow
    //               PopperProps={{
    //                 sx: {
    //                   marginBottom: "-7px !important",
    //                 },
    //               }}
    //             >
    //               <InfoCircle />
    //             </Tooltip>
    //           </Box>
    //           {loadingUserFeeds && userFeedNotifications?.length === 0 ? (
    //             <RectangleSkeletons height={67} />
    //           ) : userFeedNotifications?.length > 0 ? (
    //             <div
    //               id="scrollableDiv"
    //               style={{ overflow: "auto", height: "400px" }}
    //             >
    //               <InfiniteScroll
    //                 dataLength={userFeedNotifications.length || []}
    //                 next={fetchMoreData}
    //                 hasMore={hasNext}
    //                 loader={
    //                   <CircularProgressLoading size={20} overflow="hidden" />
    //                 }
    //                 scrollableTarget="scrollableDiv"
    //                 style={{
    //                   display: "flex",
    //                   flexDirection: "column",
    //                   gap: "8px",
    //                 }}
    //               >
    //                 {userFeedNotifications?.map((data, index) => (
    //                   <UserFeedCard data={data} key={index} />
    //                 ))}
    //               </InfiniteScroll>
    //             </div>
    //           ) : (
    //             <Stack
    //               gap="16px"
    //               alignItems="center"
    //               justifyContent="center"
    //               height="100%"
    //             >
    //               <Box width="128px" height="128px">
    //                 <img src={EmptyBox} alt="empty box" width="100%" />
    //               </Box>
    //               <Typography variant="body1">No user feeds yet</Typography>
    //             </Stack>
    //           )}
    //         </Stack>
    //         <Box
    //             position="absolute"
    //             top="-23px"
    //             right="-23px"
    //             width="215px"
    //             sx={{ transform: "rotate(17.166deg)" }}
    //           >
    //             <img src={bellIcon} alt="notification" width="100%" />
    //           </Box>

    //       <Stack
    //             alignItems="flex-start"
    //             justifyContent="flex-end"
    //             gap="24px"
    //           >

    //             <Typography variant="body1" fontSize="18px">
    //               Instant Case Updates Through Push Notifications!
    //             </Typography>
    //             <Button
    //               href="/marketing?tab=push_notifications"
    //               variant="contained"
    //               size="large"
    //             >
    //               Set up push notifications
    //             </Button>
    //           </Stack>
    //       </Stack> */}
    //     </Box>

    //     {/* 3rd ROW CARDS CHAT AND CASES */}
    //     {/* <Grid container spacing={3}>
    //       NEW MESSAGES BOX
    //       <Grid item md={6}>
    //         <Box
    //           boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
    //           borderRadius="8px"
    //           bgcolor="#FFF"
    //           height="348px"
    //           padding="32px"
    //         >
    //           <Typography
    //             variant="h2"
    //             fontSize="16px"
    //             fontWeight="500"
    //             color="secondary"
    //             textTransform="uppercase"
    //             lineHeight="28px"
    //             paddingBottom="24px"
    //           >
    //             New Messages
    //           </Typography>

    //           {loadingUnreadChatMessages ? (
    //             <RectangleSkeletons height={78} totalRows={2} />
    //           ) : unreadChatMessages?.unreadMessages?.length > 0 ? (
    //             <Stack gap="8px" overflow="auto" height="256px">
    //               {unreadChatMessages?.unreadMessages.map((message, i) => (
    //                 <NewMessageCard
    //                   key={i}
    //                   senderProfile={message?.case?.client?.profile_picture_url}
    //                   senderName={message?.senderDisplayName}
    //                   chatDate={dateTimeFormat(message?.createdOn)}
    //                   messageContent={message?.content}
    //                   case_id={message?.case?.id}
    //                 />
    //               ))}
    //             </Stack>
    //           ) : (
    //             <Stack gap="16px" alignItems="center" justifyContent="center">
    //               <Box width="128px" height="128px">
    //                 <img src={EmptyBox} alt="empty box" width="100%" />
    //               </Box>
    //               <Typography variant="body1">
    //                 No chat notifications yet
    //               </Typography>
    //             </Stack>
    //           )}
    //         </Box>
    //       </Grid>

    //       AT RISK CASES BOX
    //       <Grid item md={6}>
    //         <Box
    //           boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
    //           borderRadius="8px"
    //           bgcolor="#FFF"
    //           height="348px"
    //           padding="32px"
    //         >
    //           <Box
    //             display="flex"
    //             justifyContent="flex-start"
    //             gap="4px"
    //             alignItems="flex-start"
    //             paddingBottom="24px"
    //           >
    //             <Typography
    //               variant="h2"
    //               fontSize="16px"
    //               fontWeight="500"
    //               color="secondary"
    //               textTransform="uppercase"
    //               lineHeight="28px"
    //             >
    //               At Risk cases
    //             </Typography>
    //             <Tooltip
    //               title={
    //                 "Cases with no activity for the last 7 consecutive days are considered 'at risk' and are listed here"
    //               }
    //               placement="top"
    //               arrow
    //               PopperProps={{
    //                 sx: {
    //                   marginBottom: "-7px !important",
    //                 },
    //               }}
    //             >
    //               <InfoCircle />
    //             </Tooltip>
    //           </Box>

    //           {loadingHighRiskCases ? (
    //             <RectangleSkeletons height={78} totalRows={2} />
    //           ) : highRiskCases?.high_risk_cases?.length === 0 ? (
    //             <Stack gap="16px" alignItems="center" justifyContent="center">
    //               <RiskCaseEmptyState />
    //               <Typography variant="body1" width="291px" textAlign="center">
    //                 All your cases are in the clear, no at-risk cases in sight.
    //               </Typography>
    //             </Stack>
    //           ) : (
    //             <Stack width="100%" gap="8px" overflow="auto" height="256px">
    //               {highRiskCases?.high_risk_cases?.map((caseDetail, i) => (
    //                 <RiskCasesCard
    //                   key={i}
    //                   caseTitle={caseDetail?.title}
    //                   caseStatus={caseDetail?.status}
    //                   caseUpdate={timeDateFormat(caseDetail?.updated_at)}
    //                   profilePhoto={caseDetail?.client?.profile_picture_url}
    //                   clientName={caseDetail?.client?.name}
    //                   caseId={caseDetail?.id}
    //                 />
    //               ))}
    //             </Stack>
    //           )}
    //         </Box>
    //       </Grid>
    //     </Grid> */}
    //   </Stack>
    // </>
  );
};

export default DashboardComponent;
