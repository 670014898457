import React from "react";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: "list-item",
  listStyleType: "disc",
  listStylePosition: "outside",
  fontSize: "12px !important",
  marginLeft: "4px !important",
}));

const PasswordConstraint = () => {
  return (
    <>
      <StyledTypography component={"span"}>
        Minimum 8 characters long - the more, the better
      </StyledTypography>
      <StyledTypography component={"span"}>
        At least one lowercase & one uppercase character
      </StyledTypography>
      <StyledTypography component={"span"}>
        At least one number, symbol, or whitespace character
      </StyledTypography>
    </>
  );
};

export default PasswordConstraint;
