/* eslint-disable */

import React from "react";
import { Chart } from "chart.js";
import * as Chartjs from "chart.js";
import { Bar, Line } from "react-chartjs-2";
// import {
//   CategoryScale,
//   Chart as ChartJS,
//   LineElement,
//   LinearScale,
//   PointElement,
//   Tooltip,
// } from "chart.js";
import dayjs from "dayjs";
import { getRandomBackgroundColor } from "../../utils/helpers";

const controllers = Object.values(Chartjs).filter(
  (chart) => chart.id !== undefined
);

const skipped = (ctx, value) =>
  ctx.p0.skip || ctx.p1.skip ? value : undefined;

Chart.register(...controllers);

const StepCountChart = ({ allDates, fromPdf = true, chartRef }) => {
  const labels = fromPdf
    ? allDates?.map((data) => dayjs(data?.date).format("MM/DD"))
    : allDates?.reverse()?.map((data) => dayjs(data?.date).format("MM/DD"));

  const sleep_loss_data = allDates?.map((date) => {
    const sleepLossEntry = date;
    return sleepLossEntry?.missed === false ? sleepLossEntry?.step_count : null;
  });

  const maxStepCount = Math.max(...sleep_loss_data);
  const minStepCount = Math.min(
    ...sleep_loss_data.filter((value) => value > 0)
  );

  const stepSize =
    minStepCount > 0
      ? Math.max(1, Math.ceil(maxStepCount / Math.min(10, minStepCount)))
      : Math.ceil(maxStepCount / 10);

  const maxTicksLimit = 10;

  const data = {
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Step Count",
        backgroundColor: "#E9E9E9",
        barThickness: 12,
        data: sleep_loss_data,
        borderRadius: 90,
        hoverBackgroundColor: "#0C80F3",
      },
    ],
  };

  const options = {
    responsive: true,

    scales: {
      y: {
        display: false,
        beginAtZero: true,
        max: maxStepCount,
        ticks: {
          stepSize: stepSize,
          maxTicksLimit: maxTicksLimit,
          callback: (value, index, values) => {
            return value === maxStepCount ? null : value;
          },
        },
      },
      x: {
        grid: {
          display: true,
          drawBorder: false,
          color: "#E9E9E9", // Dotted line color
          lineWidth: 0,
          borderDash: [10, 10], // Dotted line style
        },
        ticks: {
          display: false, // Hide x-axis labels/ticks
        },
      },
    },
    plugins: {
      customCanvasBackgroundColor: {
        color: "#fff",
      },
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context) {
            return `${context?.raw ? context?.raw : 0} ${
              context?.raw === "0" ||
              context?.raw === "1" ||
              context?.raw === null
                ? "Step"
                : "Steps"
            }`;
          },
          title: function (context) {
            return labels[context[0].dataIndex];
          },
        },
      },
    },

    hover: {
      mode: "index",
      intersect: false,
    },
  };

  // const options = {
  //   plugins: {
  //     legend: false,
  //     // tooltip: {
  //     //   callbacks: {
  //     //     title: (xDatapoint) => {
  //     //       console.log(xDatapoint);
  //     //       // return formatXValue(xDatapoint.raw);
  //     //     },
  //     //     label: (yDatapoint) => {
  //     //       console.log(yDatapoint);
  //     //       // return formatYValue(yDatapoint.raw);
  //     //     },
  //     //   },
  //     // },
  //   },
  //   showTooltips: true,
  //   // tooltips: {
  //   //   mode: "index",
  //   //   intersect: false,
  //   // },
  //   hover: {
  //     mode: "index",
  //     intersect: false,
  //   },
  //   maintainAspectRatio: false,
  //   responsive: true,
  //   hoverRadius: 30,
  //   // scales: {
  //   //   x: {
  //   //     grid: { display: false },
  //   //   },
  //   //   y: {
  //   //     min: 0,
  //   //     max: 5,
  //   //     ticks: {
  //   //       stepSize: 1,
  //   //     },
  //   //     grid: {
  //   //       borderDash: 10,
  //   //     },
  //   //   },
  //   // },
  //   // tooltips: {
  //   //   callbacks: {
  //   //     label: function (tooltipItem, data) {
  //   //       console.log("TOOLTIPITEM", tooltipItem);
  //   //       console.log("DATA", data);
  //   //       const label = data.datasets[tooltipItem.datasetIndex].label || "";
  //   //       const value = tooltipItem.yLabel;
  //   //       return `${label}: ${value}`;
  //   //     },
  //   //   },
  //   // },
  // };

  return (
    <Line
      ref={chartRef}
      data={data}
      options={options}
      style={{ width: "100% !important" }}
    />
  );
};

export default StepCountChart;
