import { Alert, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { SmsIcon } from "../../assets/constants";
import CallIcon from "../../icons/CallIcon";
import { LoadingButton } from "@mui/lab";
import { dateFormat, timeFormat } from "../../utils/helpers";
import AppContactDetailCard from "../AppContactDetailCard";
import AppMobileInfoCard from "../AppMobileInfoCard";

const InactiveIconContent = ({
  patientDetail,
  firstLogin,
  lastAppOpened,
  osVersion,
  appVersion,
}) => {
  return (
    <Stack gap="24px">
      <Alert severity="warning">
        {patientDetail?.name} has not opened the app for the past 7 days. Please
        remind them to start using the app again to resume health monitoring.
      </Alert>

      <AppMobileInfoCard
        firstLogin={firstLogin}
        lastAppOpened={lastAppOpened}
        appVersion={appVersion}
        osVersion={osVersion}
      />

      <AppContactDetailCard patientDetail={patientDetail} status="inactive" />
    </Stack>
  );
};

export default InactiveIconContent;
