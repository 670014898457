import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDiagnosisOptionsFunc } from "../../actions/DiagnosisActions";
import { useSelector } from "react-redux";

const LoadingAutoComplete = ({ medicalCode, setMedicalCode }) => {
  const dispatch = useDispatch();
  const [diagnosticOptions, setDiagnosticOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  const getDiagnosisOptions = useSelector((state) => state.getDiagnosisOptions);
  const {
    loading,
    error: errorDiagnosticCode,
    diagnosisOptions,
  } = getDiagnosisOptions;

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  useEffect(() => {
    if (debouncedValue?.length >= 4) {
      dispatch(getDiagnosisOptionsFunc(debouncedValue));
    }
  }, [debouncedValue, dispatch]);

  useEffect(() => {
    if (diagnosisOptions?.ok) {
      setDiagnosticOptions(diagnosisOptions?.diagnostic_codes);
    }
  }, [diagnosisOptions]);

  return (
    <Autocomplete
      multiple
      id="diagnostic-codes"
      options={diagnosticOptions}
      value={medicalCode}
      onChange={(e, value) => {
        if (value) {
          setMedicalCode(value);
        }
      }}
      onInputChange={(e, value) => setInputValue(value)}
      fullWidth
      getOptionLabel={(option) => `${option?.code} - ${option?.description}`}
      isOptionEqualToValue={(option, value) =>
        option?.code === value?.code &&
        option?.description === value?.description
      }
      loading={loading}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          //   error={dateFieldIsTouched && !bodyPartsValue?.length}
          size="small"
          placeholder="Start typing the diagnosis code here..."
          //   onFocus={() => setBodyPartField(true)}
          //   onBlur={() => setBodyPartField(false)}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default LoadingAutoComplete;
