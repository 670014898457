import React, { useEffect, useState } from "react";
import BgBox from "../../common/BgBox";
import CardUI from "../../common/Card";
import HeaderText from "../../common/HeaderText";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Link as LinkTo, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPassword,
  resetForgotPassword,
  resetOtpVerify,
} from "../../actions/Auth";
import CircularProgressLoading from "../../common/CircularProgress";
import AlertMessage from "../../common/AlertMessage";
import MccLogo from "../../components/MccLogo";
import { LoadingButton } from "@mui/lab";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userForgotPassword = useSelector((state) => state.userForgotPassword);
  const { loading, error, res } = userForgotPassword;

  console.log("RRR", res);

  useEffect(() => {
    if (res?.data?.ok) {
      dispatch(resetForgotPassword());
      navigate(
        "/otp-verification",
        {
          state: { email: email, forgotPassword: true },
        },
        { replace: true }
      );
    }
  }, [res]);

  const passwordResetHandler = async (e) => {
    e.preventDefault();

    dispatch(forgotPassword(email));
    setOpen(true);

    // if (!success?.data?.ok) {
    //   navigate("/forgot-password");
    // }
  };

  return (
    <>
      {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      <BgBox>
        <CardUI paddingTop="86px">
          {/* MCC LOGO */}
          <MccLogo />

          <Grid container component="main">
            <Box
              component="form"
              onSubmit={passwordResetHandler}
              xs={12}
              display="flex"
              gap="40px"
              flexDirection="column"
              width="100%"
            >
              <Box>
                <HeaderText
                  variant="h3"
                  fontSize={34}
                  textContent="Forgot your password?"
                  lineHeight="24px"
                  textAlign="center"
                />
                <Typography
                  variant="body1"
                  className="d-flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize={16}
                  mt="16px"
                  color="#566875"
                >
                  No worries we'll send you reset instructions.
                </Typography>
              </Box>

              <Box display="flex" flexDirection="column" gap="16px">
                <TextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your registered email to continue"
                  label="Email"
                  variant="outlined"
                  name="email"
                  required
                />

                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  loading={loading}
                  // disabled={!email.length}
                  sx={{ padding: "8px 22px", fontSize: 15 }}
                >
                  Send OTP
                </LoadingButton>
              </Box>

              <Link component={LinkTo} to="/signin" textAlign="center">
                Back to Sign in page
              </Link>

              {/* <Button
          className="w-100"
          fontSize="15px"
          color="primary"
          
          title="Sign In"
          variant="contained"
          width="100%"
          height="48px"
          // disabled={!fieldVals?.email || !fieldVals?.password} // Disabling it off on purpose
          // onClick={() => onSignIn()}
          // loading={signInLoading}
          loadingColor="primary"
        /> */}
            </Box>
          </Grid>
        </CardUI>
      </BgBox>
    </>
  );
};

export default ForgotPassword;
