import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { PushNotificationWhiteIcon } from "../../assets/constants";
import { timeDateFormat } from "../../utils/helpers";

const PushNotificationsCard = ({ title, description, date }) => {
  return (
    <Box
      display="flex"
      gap="12px"
      bgcolor="#FCFCFC"
      padding="12px"
      borderRadius="8px"
    >
      <Box
        width="44px"
        height="44px"
        bgcolor="#0C80F3"
        borderRadius="8px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <PushNotificationWhiteIcon />
      </Box>
      <Stack>
        <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
          {timeDateFormat(date)}
        </Typography>
        <Typography variant="body1" fontSize="16px">
          {title}{" "}
        </Typography>
        <Typography variant="body2" color="secondary">
          {description}{" "}
        </Typography>
      </Stack>
    </Box>
  );
};

export default PushNotificationsCard;
