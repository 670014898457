import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { dateTimeFormat, getNotificationText } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import styles from "../NotificationDrawer/Notifications.module.css";

const ChatUserCard = ({ data, setOpenNotificationDrawer }) => {
  const navigate = useNavigate();

  const displayText = getNotificationText(data);

  return (
    <Box className={styles.all_container} bgcolor="#F4F6F8">
      <Avatar src={data?.client?.profile_picture_url} alt="name" />
      <Stack gap="8px">
        <Stack gap="4px">
          <Typography variant="body2" fontSize="16px">
            {data?.activity_performed_by}{" "}
            {data?.log_type === "chat_activity" ? (
              <>
                <span className={styles.lightText}>replied in </span>{" "}
                <span
                  className={styles.lightText}
                  //   onClick={() => {
                  //     navigate(`/patient/${data?.id}?tab=chat`);
                  //     setOpenNotificationDrawer((prevState) => !prevState);
                  //   }}
                >
                  chat
                </span>
              </>
            ) : (
              displayText
            )}
          </Typography>

          <Typography
            variant="caption"
            fontSize="12px"
            className={styles.lightText}
          >
            {dateTimeFormat(data.created_at)}
          </Typography>
        </Stack>
        {data?.log_type === "chat_activity" && (
          <Typography className={styles.description}>
            {data?.message_text}
          </Typography>
        )}

        {/* {data?.log_type === "document_uploaded" && (
          <Typography
            className={styles.blueTextLine}
            onClick={() => {
              if (data?.case_id) {
                navigate(`/case/${data?.case_id}?tab=documents`);
                setOpenNotificationDrawer((prevState) => !prevState);
              }
            }}
          >
            {data?.document_title}
          </Typography>
        )} */}

        {data?.log_type === "appointment_update" &&
          data?.appointment_reason && (
            <Typography className={styles.description}>
              Reason: {data?.appointment_reason}
            </Typography>
          )}
      </Stack>
    </Box>
  );
};

export default ChatUserCard;
