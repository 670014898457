import { Box, Skeleton, Typography, Avatar } from "@mui/material";

const AvatarWithName = ({
  loading,
  name,
  imageURL,
  size = 32,
  columnGap = "8px",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        columnGap: columnGap,
        height: "100%",
      }}
    >
      {loading ? (
        <>
          <Skeleton variant="circular">
            <Avatar sx={{ width: size, height: size }} />
          </Skeleton>
          <Skeleton>
            <Typography sx={{ fontSize: "0.875rem" }}></Typography>
          </Skeleton>
        </>
      ) : (
        <>
          <Avatar
            alt={name}
            src={imageURL}
            sx={{ width: size, height: size }}
          />
          <Typography id={`client-name-${name}`} sx={{ fontSize: "0.875rem" }}>
            {name}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default AvatarWithName;
