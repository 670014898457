import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CardUI from "../../../common/Card";
import HeaderText from "../../../common/HeaderText";
import SortIcon from "../../../icons/SortIcon";
import AddIcon from "@mui/icons-material/Add";
import EmptyBox from "../../../assets/images/empty-box.png";

import { useDispatch, useSelector } from "react-redux";
import {
  getCaseDetailByCaseId,
  resetUpdateCase,
  updateCaseFunc,
} from "../../../actions/Cases";
import dayjs from "dayjs";
import {
  clearContact,
  dateFormat,
  formatPhoneNumber,
  notesOptions,
} from "../../../utils/helpers";

import CaseMoreInfoSkeleton from "../../../components/CaseMoreInfoSkeleton";

import EditCaseField from "../../../components/EditCaseField";
import { segmentError } from "../../../utils/helpers/tracking_utils";
import { appInsights } from "../../../utils/helpers/AppInsights";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getAgentsListFunc,
  resetUpdatedTeamMember,
  updateAgentTeamMembersFunc,
} from "../../../actions/Agency";

import { statesWithCities } from "../../../utils/helpers/states_with_cities";
import { getNotesFunc, resetGetNotes } from "../../../actions/TimeEntry";
import NoteLogsCard from "../../../components/NoteLogsCard";
import RectangleSkeletons from "../../../components/RectangleSkeletons";
import { LoadingButton } from "@mui/lab";
import {
  createCustomNotesFunc,
  resetCreateCustomNotes,
} from "../../../actions/AppConfig";
import Cookies from "js-cookie";

const states = Object.keys(statesWithCities);

const MoreInfoTab = ({ caseDetail, loading }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [modalStates, setModalStates] = useState({
    // =========================
    members: false,
    contact_number: false,
    dob: false,
    address: false,
    country: false,
    region: false,
    city: false,
    zipcode: false,
    city: false,
    additional_info: false,
    primary_insurance_provider_name: false,
    secondary_insurance_provider_name: false,
    policy_number: false,
    group_number: false,
    primary_policy_holder_name: false,
    primary_policy_holder_dob: null,
    primary_policy_holder_relation: false,
  });

  const [dob, setDob] = useState(null);
  const [primaryPolicyHolderDob, setPrimaryPolicyHolderDob] = useState(null);
  const [address, setAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [assignedTeamMembers, setAssignedTeamMembers] = useState([]);
  const [state, setState] = useState(null);
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [primaryInsuranceName, setPrimaryInsuranceName] = useState("");
  const [secondaryInsuranceName, setSecondaryInsuranceName] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [groupNumber, setGroupNumber] = useState("");
  const [primaryPolicyHolderName, setPrimaryPolicyHolderName] = useState("");
  const [primaryPolicyRelation, setPrimaryPolicyRelation] = useState("");
  const [notes, setNotes] = useState("");
  const [reverseArray, setReverseArray] = useState(false);
  const [selectedLogsFilters, setSelectedLogsFilters] = useState(["all"]);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openPrimaryDatePicker, setOpenPrimaryDatePicker] = useState(false);

  const [caseFieldIsTouched, setCaseFieldIsTouched] = useState(false);
  const [caseAttributeField, setCaseAttributeField] = useState(null);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [openAddNoteBox, setOpenAddNoteBox] = useState(false);
  const [customNotesValue, setCustomNotesValue] = useState("");

  const patientId = caseDetail?.patient?.id;

  const dispatch = useDispatch();
  const agencyId = Cookies.get("agency_id_vita");

  const agentProfile = useSelector((state) => state.agentProfile);
  const {
    loading: loadingAgentSelf,
    error: errorAgentSelf,
    agentUser,
  } = agentProfile;

  const getAgentsList = useSelector((state) => state.getAgentsList);
  const { loading: loadingAgent, error: errorAgent, allAgents } = getAgentsList;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const getNotes = useSelector((state) => state.getNotes);
  const {
    loading: loadingNotes,
    error: errorNotes,
    notes: notesLogs,
  } = getNotes;

  const createCustomNotes = useSelector((state) => state.createCustomNotes);
  const {
    loading: loadingCustomNotes,
    error: errorCustomNotes,
    customNotes,
  } = createCustomNotes;

  const updateCase = useSelector((state) => state.updateCase);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    updatedCase,
  } = updateCase;

  const updateAgentTeamMembers = useSelector(
    (state) => state.updateAgentTeamMembers
  );
  const {
    loading: loadingUpdateMembers,
    error: errorUpdateMembers,
    updatedAgentTeamMembers,
  } = updateAgentTeamMembers;

  const allAgentsOptions =
    allAgents?.agents_list.length > 0
      ? allAgents?.agents_list?.filter(
          (agent) => agent?.agent_type !== "mcc_support"
        )
      : [];

  const assignedTeamMembersId = assignedTeamMembers?.map(
    (member) => member?.id
  );

  useEffect(() => {
    dispatch(getAgentsListFunc());
  }, []);

  useEffect(() => {
    if (patientId) {
      // if (!notesLogs?.notes) {
      //   dispatch(getNotesFunc(patientId));
      // }
      setContactNumber(caseDetail?.patient?.contact_number || "");
      setDob(caseDetail?.patient?.dob ? dayjs(caseDetail?.patient?.dob) : null);
      setAddress(caseDetail?.patient?.address || "");
      setState(caseDetail?.patient?.region || null);
      setCity(caseDetail?.patient?.city || "");
      setZipcode(caseDetail?.patient?.zipcode || "");
      setAssignedTeamMembers(
        caseDetail?.patient?.agents ? caseDetail?.patient?.agents : []
      );
      setPrimaryInsuranceName(
        caseDetail?.patient?.primary_insurance_provider_name || ""
      );
      setSecondaryInsuranceName(
        caseDetail?.patient?.secondary_insurance_provider_name || ""
      );
      setPolicyNumber(caseDetail?.patient?.policy_number || "");
      setGroupNumber(caseDetail?.patient?.group_number || "");
      setPrimaryPolicyHolderName(
        caseDetail?.patient?.primary_policy_holder_name || ""
      );
      setPrimaryPolicyHolderDob(
        caseDetail?.patient?.primary_policy_holder_dob
          ? dayjs(caseDetail?.patient?.primary_policy_holder_dob)
          : null
      );
      setPrimaryPolicyRelation(
        caseDetail?.patient?.primary_policy_holder_relation || ""
      );
      setNotes(caseDetail?.patient?.additional_info || "");
    }
  }, [patientId]);

  useEffect(() => {
    if (patientId) {
      let filtered_data;

      if (selectedLogsFilters.includes("all")) {
        // If 'All' filter is selected, display all logs
        filtered_data = caseDetail?.patient?.patient_notes;
      } else {
        // Filter logs based on selected filters
        filtered_data = caseDetail?.patient?.patient_notes?.filter((item) => {
          // Filter based on log_type
          if (
            selectedLogsFilters.includes("timer") &&
            selectedLogsFilters.includes("enrollment") &&
            selectedLogsFilters.includes("custom_note")
            // selectedLogsFilters.includes("other")
          ) {
            // If all filters are selected, show all logs
            return true;
          } else {
            // Check individual filters
            let match = false;
            if (selectedLogsFilters.includes("timer")) {
              match = match || item?.note_category === "time_entry";
            }
            if (selectedLogsFilters.includes("enrollment")) {
              match = match || item?.note_category === "enrollment";
            }
            if (selectedLogsFilters?.includes("custom_note")) {
              match = match || item.note_category === "custom_note";
            }
            // if (selectedLogsFilters.includes("other")) {
            //   match =
            //     match ||
            //     ![
            //       "appointment_update",
            //       "custom_note",
            //       "expense_added",
            //     ].includes(item.log_type);
            // }
            return match;
          }
        });
      }

      //  filtered_data = selectedLogsFilters?.includes("all")
      //   ? notesLogs?.notes
      //   : caseDetail?.patient?.activity_logs?.filter(
      //       (item) => item.log_type === "appointment_update"
      //     );
      setFilteredNotes(filtered_data);
    }
  }, [caseDetail, selectedLogsFilters]);

  const openEditFieldModalHandler = (id) => {
    setContactNumber(caseDetail?.patient?.contact_number);
    setDob(dayjs(caseDetail?.patient?.dob));
    setAddress(caseDetail?.patient?.address);
    setState(caseDetail?.patient?.region);
    setCity(caseDetail?.patient?.city);
    setZipcode(caseDetail?.patient?.zipcode);
    setAssignedTeamMembers(caseDetail?.patient?.agents);
    setPrimaryInsuranceName(
      caseDetail?.patient?.primary_insurance_provider_name
    );
    setSecondaryInsuranceName(
      caseDetail?.patient?.secondary_insurance_provider_name
    );
    setPolicyNumber(caseDetail?.patient?.policy_number);
    setGroupNumber(caseDetail?.patient?.group_number);
    setPrimaryPolicyHolderName(caseDetail?.patient?.primary_policy_holder_name);
    setPrimaryPolicyHolderDob(
      dayjs(caseDetail?.patient?.primary_policy_holder_dob)
    );
    setPrimaryPolicyRelation(
      caseDetail?.patient?.primary_policy_holder_relation
    );
    setNotes(caseDetail?.patient?.additional_info);

    if (modalStates[id]) {
      setModalStates((prevStates) => ({
        ...prevStates,
        [id]: false,
      }));
    } else {
      setModalStates((prevStates) => {
        const updatedStates = { ...prevStates };
        Object.keys(updatedStates).forEach((key) => {
          updatedStates[key] = key === id;
        });
        return updatedStates;
      });
    }
  };

  const closeEditModalHandler = (id) => {
    setModalStates((prevStates) => ({
      ...prevStates,
      [id]: false,
    }));
    setCaseFieldIsTouched(false);
    setContactNumber(caseDetail?.patient?.contact_number);
    setDob(caseDetail?.patient?.dob ? dayjs(caseDetail?.patient?.dob) : null);
    setAddress(caseDetail?.patient?.address);
    setState(caseDetail?.patient?.region);
    setCity(caseDetail?.patient?.city);
    setZipcode(caseDetail?.patient?.zipcode);
    setAssignedTeamMembers(caseDetail?.patient?.agents);
    setPrimaryInsuranceName(
      caseDetail?.patient?.primary_insurance_provider_name
    );
    setSecondaryInsuranceName(
      caseDetail?.patient?.secondary_insurance_provider_name
    );
    setPolicyNumber(caseDetail?.patient?.policy_number);
    setGroupNumber(caseDetail?.patient?.group_number);
    setPrimaryPolicyHolderName(caseDetail?.patient?.primary_policy_holder_name);
    setPrimaryPolicyHolderDob(
      caseDetail?.patient?.primary_policy_holder_dob
        ? dayjs(caseDetail?.patient?.primary_policy_holder_dob)
        : null
    );
    setPrimaryPolicyRelation(
      caseDetail?.patient?.primary_policy_holder_relation
    );
    setNotes(caseDetail?.patient?.additional_info);
  };

  const contactNumberHandler = (e) => {
    const input = e.target.value;
    const num = clearContact(input);
    const formattedNumber = formatPhoneNumber(num);
    setContactNumber(formattedNumber);
  };

  const saveFieldHandler = (value, case_attribute) => {
    setCaseFieldIsTouched(true);
    setCaseAttributeField(case_attribute);

    if (!value || value.length === 0) {
      return;
    }

    if (case_attribute === "members") {
      const assignTeamMemberData = {
        patient_id: caseDetail?.patient?.id,
        members: assignedTeamMembersId,
      };
      dispatch(updateAgentTeamMembersFunc(assignTeamMemberData));
    } else {
      let updatedCaseData;
      if (
        case_attribute === "dob" ||
        case_attribute === "primary_policy_holder_dob"
      ) {
        updatedCaseData = {
          [case_attribute]: value?.utcOffset(0, true),
        };
      } else {
        updatedCaseData = {
          [case_attribute]: value,
        };
      }

      const patientObj = {
        patient_id: caseDetail?.patient?.id,
        patient_profile: updatedCaseData,
      };

      dispatch(updateCaseFunc(patientObj));
    }
  };

  useEffect(() => {
    if (updatedCase?.ok) {
      dispatch(resetUpdateCase());
      dispatch(getCaseDetailByCaseId(caseDetail?.patient?.id));
      closeEditModalHandler(caseAttributeField);
    } else if (errorUpdate) {
      appInsights.trackTrace({
        message: `Error while editing case: ${
          errorUpdate || "Could not edit the case!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_EDIT_CASE",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${errorUpdate || "Could not edit the case!"}`,
      });
    }
  }, [updatedCase, errorUpdate]);

  useEffect(() => {
    if (updatedAgentTeamMembers?.ok) {
      dispatch(resetUpdatedTeamMember());
      dispatch(getCaseDetailByCaseId(caseDetail?.patient?.id));
      closeEditModalHandler(caseAttributeField);
    }
  }, [updatedAgentTeamMembers]);

  const clickFilterHandler = (value) => {
    let newSelectedFilters;

    if (value === "all") {
      newSelectedFilters = ["all"];
    } else if (selectedLogsFilters?.includes("all")) {
      newSelectedFilters = [value];
    } else {
      if (selectedLogsFilters.includes(value)) {
        newSelectedFilters = selectedLogsFilters.filter(
          (filter) => filter !== value
        );
      } else {
        newSelectedFilters = [...selectedLogsFilters, value];
      }
    }

    if (newSelectedFilters.length === 0) {
      newSelectedFilters = ["all"];
    }

    setSelectedLogsFilters(newSelectedFilters);
  };

  const sortCaseLogsHandler = () => {
    setReverseArray((prev) => !prev);
  };

  // console.log("FFFF", filteredNotes);

  const sortedCaseLogsData = reverseArray
    ? filteredNotes?.slice()?.reverse()
    : filteredNotes?.slice();

  const openNoteBoxHandler = () => {
    setOpenAddNoteBox(true);
  };

  const saveCustomNotesHandler = () => {
    if (!customNotesValue) {
      return;
    }

    const notesObj = {
      patient_id: patientId,
      agency_id: agencyId,
      note: customNotesValue,
    };

    dispatch(createCustomNotesFunc(notesObj));
  };

  useEffect(() => {
    if (customNotes?.ok) {
      dispatch(getCaseDetailByCaseId(patientId));
      dispatch(resetCreateCustomNotes());
      setOpenAddNoteBox(false);
      setCustomNotesValue("");
    }
  }, [customNotes]);

  return (
    <>
      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            color="success"
            sx={{ width: "100%" }}
          >
            Reminder sent
          </Alert>
        </Snackbar>
      )}

      <Box
        display="flex"
        width="100%"
        gap="18px"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack gap="18px" width="100%">
          <CardUI
            maxWidth="100%"
            padding="24px"
            boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
            paddingTop="24px"
            overflow="inherit"
          >
            {loading ? (
              <CaseMoreInfoSkeleton leftCard={true} />
            ) : (
              <Stack gap="16px">
                <Stack gap="16px" alignItems="flex-start">
                  <HeaderText
                    variant="h3"
                    textContent="Patient Information"
                    fontSize="18px"
                  />
                  <Stack>
                    <Box display="flex" alignItems="center" gap="8px">
                      <Avatar
                        src={
                          caseDetail?.patient?.profile_picture_filename &&
                          caseDetail?.patient?.profile_picture_url
                        }
                        alt="name"
                      />
                      <Stack>
                        <Typography
                          fontSize="16px"
                          variant="body1"
                          color="#000"
                          fontWeight="400"
                        >
                          {caseDetail?.patient?.name
                            ? caseDetail?.patient?.name
                            : "-"}
                        </Typography>

                        <Typography
                          variant="body2"
                          color="rgba(0, 0, 0, 0.38)"
                          fontWeight="400"
                        >
                          {caseDetail?.patient?.email}
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>

                  {/* ASSIGNED TO  */}
                  <Stack width="100%">
                    <EditCaseField
                      topPlacement="80px"
                      id="members"
                      label="Assigned to"
                      value={assignedTeamMembers}
                      displayValue={caseDetail?.patient?.agents}
                      closeEditModal={closeEditModalHandler}
                      modalStates={modalStates}
                      saveFieldHandler={saveFieldHandler}
                      openEditFieldModalHandler={openEditFieldModalHandler}
                      loadingUpdatedCase={loadingUpdateMembers}
                      padding="4px"
                      assignedTo={true}
                    >
                      <Autocomplete
                        multiple
                        fullWidth
                        id="assignTeamMembers"
                        options={allAgentsOptions}
                        getOptionLabel={(option) =>
                          option.name ? option.name : []
                        }
                        filterSelectedOptions
                        value={assignedTeamMembers}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        defaultValue={assignedTeamMembers}
                        onChange={(e, value) => {
                          setAssignedTeamMembers(value && value);
                        }}
                        renderTags={(value, props) =>
                          value.map((option, index) => (
                            <Chip
                              label={option.name}
                              {...props({ index })}
                              style={{
                                color: "#2196F3",
                                background: "#35A0F426",
                              }}
                              sx={{
                                "& .MuiChip-deleteIcon": {
                                  color: "#2196F3",
                                },
                              }}
                            />
                          ))
                        }
                        sx={{ width: "100%" }}
                        renderOption={(props, option) => {
                          const optionKey = `${option?.id}-${option?.name}`;

                          return (
                            <Box
                              key={optionKey}
                              component="li"
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              {...props}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                gap="8px"
                                maxWidth="326px"
                                width="100%"
                              >
                                <Avatar
                                  sx={{ width: "32px", height: "32px" }}
                                  src={
                                    option.profile_picture_url &&
                                    option.profile_picture_url
                                  }
                                  alt={option?.name}
                                />
                                <Typography
                                  variant="body2"
                                  id={`assignee-${option.name}`}
                                >
                                  {" "}
                                  {option.name}
                                </Typography>
                              </Box>
                              <Typography variant="caption" color="secondary">
                                {option.role}
                              </Typography>
                            </Box>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            name="assign-team-member"
                            label="Assigned to"
                            // helperText={
                            //   !teamMembersId.length &&
                            //   assignMemberError &&
                            //   "Please add team member"
                            // }
                            // error={!teamMembersId.length && assignMemberError}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "assign-team-member",
                            }}
                          />
                        )}
                      />
                    </EditCaseField>
                  </Stack>
                </Stack>

                <Stack gap="16px">
                  <Stack gap="4px" width="100%">
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {/* CONTACT NUMBER */}
                      <EditCaseField
                        id="contact_number"
                        label="Contact Number"
                        value={contactNumber}
                        displayValue={contactNumber}
                        closeEditModal={closeEditModalHandler}
                        modalStates={modalStates}
                        saveFieldHandler={saveFieldHandler}
                        openEditFieldModalHandler={openEditFieldModalHandler}
                        loadingUpdatedCase={loadingUpdate}
                        padding="4px"
                      >
                        <TextField
                          label="Contact Number"
                          size="small"
                          value={contactNumber}
                          variant="outlined"
                          // onChange={(e) => setContactNumber(e.target.value)}
                          onChange={contactNumberHandler}
                          fullWidth
                          error={!contactNumber && caseFieldIsTouched}
                        />
                      </EditCaseField>

                      {/* DATE OF BIRTH */}
                      <EditCaseField
                        id="dob"
                        label="Date of birth"
                        value={dob || null}
                        displayValue={dob === null ? "-" : dateFormat(dob)}
                        closeEditModal={closeEditModalHandler}
                        modalStates={modalStates}
                        saveFieldHandler={saveFieldHandler}
                        openEditFieldModalHandler={openEditFieldModalHandler}
                        loadingUpdatedCase={loadingUpdate}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Date of birth"
                            value={dob}
                            onChange={(newValue) => setDob(newValue)}
                            onOpen={() => setOpenDatePicker(true)}
                            onClose={() => setOpenDatePicker(false)}
                            open={openDatePicker}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: false,
                                onClick: () => setOpenDatePicker(true),
                              },
                            }}
                            sx={{ marginTop: 1 }}
                            disableFuture
                            textField={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </EditCaseField>
                    </Box>

                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {/* ADDRESS */}
                      <EditCaseField
                        id="address"
                        label="Address"
                        value={address}
                        displayValue={address}
                        closeEditModal={closeEditModalHandler}
                        modalStates={modalStates}
                        saveFieldHandler={saveFieldHandler}
                        openEditFieldModalHandler={openEditFieldModalHandler}
                        loadingUpdatedCase={loadingUpdate}
                        padding="4px"
                      >
                        <TextField
                          label="Address"
                          size="small"
                          value={address || ""}
                          variant="outlined"
                          onChange={(e) => setAddress(e.target.value)}
                          fullWidth
                          error={!address && caseFieldIsTouched}
                        />
                      </EditCaseField>

                      {/* STATE */}
                      <EditCaseField
                        id="region"
                        label="State"
                        value={state}
                        displayValue={state}
                        closeEditModal={closeEditModalHandler}
                        modalStates={modalStates}
                        saveFieldHandler={saveFieldHandler}
                        openEditFieldModalHandler={openEditFieldModalHandler}
                        loadingUpdatedCase={loadingUpdate}
                        padding="4px"
                      >
                        <Autocomplete
                          options={states}
                          fullWidth
                          value={state}
                          onChange={(event, newValue) => setState(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          // sx={{ marginTop: -1 }}
                        />
                      </EditCaseField>
                    </Box>

                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {/*  CITY */}
                      <EditCaseField
                        id="city"
                        label="City"
                        value={city}
                        displayValue={city}
                        closeEditModal={closeEditModalHandler}
                        modalStates={modalStates}
                        saveFieldHandler={saveFieldHandler}
                        openEditFieldModalHandler={openEditFieldModalHandler}
                        loadingUpdatedCase={loadingUpdate}
                        padding="4px"
                      >
                        <TextField
                          label="City"
                          size="small"
                          value={city}
                          variant="outlined"
                          onChange={(e) => setCity(e.target.value)}
                          fullWidth
                          error={!city && caseFieldIsTouched}
                        />
                      </EditCaseField>

                      {/*  CITY */}
                      <EditCaseField
                        id="zipcode"
                        label="Zip code"
                        value={zipcode}
                        displayValue={zipcode}
                        closeEditModal={closeEditModalHandler}
                        modalStates={modalStates}
                        saveFieldHandler={saveFieldHandler}
                        openEditFieldModalHandler={openEditFieldModalHandler}
                        loadingUpdatedCase={loadingUpdate}
                        padding="4px"
                      >
                        <TextField
                          label="Zip code"
                          size="small"
                          value={zipcode}
                          variant="outlined"
                          onChange={(e) => setZipcode(e.target.value)}
                          fullWidth
                          error={!zipcode && caseFieldIsTouched}
                        />
                      </EditCaseField>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </CardUI>

          {/* INSURANCE INFO CARD */}
          <CardUI
            maxWidth="100%"
            padding="24px"
            boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
            paddingTop="24px"
            overflow="inherit"
          >
            {loading ? (
              <CaseMoreInfoSkeleton />
            ) : (
              <Stack gap="16px">
                <Stack gap="16px" alignItems="flex-start">
                  <HeaderText
                    variant="h3"
                    textContent="Insurance information"
                    fontSize="18px"
                  />

                  <Stack gap="16px" width="100%">
                    {/* PRIMARY INSURANCE PROVIDER NAME */}
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <EditCaseField
                        id="primary_insurance_provider_name"
                        label="Primary Insurance Provider Name"
                        value={primaryInsuranceName}
                        displayValue={primaryInsuranceName}
                        closeEditModal={closeEditModalHandler}
                        modalStates={modalStates}
                        saveFieldHandler={saveFieldHandler}
                        openEditFieldModalHandler={openEditFieldModalHandler}
                        loadingUpdatedCase={loadingUpdate}
                        padding="4px"
                      >
                        <TextField
                          label="Primary Insurance Provider Name"
                          size="small"
                          value={primaryInsuranceName || ""}
                          variant="outlined"
                          onChange={(e) =>
                            setPrimaryInsuranceName(e.target.value)
                          }
                          fullWidth
                          error={!primaryInsuranceName && caseFieldIsTouched}
                        />
                      </EditCaseField>
                    </Box>

                    {/* SECONDARY INSURANCE NAME  */}
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <EditCaseField
                        id="secondary_insurance_provider_name"
                        label="Secondary Insurance Provider Name (if applicable)"
                        value={secondaryInsuranceName}
                        displayValue={secondaryInsuranceName}
                        closeEditModal={closeEditModalHandler}
                        modalStates={modalStates}
                        saveFieldHandler={saveFieldHandler}
                        openEditFieldModalHandler={openEditFieldModalHandler}
                        loadingUpdatedCase={loadingUpdate}
                        padding="4px"
                      >
                        <TextField
                          label="Secondary Insurance Provider Name (if applicable)"
                          size="small"
                          value={secondaryInsuranceName || ""}
                          variant="outlined"
                          onChange={(e) =>
                            setSecondaryInsuranceName(e.target.value)
                          }
                          fullWidth
                          error={!secondaryInsuranceName && caseFieldIsTouched}
                        />
                      </EditCaseField>
                    </Box>

                    <Box
                      width="100%"
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      {/* POLICY NUMBER */}
                      <EditCaseField
                        id="policy_number"
                        label="Policy Number/Subscription ID"
                        value={policyNumber}
                        displayValue={policyNumber}
                        closeEditModal={closeEditModalHandler}
                        modalStates={modalStates}
                        saveFieldHandler={saveFieldHandler}
                        openEditFieldModalHandler={openEditFieldModalHandler}
                        loadingUpdatedCase={loadingUpdate}
                        padding="4px"
                      >
                        <TextField
                          label="Policy Number/Subscription ID"
                          size="small"
                          value={policyNumber || ""}
                          variant="outlined"
                          onChange={(e) => setPolicyNumber(e.target.value)}
                          fullWidth
                          error={!policyNumber && caseFieldIsTouched}
                        />
                      </EditCaseField>

                      {/* Group Number/Name */}

                      <EditCaseField
                        id="group_number"
                        label="Group Number/Name"
                        value={groupNumber}
                        displayValue={groupNumber}
                        closeEditModal={closeEditModalHandler}
                        modalStates={modalStates}
                        saveFieldHandler={saveFieldHandler}
                        openEditFieldModalHandler={openEditFieldModalHandler}
                        loadingUpdatedCase={loadingUpdate}
                        padding="4px"
                      >
                        <TextField
                          label="Group Number/Name"
                          size="small"
                          value={groupNumber || ""}
                          variant="outlined"
                          onChange={(e) => setGroupNumber(e.target.value)}
                          fullWidth
                          error={!groupNumber && caseFieldIsTouched}
                        />
                      </EditCaseField>
                    </Box>

                    <Box
                      width="100%"
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      {/* POLICY NUMBER */}
                      <EditCaseField
                        id="primary_policy_holder_name"
                        label="Primary Policyholder's Name"
                        value={primaryPolicyHolderName}
                        displayValue={primaryPolicyHolderName}
                        closeEditModal={closeEditModalHandler}
                        modalStates={modalStates}
                        saveFieldHandler={saveFieldHandler}
                        openEditFieldModalHandler={openEditFieldModalHandler}
                        loadingUpdatedCase={loadingUpdate}
                        padding="4px"
                      >
                        <TextField
                          label="Primary Policyholder's Name"
                          size="small"
                          value={primaryPolicyHolderName || ""}
                          variant="outlined"
                          onChange={(e) =>
                            setPrimaryPolicyHolderName(e.target.value)
                          }
                          fullWidth
                          error={!primaryPolicyHolderName && caseFieldIsTouched}
                        />
                      </EditCaseField>

                      {/* Group Number/Name */}

                      <EditCaseField
                        id="primary_policy_holder_dob"
                        label="Primary Policyholder's Date of Birth"
                        value={primaryPolicyHolderDob || null}
                        displayValue={
                          primaryPolicyHolderDob === null
                            ? "-"
                            : dateFormat(primaryPolicyHolderDob)
                        }
                        closeEditModal={closeEditModalHandler}
                        modalStates={modalStates}
                        saveFieldHandler={saveFieldHandler}
                        openEditFieldModalHandler={openEditFieldModalHandler}
                        loadingUpdatedCase={loadingUpdate}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Primary Policyholder's Date of Birth"
                            value={primaryPolicyHolderDob}
                            onChange={(newValue) =>
                              setPrimaryPolicyHolderDob(newValue)
                            }
                            sx={{ marginTop: 1 }}
                            onOpen={() => setOpenPrimaryDatePicker(true)}
                            onClose={() => setOpenPrimaryDatePicker(false)}
                            open={openPrimaryDatePicker}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: false,
                                onClick: () => setOpenPrimaryDatePicker(true),
                              },
                            }}
                            textField={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </EditCaseField>
                    </Box>
                    {/* POLICY RELATION */}
                    <EditCaseField
                      id="primary_policy_holder_relation"
                      label="Relationship of Patient to Policyholder"
                      value={primaryPolicyRelation}
                      displayValue={primaryPolicyRelation}
                      closeEditModal={closeEditModalHandler}
                      modalStates={modalStates}
                      saveFieldHandler={saveFieldHandler}
                      openEditFieldModalHandler={openEditFieldModalHandler}
                      loadingUpdatedCase={loadingUpdate}
                      padding="4px"
                    >
                      <TextField
                        label="Relationship of Patient to Policyholder"
                        size="small"
                        value={primaryPolicyRelation || ""}
                        variant="outlined"
                        onChange={(e) =>
                          setPrimaryPolicyRelation(e.target.value)
                        }
                        fullWidth
                        error={!primaryPolicyRelation && caseFieldIsTouched}
                      />
                    </EditCaseField>

                    <EditCaseField
                      id="additional_info"
                      label="Notes/Coments"
                      value={notes}
                      displayValue={notes}
                      closeEditModal={closeEditModalHandler}
                      modalStates={modalStates}
                      saveFieldHandler={saveFieldHandler}
                      openEditFieldModalHandler={openEditFieldModalHandler}
                      loadingUpdatedCase={loadingUpdate}
                      padding="4px"
                    >
                      <TextField
                        label="Notes/Coments"
                        size="small"
                        value={notes || ""}
                        variant="outlined"
                        onChange={(e) => setNotes(e.target.value)}
                        fullWidth
                        error={!notes && caseFieldIsTouched}
                      />
                    </EditCaseField>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </CardUI>
        </Stack>

        {/* NOTES SECTION */}
        <Stack gap={3} maxWidth="551px" width="100%">
          <CardUI
            maxWidth="551px"
            padding="0"
            paddingTop="0"
            boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
            overflow="inherit"
            height="646px"
          >
            <Stack gap="16px" height="100%">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="8px"
                padding="24px 24px 0 24px"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap="12px"
                >
                  <Typography
                    variant="subtitle1"
                    fontSize="16px"
                    color="secondary"
                    sx={{ textTransform: "uppercase" }}
                  >
                    Notes{" "}
                  </Typography>
                  <IconButton
                    onClick={sortCaseLogsHandler}
                    sx={{
                      transform: reverseArray
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                  >
                    <SortIcon />
                  </IconButton>
                </Box>

                <Button
                  startIcon={<AddIcon />}
                  onClick={openNoteBoxHandler}
                  color="primary"
                  variant="text"
                  fontSize="13px"
                >
                  Add
                </Button>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                gap="8px"
                flexWrap="wrap"
                pb="12px"
                padding="0 24px"
              >
                {notesOptions?.map((item, index) => (
                  <Box
                    key={index}
                    padding="3px 10px"
                    borderRadius="4px"
                    bgcolor={
                      selectedLogsFilters?.includes(item?.value)
                        ? `${item?.bgColor}`
                        : "#F4F6F8"
                    }
                    color="#0D0D0D"
                    sx={{
                      boxShadow: selectedLogsFilters?.includes(item?.value)
                        ? `inset 0 0 0 1.5px ${item.borderColor}`
                        : ``,
                      "&:hover": {
                        background: `${item?.hoverBgColor}`,
                        color: "#fff !important",
                        transition: "background 300ms ease-out",
                      },
                      cursor: "pointer",
                    }}
                    onClick={() => clickFilterHandler(item?.value)}
                  >
                    <Typography variant="body2" fontSize="12px">
                      {" "}
                      {item.title}
                    </Typography>
                  </Box>
                ))}
              </Box>

              {openAddNoteBox && (
                <Stack gap="8px" margin="0 24px" paddingBottom="16px">
                  <Stack
                    height="170px"
                    padding="16px"
                    gap="7px"
                    borderRadius="8px"
                    border="1px solid #0C80F3"
                    bgcolor="rgba(12, 128, 243, 0.04);"
                  >
                    <Typography variant="body2" fontWeight={500}>
                      Add Note
                    </Typography>
                    <TextField
                      multiline
                      rows={4}
                      placeholder="Start typing here..."
                      variant="standard"
                      value={customNotesValue}
                      onChange={(e) => setCustomNotesValue(e.target.value)}
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                          {
                            borderBottom: "none",
                          },
                      }}
                    />
                  </Stack>
                  <Box display="flex" alignSelf="flex-end" gap="8px">
                    <Button
                      variant="text"
                      sx={{ color: "#000" }}
                      onClick={() => {
                        setOpenAddNoteBox(false);
                        setCustomNotesValue("");
                      }}
                      size="small"
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      size="small"
                      variant="contained"
                      onClick={saveCustomNotesHandler}
                      loading={loadingCustomNotes}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </Stack>
              )}

              {loading ? (
                <Stack padding="0 24px 0">
                  <RectangleSkeletons totalRows={4} height={100} />
                </Stack>
              ) : filteredNotes?.length === 0 ? (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Avatar
                    src={EmptyBox}
                    alt="coming soon"
                    sx={{ width: "128px", height: "128px" }}
                  />
                  <Typography variant="body1" color="secondary">
                    No Activity Yet
                  </Typography>
                </Stack>
              ) : (
                <Stack
                  gap="8px"
                  overflow="auto"
                  height="500px"
                  padding="0 24px 0 24px"
                >
                  {sortedCaseLogsData?.map((item, index) => (
                    <NoteLogsCard
                      key={index}
                      time={item?.time}
                      startTime={item?.start_time}
                      endTime={item?.end_time}
                      type={item?.note_category}
                      notesText={item?.note}
                      agentId={item?.agent_id}
                    />
                  ))}
                </Stack>
              )}
            </Stack>
          </CardUI>
        </Stack>
      </Box>
    </>
  );
};

export default MoreInfoTab;
