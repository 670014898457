import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { CptClockIcon } from "../../assets/constants";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { dateTimeFormat, getCptCodeSubtitleText } from "../../utils/helpers";

const CptCard = ({ cptCode, openDrawerHandler, cptItem, approvedAt }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="8px"
      bgcolor="#FCFCFC"
      padding="8px"
      borderRadius="13px"
      onClick={() => openDrawerHandler(cptItem)}
      sx={{ cursor: "pointer" }}
    >
      <Box
        bgcolor="rgba(61, 197, 131, 0.10)"
        border="1px solid #3DC583"
        borderRadius="6px"
        padding="8px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CptClockIcon />
      </Box>
      <Stack width="191px">
        <Typography variant="body1">CPT Code {cptCode}</Typography>
        <Typography variant="caption">
          {" "}
          {cptCode === "98977"
            ? "Musculoskeletal RTM"
            : cptCode === "98975"
            ? "Initial Setup"
            : getCptCodeSubtitleText(cptCode)}{" "}
        </Typography>
      </Stack>
      <Stack flex="1">
        <Typography variant="body2">Completed on</Typography>
        <Typography variant="caption">{dateTimeFormat(approvedAt)}</Typography>
      </Stack>
      {/* <Box>
        <IconButton
          aria-label="cpt-menu"
          id="cpt-menu"
          aria-controls={open ? "cpt-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="cpt-menu"
          MenuListProps={{
            "aria-labelledby": "cpt-menu",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          //   PaperProps={{
          //     style: {
          //       maxHeight: ITEM_HEIGHT * 4.5,
          //       width: "20ch",
          //     },
          //   }}
        >
          <MenuItem>Edit</MenuItem>
          <MenuItem>Delete</MenuItem>
        </Menu>
      </Box> */}
    </Box>
  );
};

export default CptCard;
