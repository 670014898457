import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  Link,
} from "@mui/material";
// import DashboardImg from "../../assets/images/dashboard-img.png";
// import bellIcon from "../../assets/images/bell-img2.png";
// import DashboardCard from "../DashboardCard";
// import FolderImg from "../../assets/images/folder-img.png";
// import PaperImg from "../../assets/images/paper-img.png";
// import NetworkImg from "../../assets/images/network-img.png";
// import { InfoCircle } from "../../assets/constants";
import { MessageFav } from "../../assets/constants";
import ProfilePicture from "../../common/ProfilePicture";
import { Link as LinkTo } from "react-router-dom";

const NewMessageCard = ({
  senderProfile,
  senderName,
  chatDate,
  messageContent,
  case_id,
}) => {
  const parsedMessage = JSON.parse(messageContent);

  return (
    <Box
      padding="16px"
      borderRadius="8px"
      bgcolor="#F4F6F8"
      display="flex"
      gap="12px"
      width="100%"
    >
      <Box
        width="40px"
        height="40px"
        padding="6px"
        bgcolor="#fff"
        borderRadius="4px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <MessageFav />
      </Box>

      <Stack width="100%" gap="8px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          gap="8px"
        >
          <Box display="flex" gap="8px" alignItems="center">
            <ProfilePicture
              width="20px"
              height="20px"
              src={senderProfile}
              alt={senderName}
            />
            <Typography variant="body1">
              {senderName} replied in{" "}
              <Box
                component="span"
                color="#0C80F3"
                sx={{ textDecoration: "underline" }}
              >
                <Link
                  component={LinkTo}
                  to={`/patient/${case_id}?tab=chat`}
                  textAlign="center"
                  underline="none"
                >
                  chat
                </Link>
              </Box>{" "}
            </Typography>
          </Box>
          <Typography variant="body1" color="secondary" fontSize="12px">
            {chatDate}
          </Typography>
        </Box>
        <Typography variant="body1" color="#566875" fontSize="14px">
          {parsedMessage?.message}
        </Typography>
      </Stack>
    </Box>
  );
};

export default NewMessageCard;
