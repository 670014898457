import React from "react";

const PauseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M6.2125 11.1475V2.8525C6.2125 2.065 5.88 1.75 5.04 1.75H2.9225C2.0825 1.75 1.75 2.065 1.75 2.8525V11.1475C1.75 11.935 2.0825 12.25 2.9225 12.25H5.04C5.88 12.25 6.2125 11.935 6.2125 11.1475Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2496 11.1475V2.8525C12.2496 2.065 11.9171 1.75 11.0771 1.75H8.95961C8.12544 1.75 7.78711 2.065 7.78711 2.8525V11.1475C7.78711 11.935 8.11961 12.25 8.95961 12.25H11.0771C11.9171 12.25 12.2496 11.935 12.2496 11.1475Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PauseIcon;
