import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StepCountDetailCard from "../StepCountDetailCard";
import ChatTabTableCard from "../ChatTabTableCard";
import PainLogsDate from "../PainLogsDate";
import PainLogDetail from "../PainLogDetail";
import AddDiagnosisCodeSection from "../AddDiagnosisCodeSection";
import { timeDateFormat } from "../../utils/helpers";
import { useSelector } from "react-redux";
import AvatarWithName from "../AvatarWithName";

const Cpt98975Content = ({ patientDetail }) => {
  const getAgentsList = useSelector((state) => state.getAgentsList);
  const { allAgents } = getAgentsList;

  const loggedAgent = allAgents?.agents_list?.find(
    (item) => item?.id === patientDetail?.initial_setup_done_by
  );

  return (
    <>
      <Stack gap="16px">
        <Typography
          variant="body1"
          fontWeight={500}
          color="secondary"
          textTransform="uppercase"
        >
          Code requirements{" "}
        </Typography>
        <Stack bgcolor="#fff" gap="4px">
          <Box
            border="1px solid #EBEBEB"
            bgcolor="#fff"
            borderRadius="8px"
            width="100%"
            padding="8px"
          >
            <Box
              bgcolor="#FCFCFC"
              borderRadius="8px"
              width="100%"
              padding="0 9px"
            >
              <FormControlLabel
                sx={{ marginRight: "0", flex: "1" }}
                control={
                  <Checkbox
                    sx={{ cursor: "default" }}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    checked={true}
                    color="success"
                    onChange={(e) => e.preventDefault()}
                  />
                }
                label="The initial setup of the equipment has been completed"
              />
            </Box>
          </Box>

          <Box
            border="1px solid #EBEBEB"
            bgcolor="#fff"
            borderRadius="8px"
            width="100%"
            padding="8px"
          >
            <Box
              bgcolor="#FCFCFC"
              borderRadius="8px"
              width="100%"
              padding="0 9px"
            >
              <FormControlLabel
                sx={{ marginRight: "0", flex: "1" }}
                control={
                  <Checkbox
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    checked={true}
                    color="success"
                    onChange={(e) => e.preventDefault()}
                  />
                }
                label="The patient has been educated on how to use the equipment"
              />
            </Box>
          </Box>
        </Stack>
      </Stack>
      {patientDetail?.initial_setup_notes && (
        <Stack gap="16px">
          <Typography
            variant="body1"
            color="secondary"
            fontWeight={500}
            textTransform="uppercase"
          >
            Interaction Notes
          </Typography>

          <Stack
            border="1px solid #EBEBEB"
            bgcolor="#fff"
            borderRadius="8px"
            width="100%"
            padding="16px 24px"
            gap="8px"
          >
            <Typography variant="body2">
              {patientDetail?.initial_setup_notes}
            </Typography>
            <Box display="flex" gap="7px">
              <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
                {timeDateFormat(patientDetail?.initial_setup_done_at)}
              </Typography>
              <AvatarWithName
                name={loggedAgent?.name}
                size={16}
                columnGap="2px"
                imageURL={loggedAgent?.profile_picture_url}
              />
            </Box>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default Cpt98975Content;
