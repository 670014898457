import {
  CREATE_TIME_ENTRY_FAIL,
  CREATE_TIME_ENTRY_REQUEST,
  CREATE_TIME_ENTRY_SUCCESS,
  DELETE_TIME_ENTRY_FAIL,
  DELETE_TIME_ENTRY_REQUEST,
  DELETE_TIME_ENTRY_SUCCESS,
  GET_NOTES_FAIL,
  GET_NOTES_REQUEST,
  GET_NOTES_SUCCESS,
  GET_TIME_ENTRY_FAIL,
  GET_TIME_ENTRY_REQUEST,
  GET_TIME_ENTRY_SUCCESS,
  RESET_CREATE_TIME_ENTRY,
  RESET_DELETE_TIME_ENTRY,
  RESET_GET_NOTES,
  RESET_GET_TIME_ENTRY,
  RESET_UPDATE_TIME_ENTRY,
  UPDATE_TIME_ENTRY_FAIL,
  UPDATE_TIME_ENTRY_REQUEST,
  UPDATE_TIME_ENTRY_SUCCESS,
} from "../../assets/constants/timeEntryConstants";

// CREATE TIME ENTRY REDUCER
export const createTimeEntryReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_TIME_ENTRY_REQUEST:
      return { loading: true };
    case CREATE_TIME_ENTRY_SUCCESS:
      return { loading: false, createdTimeEntry: action.payload };
    case CREATE_TIME_ENTRY_FAIL:
      return {
        loading: false,
        error: action.payload,
        overlapEntries: action.payload.overlapEntries,
      };
    case RESET_CREATE_TIME_ENTRY:
      return {};
    default:
      return state;
  }
};

export const getTimeEntryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TIME_ENTRY_REQUEST:
      return { loading: true };
    case GET_TIME_ENTRY_SUCCESS:
      return { loading: false, timeEntry: action.payload };
    case GET_TIME_ENTRY_FAIL:
      return { loading: false, error: action.payload };
    case RESET_GET_TIME_ENTRY:
      return {};
    default:
      return state;
  }
};

export const deleteTimeEntryReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_TIME_ENTRY_REQUEST:
      return { loading: true };
    case DELETE_TIME_ENTRY_SUCCESS:
      return { loading: false, deletedTimeEntry: action.payload };
    case DELETE_TIME_ENTRY_FAIL:
      return { loading: false, error: action.payload };
    case RESET_DELETE_TIME_ENTRY:
      return {};
    default:
      return state;
  }
};

export const updateTimeEntryReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TIME_ENTRY_REQUEST:
      return { loading: true };
    case UPDATE_TIME_ENTRY_SUCCESS:
      return { loading: false, updatedTimeEntry: action.payload };
    case UPDATE_TIME_ENTRY_FAIL:
      return {
        loading: false,
        error: action.payload,
        overlapEntries: action.payload.overlapEntries,
      };
    case RESET_UPDATE_TIME_ENTRY:
      return {};
    default:
      return state;
  }
};

export const getNotesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_NOTES_REQUEST:
      return { loading: true };
    case GET_NOTES_SUCCESS:
      return { loading: false, notes: action.payload };
    case GET_NOTES_FAIL:
      return { loading: false, error: action.payload };
    case RESET_GET_NOTES:
      return {};
    default:
      return state;
  }
};
