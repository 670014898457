import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SmsIcon } from "../../assets/constants";
import CallIcon from "../../icons/CallIcon";
import { LoadingButton } from "@mui/lab";
import AlertMessage from "../../common/AlertMessage";
import { useDispatch } from "react-redux";
import {
  resetInactiveReminder,
  resetPendingReminder,
  sendInactiveEmailReminderFunc,
  sendPendingEmailReminderFunc,
} from "../../actions/AppConfig";
import { useSelector } from "react-redux";

const AppContactDetailCard = ({ patientDetail, status }) => {
  const dispatch = useDispatch();
  const sendInactiveEmailReminder = useSelector(
    (state) => state.sendInactiveEmailReminder
  );
  const { loading, error, sentEmailReminder } = sendInactiveEmailReminder;
  const sendPendingEmailReminder = useSelector(
    (state) => state.sendPendingEmailReminder
  );
  const {
    loading: loadingPending,
    error: errorPending,
    sentPendingEmail,
  } = sendPendingEmailReminder;

  const [openAlertMessage, setOpenAlertMessage] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlertMessage(false);
  };

  const sendInactiveReminderHandler = () => {
    let patientObj;
    if (status === "inactive") {
      patientObj = {
        patient_id: patientDetail?.id,
      };
      dispatch(sendInactiveEmailReminderFunc(patientObj));
    } else {
      patientObj = {
        patient_id: patientDetail?.id,
      };

      dispatch(sendPendingEmailReminderFunc(patientObj));
    }
  };

  useEffect(() => {
    if (sentPendingEmail?.ok) {
      setOpenAlertMessage(true);
      dispatch(resetPendingReminder());
    }
  }, [sentPendingEmail]);

  useEffect(() => {
    if (sentEmailReminder?.ok) {
      setOpenAlertMessage(true);
      dispatch(resetInactiveReminder());
    }
  }, [sentEmailReminder]);

  return (
    <>
      <AlertMessage
        message={
          patientDetail?.contact_number
            ? "Reminder email and text sent."
            : "Reminder email sent."
        }
        severity="success"
        open={openAlertMessage}
        handleClose={handleClose}
      />
      <Stack bgcolor="#F4F6F8" borderRadius="4px" padding="16px" gap="24px">
        <Stack gap="13px">
          {(patientDetail?.email || patientDetail?.contact_number) && (
            <Typography variant="body1" fontWeight={500}>
              Contact Details
            </Typography>
          )}
          {patientDetail?.email && (
            <Box display="flex" alignItems="flex-start" gap="6px">
              <SmsIcon />
              <Stack>
                <Typography variant="body1" color="#212121">
                  Email Address
                </Typography>
                <Typography variant="body1" color="secondary">
                  {patientDetail?.email}
                </Typography>
              </Stack>
            </Box>
          )}
        </Stack>
        {patientDetail?.contact_number && (
          <Box display="flex" alignItems="flex-start" gap="6px">
            <CallIcon />
            <Stack>
              <Typography variant="body1" color="#212121">
                Phone Number
              </Typography>
              <Typography variant="body1" color="secondary">
                {patientDetail?.contact_number}
              </Typography>
            </Stack>
          </Box>
        )}

        <Box
          display="flex"
          alignItems="center"
          gap="8px"
          justifyContent="space-between"
        >
          <LoadingButton
            onClick={sendInactiveReminderHandler}
            loading={loading || loadingPending}
            fullWidth
            variant="contained"
            sx={{ textTransform: "uppercase" }}
          >
            {patientDetail?.contact_number
              ? "Send Email & Text Reminder"
              : "Send Email Reminder"}
          </LoadingButton>
        </Box>
      </Stack>
    </>
  );
};

export default AppContactDetailCard;
