export { ReactComponent as SignInVector } from "../images/signin.svg";
export { ReactComponent as SignUpVector } from "../images/signup.svg";
export { ReactComponent as DashboardIcon } from "../images/Dashboard-icon.svg";
export { ReactComponent as CasesIcon } from "../images/cases-icon.svg";
export { ReactComponent as ClientsIcon } from "../images/clients-icon.svg";
export { ReactComponent as VendorsIcon } from "../images/vendors-icon.svg";
export { ReactComponent as ReferralsIcon } from "../images/referrals-icon.svg";
export { ReactComponent as MarketingIcon } from "../images/marketing-icon.svg";
export { ReactComponent as SettingsIcon } from "../images/setting-icon.svg";
export { ReactComponent as TeamTabIcon } from "../images/team-tab-icon.svg";
export { ReactComponent as GeneralTabIcon } from "../images/general-tab-icon.svg";
export { ReactComponent as CasesTabIcon } from "../images/case-tab-icon.svg";
export { ReactComponent as EditIcon } from "../images/edit-icon.svg";
export { ReactComponent as DeleteIcon } from "../images/delete-icon.svg";
export { ReactComponent as BrandingIcon } from "../images/branding-icon.svg";
export { ReactComponent as PushNotificationIcon } from "../images/push-notifications-icon.svg";
export { ReactComponent as EmailMarketingIcon } from "../images/email-marketing-icon.svg";
export { ReactComponent as SecurityIcon } from "../images/security-icon.svg";
export { ReactComponent as SendClientIcon } from "../images/send-client-icon.svg";
export { ReactComponent as NotificationIcon } from "../images/notification.svg";
export { ReactComponent as HelpIcon } from "../images/help-icon.svg";
export { ReactComponent as Logo } from "../images/firm-logo.svg";
export { ReactComponent as GoogleBtn } from "../images/googleBtn.svg";
export { ReactComponent as AddLogoIcon } from "../images/add-logo-icon.svg";
export { ReactComponent as GoogleIcon } from "../images/google-icon.svg";
export { ReactComponent as UserTagIcon } from "../images/user-tag.svg";
export { ReactComponent as LogoutIcon } from "../images/logout.svg";
export { ReactComponent as FolderIcon } from "../images/folder-icon.svg";
export { ReactComponent as SendIcon } from "../images/send-icon.svg";
export { ReactComponent as UploadIcon } from "../images/upload-icon.svg";
export { ReactComponent as ArrowLeftIcon } from "../images/arrow-left.svg";
export { ReactComponent as PrinterIcon } from "../images/printer.svg";
export { ReactComponent as ChatIcon } from "../images/chat-icon.svg";
export { ReactComponent as FolderGreyIcon } from "../images/folder-grey-icon.svg";
export { ReactComponent as MoreInfoIcon } from "../images/more-info-icon.svg";
export { ReactComponent as StepperFolderIcon } from "../images/stepper-folder-icon.svg";
export { ReactComponent as StepperCalendarIcon } from "../images/stepper-calendar-icon.svg";
export { ReactComponent as StepperMessageIcon } from "../images/stepper-message-icon.svg";
export { ReactComponent as StepperCaseIcon } from "../images/stepper-case-icon.svg";
export { ReactComponent as StepperUserIcon } from "../images/stepper-user-icon.svg";
export { ReactComponent as DoubleCheckIcon } from "../images/double-check-icon.svg";
export { ReactComponent as InfoCircle } from "../images/info-circle.svg";
export { ReactComponent as DocCircle } from "../images/clock.svg";
export { ReactComponent as MessageFav } from "../images/message-favorite.svg";
export { ReactComponent as RiskCaseEmptyState } from "../images/empty-state-riskcases.svg";
export { ReactComponent as BillingIcon } from "../images/billing-icon.svg";
export { ReactComponent as InfoIcon } from "../images/info-icon.svg";
export { ReactComponent as CptClockIcon } from "../images/cpt-clock-icon.svg";
export { ReactComponent as TimerIcon } from "../images/timer.svg";
export { ReactComponent as RefreshIcon } from "../images/refresh.svg";
export { ReactComponent as AddPractiseIcon } from "../images/add_practise_icon.svg";
export { ReactComponent as AddPractiseIconBlack } from "../images/add-practise-icon.svg";
export { ReactComponent as BillingReceiptIcon } from "../images/billing-receipt-item.svg";
export { ReactComponent as TickCircleIcon } from "../images/tick-circle-icon.svg";
export { ReactComponent as PushNotificationWhiteIcon } from "../images/pushnotificationicon.svg";
export { ReactComponent as SettingPatientIcon } from "../images/setting-patients-icon.svg";
