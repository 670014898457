const Style = (theme) => ({
  fields__space: {
    "& .control-width": {
      width: "48.7%",
      "& .react-tel-input": {
        // marginTop: ' !important',
        marginBottom: "3px !important",
      },
    },
  },
  form__radio__group: {
    width: "100%",
    "& label": {
      paddingBottom: ".5rem",
      width: "100%",
      display: "flex",
      margin: "0",
      justifyContent: "space-between",
    },
  },

  filter__width: {
    "& .field-width": {
      width: "20rem",
    },
    "& .dropdown-width": {
      width: "10rem",
    },
  },
});
export default Style;
