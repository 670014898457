import React from "react";
import AvatarWithName from "../AvatarWithName";
import { Avatar, AvatarGroup, Box, Tooltip } from "@mui/material";

const SingleAvatar = ({ team_member }) => {
  return (
    <Tooltip title={team_member?.name}>
      <Avatar alt={team_member?.name} src={team_member?.profile_picture_url} />
    </Tooltip>
  );
};

const AvatarGroups = ({ assigned_to_details }) => {
  return (
    <>
      {assigned_to_details?.length < 1 ? (
        "-"
      ) : assigned_to_details?.length === 1 ? (
        <AvatarWithName
          name={assigned_to_details[0]?.name}
          imageURL={assigned_to_details[0]?.profile_picture_url}
        />
      ) : (
        <Box sx={{ height: "100%" }}>
          <AvatarGroup
            sx={{
              justifyContent: "flex-end",
              height: "100%",
              alignItems: "center",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                fontSize: "0.875rem",
              },
            }}
          >
            {assigned_to_details?.map((team_member, i) => (
              <SingleAvatar team_member={team_member} key={i} />
            ))}
          </AvatarGroup>
        </Box>
      )}
    </>
  );
};

export default AvatarGroups;
