import React, { useState } from "react";
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import Button from "../../common/Button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EmptyBox from "../../assets/images/empty-box.png";
import CircularProgressLoading from "../../common/CircularProgress";
import FsLightbox from "fslightbox-react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WaitingClockIcon from "../../icons/WaitingClockIcon";
import { checkFileExtension, timeDateFormat } from "../../utils/helpers";
import DocImg from "../../assets/images/doc-img.png";
import { CheckBox } from "@mui/icons-material";
import DocumentListViewCard from "../DocumentListViewCard";
import DocumentMenuItems from "../DocumentMenuItems";

const ListViewLayout = ({
  documentsRequest,
  documentRequestTitle,
  fromClientPage = false,
  loading,
  docsLoading,
  documents,
  toggleDrawer,
  setDrawerOpen,
  setSelectedDocument,
  selectedDocument,
  handleTitleClick,
  openDocumentMenu,
  handleDocumentClick,
  handleDownloadClick,
  setMenuFileIndex,
  setMenuFileDownloadURLs,
  toggler,
  lightboxSources,
  anchorDocumentEl,
  handleDocumentClose,
  documentDeleteHandler,
  renameDocumentHandler,
  moveToNewFolderFunction,
  selectedCheckedDoc,
  addMoreDocumentsHandler,
  fsLightBoxViewHandler,
  moveFileDialogHandler,
  changeCheckBoxHandler,
  anchorRequestDocEl,
  openRequestDocMenu,
  sendRequestDocReminderHandler,
  cancelDocRequestHandler,
  handleRequestDocClick,
  downloadImgAsPdfHandler,
}) => {
  // const [selectedDoc, setSelectedDoc] = useState([]);

  const handleBoxClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Stack gap="24px">
      {/* REQUEST DOCUMENTS CENTER */}
      {documentsRequest?.length ? (
        <Stack gap="8px">
          <Typography variant="subtitle2" color="secondary">
            Requested Documents
          </Typography>

          <Stack gap="16px">
            {documentsRequest?.map((doc, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "12px",
                  background: "#fff",
                  borderRadius: "8px",
                  border: "1px solid #EBEBEB",
                  padding: "12px",
                }}
              >
                <Box>
                  <WaitingClockIcon />
                </Box>
                <Stack flex="1">
                  <Typography
                    variant="boxHeading"
                    color="disabled.customs"
                    sx={{
                      // cursor: "pointer",
                      textDecoration: "none",
                      // "&:hover": {
                      //   textDecoration: "underline",
                      // },
                    }}
                  >
                    {doc?.document_title}
                  </Typography>

                  <Typography variant="caption" color="#ED6C02">
                    Waiting for client to upload
                  </Typography>
                </Stack>
                {!fromClientPage && (
                  <Box flex="1">
                    <span>-</span>
                  </Box>
                )}
                <Box flex="1">
                  <Chip
                    label={
                      doc?.document_type === "client_level" //doc?.document_type
                        ? "Personal"
                        : fromClientPage
                        ? documentRequestTitle(doc)
                        : documentRequestTitle
                    }
                    size="small"
                    sx={{
                      marginLeft: 0,
                      padding: "4px",
                      color: `${
                        doc?.document_type === "client_level"
                          ? "#2196F3"
                          : "#3DC583"
                      }`,
                      background: `${
                        doc?.document_type === "client_level"
                          ? "rgba(33, 150, 243, 0.15)"
                          : "rgba(61, 197, 131, 0.15)"
                      }`,
                    }}
                  />
                </Box>

                <Stack flex="1">
                  <Typography variant="body2">Requested on </Typography>
                  <Typography variant="caption" color="secondary">
                    {timeDateFormat(doc?.created_at)}
                  </Typography>
                </Stack>
                <IconButton
                  sx={{ padding: "0" }}
                  id="document-request"
                  aria-controls={
                    openRequestDocMenu ? "document-request" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openRequestDocMenu ? "true" : undefined}
                  onClick={(e) => {
                    setSelectedDocument(doc);
                    handleRequestDocClick(e);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            ))}
          </Stack>

          <Stack gap="8px">
            <Menu
              id="document-request"
              anchorEl={anchorRequestDocEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openRequestDocMenu}
              onClose={handleDocumentClose}
              MenuListProps={{
                "aria-labelledby": "document-request",
              }}
            >
              <MenuItem onClick={sendRequestDocReminderHandler}>
                Send Reminder
              </MenuItem>
              <MenuItem
                sx={{ color: "#FF2D2D" }}
                onClick={cancelDocRequestHandler}
              >
                Cancel Request
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>
      ) : (
        <></>
      )}

      {/* UPLOADED DOCUMENTS CENTER */}
      {!loading && !docsLoading && !documents?.length ? (
        <Stack alignItems="center" justifyContent="center" height="100%">
          <Avatar
            src={EmptyBox}
            alt="coming soon"
            sx={{
              width: "128px",
              height: "128px",
              paddingBottom: "24px",
            }}
          />
          <Typography variant="body1" color="secondary" pb={2}>
            All documents will show up here. you can also send document request
            to clients
          </Typography>
          <Button
            startIcon={<AddRoundedIcon />}
            title="Request New"
            color="primary"
            variant="contained"
            onClick={() => {
              toggleDrawer("right", true);
              setDrawerOpen(true);
            }}
          />
        </Stack>
      ) : docsLoading ? (
        <CircularProgressLoading />
      ) : (
        <Stack gap="16px">
          <Typography variant="subtitle2" color="secondary">
            Uploaded Documents
          </Typography>

          <Stack gap={2}>
            {documents?.map((doc, i) => {
              return (
                <React.Fragment key={i}>
                  <DocumentListViewCard
                    fromClientPage={fromClientPage}
                    handleTitleClick={handleTitleClick}
                    handleBoxClick={handleBoxClick}
                    changeCheckBoxHandler={changeCheckBoxHandler}
                    selectedCheckedDoc={selectedCheckedDoc}
                    openDocumentMenu={openDocumentMenu}
                    handleDocumentClick={handleDocumentClick}
                    setMenuFileIndex={setMenuFileIndex}
                    setMenuFileDownloadURLs={setMenuFileDownloadURLs}
                    setSelectedDocument={setSelectedDocument}
                    isFolder={doc?.type === "folder"}
                    fileName={doc?.file_name}
                    blob_name={doc?.blob_name}
                    fileUrl={doc?.file_url}
                    fileTitle={
                      doc?.type === "folder" ? doc?.name : doc?.file_name
                    }
                    numOfFiles={doc?.number_of_children}
                    fileSize={
                      doc?.type === "folder" ? doc?.size : doc?.file_size
                    }
                    doc={doc}
                    index={i}
                    documentType={
                      doc?.file_type === "client_level" ||
                      doc?.folder_type === "client_level"
                        ? "Personal"
                        : fromClientPage
                        ? documentRequestTitle(doc)
                        : documentRequestTitle
                    }
                    fsLightBoxViewHandler={fsLightBoxViewHandler}
                    fromInnerFolderView={false}
                  />
                </React.Fragment>
              );
            })}
          </Stack>

          <Stack gap="8px">
            <Stack>
              <FsLightbox toggler={toggler} sources={lightboxSources} />
              <DocumentMenuItems
                anchorDocumentEl={anchorDocumentEl}
                openDocumentMenu={openDocumentMenu}
                handleDocumentClose={handleDocumentClose}
                addMoreDocumentsHandler={addMoreDocumentsHandler}
                selectedDocument={selectedDocument}
                moveFileDialogHandler={moveFileDialogHandler}
                moveToNewFolderFunction={moveToNewFolderFunction}
                renameDocumentHandler={renameDocumentHandler}
                handleDownloadClick={handleDownloadClick}
                documentDeleteHandler={documentDeleteHandler}
                downloadImgAsPdfHandler={downloadImgAsPdfHandler}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default ListViewLayout;
