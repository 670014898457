import axios from "axios";
import {
  CPT_INIT_SETUP_FAIL,
  CPT_INIT_SETUP_REQUEST,
  CPT_INIT_SETUP_SUCCESS,
  EXPORT_REPORT_FAIL,
  EXPORT_REPORT_REQUEST,
  EXPORT_REPORT_SUCCESS,
  GET_CPT_CODES_FAIL,
  GET_CPT_CODES_REQUEST,
  GET_CPT_CODES_SUCCESS,
  REMOVE_CPT_DIAGNOSIS_CODE_FAIL,
  REMOVE_CPT_DIAGNOSIS_CODE_REQUEST,
  REMOVE_CPT_DIAGNOSIS_CODE_SUCCESS,
  RESET_CPT_CODES,
  RESET_CPT_INIT_SETUP,
  RESET_EXPORT_REPORT,
  RESET_UPDATE_CPT_CODE,
  UPDATE_CPT_CODES_FAIL,
  UPDATE_CPT_CODES_REQUEST,
  UPDATE_CPT_CODES_SUCCESS,
} from "../../assets/constants/cptCodesConstants";
import Cookies from "js-cookie";
import { BASE_URL } from "..";
import {
  ADD_CPT_DIAGNOSIS_CODE_FAIL,
  ADD_CPT_DIAGNOSIS_CODE_REQUEST,
  ADD_CPT_DIAGNOSIS_CODE_SUCCESS,
} from "../../assets/constants/diagnosisConstants";

// ============= SET UP INIT CPT CODE ACTIONS  ========================
export const initCptSetupFunc = (cptCodeObj) => async (dispatch) => {
  try {
    dispatch({ type: CPT_INIT_SETUP_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/cpt-code/record-initial-setup`,
        cptCodeObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: CPT_INIT_SETUP_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CPT_INIT_SETUP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetInitCptSetup = () => async (dispatch) => {
  dispatch({ type: RESET_CPT_INIT_SETUP });
};

// ============= GET ALL CPT CODES ACTIONS  ========================
export const getCptCodesFunc =
  (patientId, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: GET_CPT_CODES_REQUEST });

      const token = Cookies.get("token");

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/cpt-code/get?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: GET_CPT_CODES_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CPT_CODES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetCptCodes = () => async (dispatch) => {
  dispatch({ type: RESET_CPT_CODES });
};

// =============UPDATE  CPT CODE ACTIONS  ========================
export const updateCptCodeFunc = (cptCodeObj) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CPT_CODES_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/cpt-code/update`,
        cptCodeObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: UPDATE_CPT_CODES_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_CPT_CODES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetUpdateCptCodes = () => async (dispatch) => {
  dispatch({ type: RESET_UPDATE_CPT_CODE });
};

// ================== ADD CPT DIAGNOSTIC CODE ACTION  =========================
export const addCptDiagnosisCodeFunc =
  (diagnosisCodeObj) => async (dispatch) => {
    try {
      dispatch({ type: ADD_CPT_DIAGNOSIS_CODE_REQUEST });

      const token = Cookies.get("token");
      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.post(
          `${BASE_URL}/cpt-code/add-diagnostic-codes`,
          diagnosisCodeObj,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: ADD_CPT_DIAGNOSIS_CODE_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ADD_CPT_DIAGNOSIS_CODE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// =============REMOVE  CPT DIAGNOSIS CODE ACTIONS  ========================
export const removeCptDiagnosisCodeFunc = (cptCodeObj) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_CPT_DIAGNOSIS_CODE_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/cpt-code/remove-diagnostic-code`,
        cptCodeObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: REMOVE_CPT_DIAGNOSIS_CODE_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: REMOVE_CPT_DIAGNOSIS_CODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== EXPORT REPORT ACTION  =========================
export const exportReportFunc = (startDate, endDate) => async (dispatch) => {
  try {
    dispatch({ type: EXPORT_REPORT_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/patient/export-billing-report?agency_id=${agencyId}&start_date=${startDate}&end_date=${endDate}`,

        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: EXPORT_REPORT_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EXPORT_REPORT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetExportReport = () => async (dispatch) => {
  dispatch({ type: RESET_EXPORT_REPORT });
};
