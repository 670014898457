// import { configureStore } from "@reduxjs/toolkit";
// import logger from "redux-logger";
// import rootReducer from "../reducers/index";
import { composeWithDevTools } from "@redux-devtools/extension";
import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import {
  addPasswordReducer,
  otpVerifyReducer,
  resendClientCredentialsReducer,
  updateForgottenPasswordReducer,
  userForgotPasswordReducer,
  userLoginReducer,
  userRegisterReducer,
} from "../reducers/Auth";
import {
  addNewClientReducer,
  agentUpdateProfileReducer,
  checkPatientEmailReducer,
  createWorkspaceReducer,
  deleteClientReducer,
  getAgencyReducer,
  getAgentProfileReducer,
  getAgentsListReducer,
  getAllAgenciesReducer,
  getAllClientsReducer,
  getAllClientsUnpaginatedReducer,
  getClientDataReducer,
  inviteAgentReducer,
  updateAgencyReducer,
  updateAgentTeamMembersReducer,
  updateClientReducer,
} from "../reducers/Agency";
import Cookies from "js-cookie";
import {
  addNewCaseReducer,
  addNoteToCaseReducer,
  addQuestionToCaseReducer,
  deleteCaseReducer,
  getAllCasesReducer,
  getAllPatientsByAgencyReducer,
  getCaseByAgentReducer,
  getCaseByCaseIdReducer,
  getCaseClientDocumentsReducer,
  getClientPainlogsReducer,
  getDashboardAnalyticsReducer,
  getHighRiskCasesReducer,
  getPieChartStatsReducer,
  getUnpaginatedCasesReducer,
  getUnreadChatMessagesReducer,
  updateCaseReducer,
} from "../reducers/Cases";
import {
  getNotificationsListReducer,
  getReferralsListReducer,
  postNotificationReducer,
} from "../reducers/Referrals";
import {
  changedAgencyIdReducer,
  createCustomNotesReducer,
  disableAppAccessReducer,
  enableAppAccessReducer,
  getAlertNotificationsReducer,
  getAllNotificationsReducer,
  getBodyPartsReducer,
  getCaseSummaryLinkReducer,
  getChatNotificationsReducer,
  getExpensesDetailReducer,
  getUserActivityNotificationsReducer,
  getUserFeedNotificationsReducer,
  markAsReadNotificationsReducer,
  sendPainLogReminderReducer,
  timerReducer,
} from "../reducers/AppConfig";
import {
  cancelDocumentReducer,
  createEmptyFolderReducer,
  deleteFileReducer,
  deleteFolderReducer,
  getClientCasesDocsReducer,
  innerFolderViewReducer,
  moveFileToFolderReducer,
  renameFileReducer,
  renameFolderReducer,
  sendDocReminderReducer,
} from "../reducers/DocumentsReducers";
import {
  addIntakeAnswersReducer,
  createPresetReducer,
  deletePresetReducer,
  getClientIntakeListsReducer,
  getPresetDetailReducer,
  getQuestionsbyCaseTypeReducer,
  intakeQuestionsAnswersReducer,
  intakeReminderReducer,
  updatePresetReducer,
} from "../reducers/ClientIntakeReducers";
import {
  addCaseToSubscriptionReducer,
  cancelBillingSubscriptionReducer,
  createBillingSubscriptionReducer,
  getBillingSubscriptionReducer,
  removeCaseFromSubscriptionReducer,
  updateBillingSubscriptionReducer,
} from "../reducers/BillingSubscription";
import {
  getMedicinesReducer,
  getStepsCountReducer,
} from "../reducers/StepsCount";
import {
  createTimeEntryReducer,
  deleteTimeEntryReducer,
  getNotesReducer,
  getTimeEntryReducer,
  updateTimeEntryReducer,
} from "../reducers/TimeEntry";
import {
  addDiagnosisCodeReducer,
  getDiagnosisOptionsReducer,
  removeDiagnosisCodeReducer,
} from "../reducers/DiagnosisReducers";
import { createOrganizationReducer } from "../reducers/OrganizationReducers";
import {
  addCptDiagnosticCodesReducer,
  exportReportReducer,
  getCptCodesReducer,
  initCptSetupReducer,
  removeCptDiagnosisCodeReducer,
  updateCptCodeReducer,
} from "../reducers/CptCodesReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userForgotPassword: userForgotPasswordReducer,
  otpVerify: otpVerifyReducer,
  createWorkspace: createWorkspaceReducer,
  createOrganization: createOrganizationReducer,
  changedAgencyId: changedAgencyIdReducer,
  updateForgotPassword: updateForgottenPasswordReducer,
  addPassword: addPasswordReducer,
  agentProfile: getAgentProfileReducer,
  getAgencyDetail: getAgencyReducer,
  updateAgency: updateAgencyReducer,
  getAllAgencies: getAllAgenciesReducer,
  agentUpdateProfile: agentUpdateProfileReducer,
  getAgentsList: getAgentsListReducer,
  inviteAgent: inviteAgentReducer,
  updateAgentTeamMembers: updateAgentTeamMembersReducer,
  getAllClients: getAllClientsReducer,
  getAllClientsUnpaginated: getAllClientsUnpaginatedReducer,
  getClientData: getClientDataReducer,
  resendClientCredentials: resendClientCredentialsReducer,
  addNewClient: addNewClientReducer,
  updateClient: updateClientReducer,
  deleteClient: deleteClientReducer,
  getAllCases: getAllCasesReducer,
  getAllAgencyPatients: getAllPatientsByAgencyReducer,
  getAllUnpaginatedCases: getUnpaginatedCasesReducer,
  caseDetailByCaseId: getCaseByCaseIdReducer,
  getCaseByAgent: getCaseByAgentReducer,
  getCaseClientDocs: getCaseClientDocumentsReducer,
  getClientPainlogs: getClientPainlogsReducer,
  addNewCase: addNewCaseReducer,
  updateCase: updateCaseReducer,
  deletedCase: deleteCaseReducer,
  dashboardAnalytics: getDashboardAnalyticsReducer,
  atHighRiskCases: getHighRiskCasesReducer,
  getUnreadChatMessages: getUnreadChatMessagesReducer,
  getReferralsList: getReferralsListReducer,
  getNotificationsList: getNotificationsListReducer,
  postNotification: postNotificationReducer,
  disableAppAccess: disableAppAccessReducer,
  enableAppAccess: enableAppAccessReducer,
  getUserFeedNotifications: getUserFeedNotificationsReducer,
  getAllNotifications: getAllNotificationsReducer,
  getChatNotifications: getChatNotificationsReducer,
  getAlertNotifications: getAlertNotificationsReducer,
  getUserActivityNotifications: getUserActivityNotificationsReducer,
  markedAsReadNotifications: markAsReadNotificationsReducer,
  createdEmptyFolder: createEmptyFolderReducer,
  innerFolderView: innerFolderViewReducer,
  renameFolder: renameFolderReducer,
  renameFile: renameFileReducer,
  moveFileToFolder: moveFileToFolderReducer,
  deleteFile: deleteFileReducer,
  deleteFolder: deleteFolderReducer,
  getClientCasesDocs: getClientCasesDocsReducer,
  getClientIntakeLists: getClientIntakeListsReducer,
  getPresetDetail: getPresetDetailReducer,
  createPreset: createPresetReducer,
  deletePreset: deletePresetReducer,
  updatePreset: updatePresetReducer,
  intakeQuestionsAnswers: intakeQuestionsAnswersReducer,
  sendDocReminder: sendDocReminderReducer,
  cancelDocument: cancelDocumentReducer,
  intakeReminder: intakeReminderReducer,
  getAllBodyParts: getBodyPartsReducer,
  getCaseSummaryLink: getCaseSummaryLinkReducer,
  getPieChartStats: getPieChartStatsReducer,
  getBillingSubscription: getBillingSubscriptionReducer,
  createBillingSubscription: createBillingSubscriptionReducer,
  updateBillingSubscription: updateBillingSubscriptionReducer,
  addCaseToSubscription: addCaseToSubscriptionReducer,
  removeCaseFromSubscription: removeCaseFromSubscriptionReducer,
  cancelBillingSubscription: cancelBillingSubscriptionReducer,
  addQuestionToCase: addQuestionToCaseReducer,
  getQuestionsByCaseType: getQuestionsbyCaseTypeReducer,
  addIntakeAnswers: addIntakeAnswersReducer,
  sendPainLogReminder: sendPainLogReminderReducer,
  addNoteToCase: addNoteToCaseReducer,
  getExpensesDetail: getExpensesDetailReducer,
  getStepsCount: getStepsCountReducer,
  getMedicinesStats: getMedicinesReducer,
  timerModal: timerReducer,
  createTimeEntry: createTimeEntryReducer,
  getTimeEntry: getTimeEntryReducer,
  deleteTimeEntry: deleteTimeEntryReducer,
  updateTimeEntry: updateTimeEntryReducer,
  getDiagnosisOptions: getDiagnosisOptionsReducer,
  addDiagnosisCode: addDiagnosisCodeReducer,
  removeDiagnosisCode: removeDiagnosisCodeReducer,
  getNotes: getNotesReducer,
  initCptSetup: initCptSetupReducer,
  getCptCodes: getCptCodesReducer,
  updateCptCode: updateCptCodeReducer,
  removeCptDiagnosisCode: removeCptDiagnosisCodeReducer,
  addCptDiagnosticCodes: addCptDiagnosticCodesReducer,
  checkPatientEmail: checkPatientEmailReducer,
  exportReport: exportReportReducer,
  createCustomNotes: createCustomNotesReducer,
});

// const userInfoStorage = localStorage.getItem("userInfo")
//   ? JSON.parse(localStorage.getItem("userInfo"))
//   : null;

// const userToken = localStorage.getItem("agentToken")
//   ? JSON.parse(localStorage.getItem("agentToken"))
//   : null;

const token = Cookies.get("token") ?? null;
const agentId = Cookies.get("agent_id_vita") ?? null;
const agencyId = Cookies.get("agency_id_vita") ?? null;

// console.log("TOKEN", token);
// console.log(token)

const initialState = {
  userLogin: {
    userInfo: { token, agentId, agencyId },
    // userToken: userToken,
  },
};

const middlewares = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
