import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import ComingSoonImage from "../../assets/images/Illustration.png";
import HeaderText from "../../common/HeaderText";

const ComingSoonPage = () => {
  return (
    <Stack height="85vh" alignItems="center" justifyContent="center" gap="24px">
      <Box>
        <img src={ComingSoonImage} alt="coming soon" />
      </Box>
      <Stack gap="8px" alignItems="center">
        <Typography variant="h3" fontSize="44px">
          Coming Soon!
        </Typography>
        <Typography variant="body1" color="secondary" fontSize="20px">
          We are working on it{" "}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ComingSoonPage;
