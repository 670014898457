import React, { useEffect } from "react";
import { Avatar, Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import IntegrationLinkIcon from "../../icons/IntegrationLinkIcon";
import { integrationsItems } from "../../utils/helpers/integrationData";
import IntegrationCard from "../../components/IntegrationCard";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgentProfile,
  resetUpdateAgentProfile,
  updateAgentProfile,
} from "../../actions/Agency";
import ComingSoonPage from "../../common/ComingSoonPage";

const Integrations = () => {
  const agencyId = Cookies.get("agency_id_vita");
  const agentId = Cookies.get("agent_id_vita");

  const dispatch = useDispatch();

  const agentProfile = useSelector((state) => state.agentProfile);
  const {
    loading: loadingAgentProfile,
    error: errorAgentProfile,
    agentUser,
  } = agentProfile;

  // updatedAgentProfile
  const agentUpdateProfile = useSelector((state) => state.agentUpdateProfile);
  const {
    loading: loadingUpdateAgent,
    error: errorUpdateAgent,
    agentInfo,
  } = agentUpdateProfile;

  const clioIntegrated = agentUser?.agent?.clio_integrated;

  const disconnectIntegrationHandler = () => {
    const agentOptions = {
      agent_id: agentId,
      agent_profile: {
        clio_integrated: false,
      },
    };

    dispatch(updateAgentProfile(agentOptions));
  };

  useEffect(() => {
    if (agentInfo?.ok) {
      dispatch(getAgentProfile());
      dispatch(resetUpdateAgentProfile());
    }
  }, [agentInfo]);

  return (
    <ComingSoonPage />
    // <Stack gap="1.5rem">
    //   <Stack>
    //     <Typography
    //       variant="h5"
    //       fontSize="1.875rem"
    //       fontWeight={500}
    //       pb="1.5rem"
    //     >
    //       Integrations
    //     </Typography>
    //     {/* <Typography variant="body1">
    //       Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
    //       dolore eu fugiat nulla pariatu{" "}
    //     </Typography> */}
    //   </Stack>
    //   {loadingAgentProfile ? (
    //     <Skeleton variant="rounded" width={350} height={157} />
    //   ) : (
    //     <Grid container spacing={3}>
    //       {integrationsItems.map((item, index) => (
    //         <IntegrationCard
    //           key={index}
    //           title={item.title}
    //           integrationImage={item.logo}
    //           websiteLink={item.website}
    //           subheading={item.subheading}
    //           description={item.description}
    //           comingSoon={item.coming_soon}
    //           integrationAuthLink={item.authLink}
    //           integrated={clioIntegrated}
    //           disconnectIntegrationHandler={disconnectIntegrationHandler}
    //           loadingUpdateAgent={loadingUpdateAgent}
    //         />
    //       ))}
    //     </Grid>
    //   )}
    // </Stack>
  );
};

export default Integrations;
