import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  IconButton,
  Box,
  Autocomplete,
  Chip,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Textfield from "../../common/Textfield";
import Button from "../../common/Button";
import CommonDrawer from "../../common/CommonDrawer";
import AlertMessage from "../../common/AlertMessage";
import { LoadingButton } from "@mui/lab";
import Cookies from "js-cookie";
import {
  clearContact,
  formatPhoneNumber,
  validateEmail,
} from "../../utils/helpers";
import { statesWithCities } from "../../utils/helpers/states_with_cities";
import { getClientDataFunc, getAgencyDetailFunc } from "../../actions/Agency";
import { segmentTrack } from "../../utils/helpers/tracking_utils";

const states = Object.keys(statesWithCities);

export default function InviteDrawer({
  anchor,
  open,
  setOpen,
  onClose,
  update,
  setUpdate,
  client,
  getClientsList,
  fromClientOverview,
  getClientData,
}) {
  // var userInfo = localStorage.getItem("userInfo");

  // console.log("CLIENT", client);

  // console.log("CLIENT IN EDIT", client);

  const [token, setToken] = useState("");
  const [agentID, setAgentID] = useState(0);
  const [agencyID, setAgencyID] = useState(0);

  useEffect(() => {
    // if (userInfo) {
    //   userInfo = JSON.parse(userInfo);
    //   setToken(userInfo.token);
    //   setAgencyID(userInfo.agency_id);
    //   setAgentID(userInfo.agent_id);
    // }
    const agentToken = Cookies.get("token");
    const cookieAgentId = Cookies.get("agent_id_vita");
    const cookieAgencyId = Cookies.get("agency_id_vita");

    if (agentToken) {
      setToken(agentToken);
      setAgentID(cookieAgentId);
      setAgencyID(cookieAgencyId);
    }
  }, [token]);

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState(null);
  const [contactNumber, setContactNumber] = useState("");
  const [sendCredentials, setSendCredentials] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [hobbies, setHobbies] = useState([]);

  const [teamMembersLoading, setTeamMembersLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [existingAssignedTeamMembers, setExistingAssignedTeamMembers] =
    useState([]);
  const [updatedAssignedMembers, setUpdatedAssignedMembers] = useState([]);

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState(null); // state/region
  const [country, setCountry] = useState("United States of America");
  const [zipCode, setZipCode] = useState("");

  // console.log("CITIES", statesWithCities[region]);

  const [jobTitle, setJobTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [annualIncome, setAnnualIncome] = useState("");

  const [additionalInfo, setAdditionalInfo] = useState("");

  const dispatch = useDispatch();

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const [status, setStatus] = useState({ type: "", message: "" });
  const [alertOpen, setAlertOpen] = useState(true);

  const handleAlertClick = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  // const getTeamMembers = async () => {
  //   try {
  //     setTeamMembersLoading(true);
  //     const config = {
  //       headers: {
  //         "Content-type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };
  //     const { data } = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/agent/get-list?agency_id=${agencyID}`,

  //       config
  //     );

  //     setTeamMembers(data.agents_list);
  //     setTeamMembersLoading(false);
  //   } catch (error) {
  //     setTeamMembersLoading(false);
  //     console.log(error);
  //   }
  // };

  // console.log("TMMM", teamMembers);

  const emailChangeHandler = (e) => {
    const emailInputValue = e.target.value;
    setEmail(emailInputValue);

    const emailIsValid = validateEmail(emailInputValue);

    if (!emailIsValid) {
      setEmailError(true);
      return;
    }

    setEmailError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setStatus({
      type: "",
      message: "",
    });

    setLoading(true);

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const client_obj = {
        first_name: firstName,
        last_name: lastName,
        email,
        contact_number: contactNumber,
        address: address,
        city,
        country,
        region: region,
        zipcode: zipCode,
        dob: dob ? dayjs(dob).format("YYYY-MM-DD") : null,
        // hobbies: hobbies && hobbies.length ? JSON.stringify(hobbies) : null,
        // assigned_to: update
        //   ? JSON.stringify(updatedAssignedMembers.map((et) => et.id))
        //   : assignedTo && assignedTo.length
        //   ? JSON.stringify(assignedTo)
        //   : null,
        assigned_to: JSON.stringify([agentID]),
        job_title: jobTitle,
        company_name: companyName,
        annual_income: annualIncome,
        additional_info: additionalInfo,
      };

      var url = `${process.env.REACT_APP_BASE_URL}/agent/invite-client`;

      // console.log("CLIENTOBJ", client_obj);

      var clientData = {
        agency_id: agencyID,
        send_credentials: sendCredentials,
        client_obj: client_obj,
      };

      if (update) {
        url = `${process.env.REACT_APP_BASE_URL}/agent/update-client`;

        clientData = {
          client_id: client?.id,
          client_profile: client_obj,
        };
      }

      const res = await axios.post(url, clientData, config);

      setStatus({
        type: "success",
        message: res?.data?.message
          ? res?.data?.message
          : "Changes saved successfully ",
      });

      setFirstName("");
      setLastName("");
      setEmail("");
      setContactNumber("");
      setDob(null);
      setSendCredentials(false);
      setHobbies([]);
      setAssignedTo([]);
      setTeamMembers([]);
      setExistingAssignedTeamMembers([]);
      setAddress("");
      setCountry("United States of America");
      setCity("");
      setRegion(null);
      setZipCode("");
      setJobTitle("");
      setCompanyName("");
      setAnnualIncome("");
      setAdditionalInfo("");

      setLoading(false);
      setOpen(false);
      setUpdate(false);
      handleAlertClick();
      if (fromClientOverview) {
        dispatch(getClientDataFunc(client?.id));
      } else {
        getClientsList("");
      }

      if (update) {
        if (agentUser?.agent && agencyDetail?.agency) {
          segmentTrack({
            event: "client_updated",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
          });
        }
      } else {
        if (agentUser?.agent && agencyDetail?.agency) {
          segmentTrack({
            event: "new_client_created",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
          });
        }
      }
    } catch (error) {
      setStatus({
        type: "error",
        message:
          error.data && error.data.message ? error.data.message : error.message,
      });
      setOpen(false);
      setLoading(false);
      console.log(error);
    }
  };

  const handleZipCodeChange = (e) => {
    // Only update if the input is a number or empty (to allow deletion)
    if (/^\d*$/g.test(e.target.value)) {
      setZipCode(e.target.value);
    }
  };

  const handleAnnualIncomeChange = (e) => {
    const inputNumber = e.target.value;
    // Remove non-numeric characters from the input
    const onlyNumbers = inputNumber.replace(/\D/g, "");
    // Format the number with commas after every 3 digits
    const formattedNumber = onlyNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setAnnualIncome(formattedNumber);
  };

  const clearAttributes = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setContactNumber("");
    setDob(null);
    setSendCredentials(false);
    setHobbies([]);
    setAssignedTo([]);
    // setTeamMembers([]);
    setExistingAssignedTeamMembers([]);
    setAddress("");
    setCountry("United States of America");
    setCity("");
    setRegion(null);
    setZipCode("");
    setJobTitle("");
    setCompanyName("");
    setAnnualIncome("");
    setAdditionalInfo("");
  };

  const contactNumberHandler = (e) => {
    const input = e.target.value;
    const num = clearContact(input);
    const formattedNumber = formatPhoneNumber(num);
    setContactNumber(formattedNumber);
  };

  useEffect(() => {
    // if (open && !teamMembers.length) {
    //   try {
    //     getTeamMembers();
    //   } catch (error) {
    //     setTeamMembersLoading(false);
    //     console.log(error);
    //   }
    // }

    if (update) {
      setFirstName(client.first_name || "");
      setLastName(client.last_name || "");
      setEmail(client.email || "");
      setContactNumber(client.contact_number || "");
      setDob(client.dob ? dayjs(client.dob) : null);
      setSendCredentials(false);
      // setHobbies(client.hobbies ? JSON.parse(client.hobbies) : []);
      // setAssignedTo(
      //   client.assigned_to
      //     ? JSON.parse(client.assigned_to).map((agent) => agent.id)
      //     : []
      // );
      setAddress(client.address || "");
      setCountry("United States of America");
      setCity(client.city || "");
      setRegion(client.region || null);
      setZipCode(client.zipcode || "");
      setJobTitle(client.job_title || "");
      setCompanyName(client.company_name || "");
      setAnnualIncome(client.annual_income || "");
      setAdditionalInfo(client.additional_info || "");
    } else {
      clearAttributes();
    }

    // if (update && teamMembers) {
    //   setExistingAssignedTeamMembers(
    //     teamMembers.filter((tm) => client?.assigned_to?.includes(tm.id))
    //   );
    //   setUpdatedAssignedMembers(
    //     teamMembers.filter((tm) => client?.assigned_to?.includes(tm.id))
    //   );
    // }
  }, [open]);

  return (
    <>
      {!loading && (status?.type === "success" || status?.type === "error") && (
        <AlertMessage
          message={status?.message}
          severity={status?.type}
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}

      <CommonDrawer
        anchor={anchor}
        open={open}
        onClose={() => {
          clearAttributes();
          setUpdate(false);
          setOpen(false);
        }}
      >
        <Stack gap="24px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">
              {update ? "Update" : "Add New"} Client
            </Typography>

            <IconButton
              size="small"
              onClick={() => {
                clearAttributes();
                setUpdate(false);
                setOpen(false);
              }}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </Box>
          <Typography
            variant="boxHeading"
            color="secondary"
            textTransform="uppercase"
          >
            Personal Information
          </Typography>
          <Stack direction="row" justifyContent="space-between" gap="24px">
            <TextField
              id="add-client-first-name"
              placeholder="Enter First Name"
              // error={isTouched && !!error}
              // helperText={error?.message}
              margin="dense"
              label="First Name"
              variant="outlined"
              name="firstName"
              fullWidth
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />

            <TextField
              placeholder="Enter Last Name"
              margin="dense"
              label="Last Name"
              variant="outlined"
              name="lastName"
              // error={isTouched && !!error}
              // helperText={error?.message}
              value={lastName}
              fullWidth
              onChange={(e) => setLastName(e.target.value)}
            />
          </Stack>
          <Stack>
            <TextField
              id="add-client-email"
              placeholder="Enter Email"
              margin="dense"
              label="Email Address"
              variant="outlined"
              name="email"
              error={emailError}
              // helperText={error?.message}
              value={email}
              required
              onChange={emailChangeHandler}
              InputProps={{
                readOnly: update,
              }}
            />
          </Stack>

          <Stack direction="row" justifyContent="space-between" gap="24px">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of Birth"
                value={dob}
                disableFuture
                onChange={(newValue) => setDob(newValue)}
                slotProps={{ textField: { fullWidth: true } }}
                sx={{ marginTop: 1 }}
              />
            </LocalizationProvider>

            <TextField
              placeholder="Enter Contact Number"
              margin="dense"
              label="Contact Number"
              variant="outlined"
              name="contactNumber"
              // error={isTouched && !!error}
              // helperText={error?.message}
              fullWidth
              value={contactNumber}
              onChange={contactNumberHandler}
            />
          </Stack>
          {update ? (
            <></>
          ) : (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendCredentials}
                    onChange={(e) => setSendCredentials(e.target.checked)}
                  />
                }
                label="Share mobile app login credentials via email."
              />
            </FormGroup>
          )}

          {/* <Typography
            component="label"
            htmlFor="tags"
            variant="boxHeading"
            color="disabled.boxHeading"
            textTransform="uppercase"
          >
            Lifestyle / Hobbies
          </Typography>

          <Autocomplete
            multiple
            id="tags"
            options={[]}
            freeSolo
            // defaultValue={update ? hobbies : []}
            value={hobbies}
            onChange={(e, value) => {
              setHobbies(value && value);
            }}
            renderTags={(value, props) =>
              value.map((option, index) => (
                <Chip
                  label={option || null}
                  {...props({ index })}
                  style={{ color: "#2196F3", background: "#35A0F426" }}
                  sx={{
                    "& .MuiChip-deleteIcon": {
                      color: "#2196F3",
                    },
                  }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Add Tags" />
            )}
          /> */}

          {/* <Typography
            component="label"
            htmlFor="assignTo"
            variant="boxHeading"
            color="disabled.boxHeading"
            textTransform="uppercase"
          >
            Assign to
          </Typography>

          <Autocomplete
            multiple
            id="assignTo"
            options={teamMembers}
            noOptionsText={!teamMembers.length > 0 && "No Options found"}
            filterSelectedOptions
            defaultValue={update ? existingAssignedTeamMembers : []}
            key={existingAssignedTeamMembers}
            // value={update ? updatedAssignedMembers : []}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={async (e, value) => {
              if (value === null || value === undefined) {
                // Handle the case when no option is selected
                setUpdatedAssignedMembers([]); // Clear the selected options
                setAssignedTo([]); // Clear the assignedTo array or perform the necessary action
              } else {
                setUpdatedAssignedMembers(value);
                setAssignedTo(value.map((v) => v.id));
              }
            }}
            // value={update ? updatedAssignedMembers : assignedTo}
            getOptionLabel={(option) => option.name || ""}
            renderTags={(value, props) =>
              value.map((option, index) => (
                <Chip
                  label={option.name || null}
                  // noOptionsText="No optionssss"
                  {...props({ index })}
                  style={{ color: "#2196F3", background: "#35A0F426" }}
                  sx={{
                    "& .MuiChip-deleteIcon": {
                      color: "#2196F3",
                    },
                  }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assign to team members"
                required
              />
            )}
          /> */}

          <Typography
            variant="boxHeading"
            color="disabled.boxHeading"
            textTransform="uppercase"
          >
            Address
          </Typography>

          <Stack>
            <TextField
              placeholder="90210 Broadway Blvd"
              margin="dense"
              label="Address"
              variant="outlined"
              name="address"
              // error={isTouched && !!error}
              // helperText={error?.message}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between" gap="24px">
            {/* <TextField
              placeholder="United States of America"
              margin="dense"
              label="Country"
              variant="outlined"
              name="country"
              value="United States of America"
              fullWidth
              InputProps={{
                readOnly: true,
                style: {
                  backgroundColor: "transparent",
                  border: "none",
                  color: "black",
                  fontSize: "1em",
                },
              }}
              // onChange={(e) => setCountry(e.target.value)}
            /> */}

            {/* <FormControl variant="outlined" margin="dense" fullWidth>
              <InputLabel htmlFor="state">State/Region</InputLabel>
              <Select
                label="State/Region"
                name="state"
                value={region}
                onChange={(e) => setRegion(e.target.value)}
                input={<OutlinedInput label="State/Region" id="state" />}
              >
                {Object.keys(statesWithCities).map((stateName) => (
                  <MenuItem key={stateName} value={stateName}>
                    {stateName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <Textfield
              placeholder="San Mateo"
              label="City"
              variant="outlined"
              name="city"
              fullWidth
              width="100%"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              // margin="dense"
              // error={isTouched && !!error}
              // helperText={error?.message}
            />

            <Autocomplete
              options={states}
              fullWidth
              value={region}
              onChange={(event, newValue) => setRegion(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                />
              )}
              sx={{ marginTop: -1 }}
            />
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            gap="24px"
            width="48%"
          >
            {/* <FormControl variant="outlined" margin="dense" fullWidth>
              <InputLabel htmlFor="city">City</InputLabel>
              <Select
                label="City"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                input={<OutlinedInput label="City" id="city" />}
              >
                {statesWithCities[region]?.map((cityName) => (
                  <MenuItem key={cityName} value={cityName}>
                    {cityName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <TextField
              placeholder="14000"
              margin="dense"
              label="Zip Code"
              variant="outlined"
              name="zipCode"
              // error={isTouched && !!error}
              // helperText={error?.message}
              value={zipCode}
              fullWidth
              onChange={handleZipCodeChange}
            />
          </Stack>
          <Typography
            variant="boxHeading"
            color="disabled.boxHeading"
            textTransform="uppercase"
          >
            WORK DETAILS
          </Typography>
          <Stack direction="row" justifyContent="space-between" gap="24px">
            <TextField
              // error={isTouched && !!error}
              // helperText={error?.message}
              margin="dense"
              label="Job Title"
              variant="outlined"
              name="jobTitle"
              value={jobTitle}
              fullWidth
              onChange={(e) => setJobTitle(e.target.value)}
            />

            <TextField
              margin="dense"
              label="Company Name"
              variant="outlined"
              name="companyName"
              // error={isTouched && !!error}
              // helperText={error?.message}
              value={companyName}
              fullWidth
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Stack>
          <Stack pr="24px">
            <TextField
              margin="dense"
              label="Annual Income"
              variant="outlined"
              name="income"
              width="50%"
              // error={isTouched && !!error}
              // helperText={error?.message}
              value={annualIncome}
              onChange={handleAnnualIncomeChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Stack>
          <Typography
            variant="boxHeading"
            color="disabled.boxHeading"
            textTransform="uppercase"
          >
            ADDITIONAL INFORMATION
          </Typography>
          <Stack>
            <TextField
              margin="dense"
              label="Notes/comments"
              variant="outlined"
              name="about"
              multiline
              maxRows={4}
              // error={isTouched && !!error}
              // helperText={error?.message}
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
            />
          </Stack>
          <Stack spacing={2} direction="row" justifyContent="flex-end" pt={1.4}>
            <Button
              title="Cancel"
              color="cancelButton"
              variant="contained"
              onClick={() => {
                clearAttributes();
                setOpen(false);
                setUpdate(false);
              }}
            />
            <LoadingButton
              id="add-client-submit"
              color="primary"
              variant="contained"
              disabled={
                !email || emailError || !firstName
                // (!assignedTo.length && !updatedAssignedMembers.length)
              }
              onClick={handleSubmit}
              loading={loading}
            >
              {update ? "Update" : "Save"} Client
            </LoadingButton>
          </Stack>
        </Stack>
      </CommonDrawer>
    </>
  );
}
