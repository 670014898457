// /time-entry/create

export const CREATE_TIME_ENTRY_REQUEST = "CREATE_TIME_ENTRY_REQUEST";
export const CREATE_TIME_ENTRY_SUCCESS = "CREATE_TIME_ENTRY_SUCCESS";
export const CREATE_TIME_ENTRY_FAIL = "CREATE_TIME_ENTRY_FAIL";
export const RESET_CREATE_TIME_ENTRY = "RESET_CREATE_TIME_ENTRY";

export const GET_TIME_ENTRY_REQUEST = "GET_TIME_ENTRY_REQUEST";
export const GET_TIME_ENTRY_SUCCESS = "GET_TIME_ENTRY_SUCCESS";
export const GET_TIME_ENTRY_FAIL = "GET_TIME_ENTRY_FAIL";
export const RESET_GET_TIME_ENTRY = "RESET_GET_TIME_ENTRY";

export const UPDATE_TIME_ENTRY_REQUEST = "UPDATE_TIME_ENTRY_REQUEST";
export const UPDATE_TIME_ENTRY_SUCCESS = "UPDATE_TIME_ENTRY_SUCCESS";
export const UPDATE_TIME_ENTRY_FAIL = "UPDATE_TIME_ENTRY_FAIL";
export const RESET_UPDATE_TIME_ENTRY = "RESET_UPDATE_TIME_ENTRY";

export const DELETE_TIME_ENTRY_REQUEST = "DELETE_TIME_ENTRY_REQUEST";
export const DELETE_TIME_ENTRY_SUCCESS = "DELETE_TIME_ENTRY_SUCCESS";
export const DELETE_TIME_ENTRY_FAIL = "DELETE_TIME_ENTRY_FAIL";
export const RESET_DELETE_TIME_ENTRY = "RESET_DELETE_TIME_ENTRY";

export const GET_NOTES_REQUEST = "GET_NOTES_REQUEST";
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";
export const GET_NOTES_FAIL = "GET_NOTES_FAIL";
export const RESET_GET_NOTES = "RESET_GET_NOTES";
