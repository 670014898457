import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSelectFilter from "../../../common/CustomSelectFilter";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TimeBillCard from "../../../components/TimeBillCard";
import CptCard from "../../../components/CptCard";
import CptDrawer from "../../../components/CptDrawer";
import CptProgressCard from "../../../components/CptProgressCard";
import DateTimeCard from "../../../components/DateTimeCard";
import { TimerIcon } from "../../../assets/constants";
import WaitingClockIcon from "../../../icons/WaitingClockIcon";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import HoverableChip from "../../../components/HoverableChip";
import AlertDialog from "../../../components/AlertDialog";
import { useDispatch } from "react-redux";
import {
  createTimeEntryFunc,
  deleteTimeEntryFunc,
  getTimeEntryFunc,
  resetCreateTimeEntry,
  resetDeleteTimeEntry,
  resetGetTimeEntry,
  resetUpdateTimeEntry,
  updateTimeEntryFunc,
} from "../../../actions/TimeEntry";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import TimeEntriesComponent from "../../../components/TimeEntriesComponent";
import AlertMessage from "../../../common/AlertMessage";
import { getAgentsListFunc } from "../../../actions/Agency";
import {
  formatElapsedTime,
  getCptDateRanges,
  getReportDateRanges,
  groupedEntriesFunc,
  monthFilterOptions,
} from "../../../utils/helpers";
import LoadingAutoComplete from "../../../components/LoadingAutoComplete";
import {
  addDiagnosisCodeFunc,
  getDiagnosisOptionsFunc,
  removeDiagnosisCodeFunc,
  resetAddDiagnosisCode,
  resetRemoveDiagnosisCode,
} from "../../../actions/DiagnosisActions";
import Cookies from "js-cookie";
import { getCaseDetailByCaseId } from "../../../actions/Cases";
import EmptyBox from "../../../assets/images/empty-box.png";
import RectangleSkeletons from "../../../components/RectangleSkeletons";
import {
  getCptCodesFunc,
  resetCptCodes,
} from "../../../actions/CptCodesActions";

const cptCodePrices = {
  98975: 19.65,
  98977: 46.83,
  98980: 49.78,
  98981: 39.3,
};

const BillingTab = ({
  caseDetail,
  openDrawer,
  setOpenDrawer,
  showSuccessMessage,
  setShowSuccessMessage,
  setSuccessMessageContent,
  successMessageContent,
  timeDetails,
  setTimeDetails,
}) => {
  const [selectValue, setSelectValue] = useState("this_month");
  const [cptCodeDetail, setCptCodeDetail] = useState({});
  const [drawerType, setDrawerType] = useState("");
  const [singleChip, setSingleChip] = useState(null);
  const [openDiagnosisAddModal, setOpenDiagnosisAddModal] = useState(false);
  const [medicalCode, setMedicalCode] = useState([]);
  const [groupedEntries, setGroupedEntries] = useState({});
  const [timeEntryId, setTimeEntryId] = useState(null);
  const [openDeleteTimeEntryModal, setOpenDeleteTimeEntryModal] =
    useState(false);
  const [openCptDrawer, setOpenCptDrawer] = useState(false);
  const [allCptCodesArr, setAllCptCodesArr] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const agencyId = Cookies.get("agency_id_vita");

  const patientId = caseDetail?.patient?.id;

  // const [diagnosticOptions, setDiagnosticOptions] = useState([]);

  // const getDiagnosisOptions = useSelector((state) => state.getDiagnosisOptions);
  // const {
  //   loading: loadingDiagnosticCode,
  //   error: errorDiagnosticCode,
  //   diagnosisOptions,
  // } = getDiagnosisOptions;

  const getTimeEntry = useSelector((state) => state.getTimeEntry);
  const { loading: loadingGetTimeEntry, error, timeEntry } = getTimeEntry;

  const getCptCodes = useSelector((state) => state.getCptCodes);
  const {
    loading: loadingCptCodes,
    error: errorCptCodes,
    allCptCodes,
  } = getCptCodes;

  const caseDetailByCaseId = useSelector((state) => state.caseDetailByCaseId);
  const { loading: loadingCaseDetail } = caseDetailByCaseId;

  const removeDiagnosisCode = useSelector((state) => state.removeDiagnosisCode);
  const {
    loading: loadingRemoveDiagnosisCode,
    error: errorRemovedDiagnosisCode,
    removedDiagnosisCode,
  } = removeDiagnosisCode;

  const addCptDiagnosticCodes = useSelector(
    (state) => state.addCptDiagnosticCodes
  );
  const { addedCptDiagnosticCodes } = addCptDiagnosticCodes;

  const addDiagnosisCode = useSelector((state) => state.addDiagnosisCode);
  const {
    loading: loadingDiagnosisCode,
    error: errorDiagnosisCode,
    diagnosisCodeAdded,
  } = addDiagnosisCode;

  const getAgentsList = useSelector((state) => state.getAgentsList);
  const { allAgents } = getAgentsList;

  const updateTimeEntry = useSelector((state) => state.updateTimeEntry);
  const { updatedTimeEntry } = updateTimeEntry;

  const createTimeEntry = useSelector((state) => state.createTimeEntry);
  const { createdTimeEntry } = createTimeEntry;

  const updateCptCode = useSelector((state) => state.updateCptCode);
  const { updatedCptCode } = updateCptCode;

  const deleteTimeEntry = useSelector((state) => state.deleteTimeEntry);
  const {
    loading: loadingdeleteTimeEntry,
    error: errorDeleteTimeEntry,
    deletedTimeEntry,
  } = deleteTimeEntry;

  const removeCptDiagnosisCode = useSelector(
    (state) => state.removeCptDiagnosisCode
  );
  const { removedCptDiagnosisCode } = removeCptDiagnosisCode;

  const dispatch = useDispatch();

  const [chips, setChips] = useState([]);
  const [changedCptFilter, setChangedFilter] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [modalContentData, setModalContentData] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log("SELECT VALUE", selectValue);

  const getTimeEntryDetails = () => {
    const { startDate, endDate } = getReportDateRanges(selectValue);

    dispatch(getTimeEntryFunc(patientId, startDate, endDate));
  };

  useEffect(() => {
    if (patientId) {
      getTimeEntryDetails();
      setChips(caseDetail?.patient?.diagnostic_codes);
    }

    dispatch(getAgentsListFunc());
  }, [caseDetail, selectValue]);

  useEffect(() => {
    if (timeEntry?.ok) {
      const entries = groupedEntriesFunc(timeEntry?.time_entries);

      setGroupedEntries(entries);
    }
  }, [timeEntry]);

  const totalTimeInSeconds = Object.values(groupedEntries)?.reduce(
    (sum, { totalTime }) => sum + totalTime,
    0
  );

  useEffect(() => {
    if (patientId) {
      const { startDate, endDate } = getReportDateRanges(selectValue);

      dispatch(getCptCodesFunc(patientId, startDate, endDate));
    }
  }, [
    patientId,
    selectValue,
    createdTimeEntry,
    updatedCptCode,
    addedCptDiagnosticCodes,
    removedCptDiagnosisCode,
  ]);

  useEffect(() => {
    if (allCptCodes?.ok) {
      setAllCptCodesArr(allCptCodes?.cpt_codes);
    }
  }, [allCptCodes]);

  const changeMonthFilterHandler = (e) => {
    setChangedFilter(true);
    setSelectValue(e.target.value);
  };

  const openDrawerHandler = (cptItemDetails) => {
    setCptCodeDetail(cptItemDetails);
    setOpenCptDrawer(true);
    setDrawerType("cpt_drawer");
  };

  const openTimeDrawerHandler = (timeDetailsObj) => {
    setTimeDetails(timeDetailsObj);
    setOpenDrawer(true);
  };

  const openDeleteModalHandler = (chip) => {
    setSingleChip(chip);
    setOpenDeleteModal(true);
    setModalContentData({
      title: "Are you sure?",
      subtitle: `The diagnosis code (${chip?.code} - ${chip?.description}) will be removed.`,
    });
  };

  const deleteDiagnosisHandler = () => {
    const diagnosisCodeId = {
      diagnostic_code_id: singleChip?.id,
    };

    dispatch(removeDiagnosisCodeFunc(diagnosisCodeId));
  };

  useEffect(() => {
    if (removedDiagnosisCode?.ok) {
      setOpenDeleteModal(false);
      dispatch(resetRemoveDiagnosisCode());
      dispatch(getCaseDetailByCaseId(patientId));
    }
  }, [removedDiagnosisCode]);

  const openDiagnosisAddModalHandler = () => {
    setOpenDiagnosisAddModal(true);
    // if (caseDetail?.patient?.diagnostic_codes?.length) {
    //   setMedicalCode(
    //     caseDetail?.patient?.diagnostic_codes?.map((item) => ({
    //       code: item?.code,
    //       description: item?.description,
    //     }))
    //   );
    // }
  };

  const addDiagnosisCodesHandler = () => {
    const diagnosisCodeObj = {
      agency_id: agencyId,
      patient_id: patientId,
      diagnostic_codes: medicalCode,
    };

    dispatch(addDiagnosisCodeFunc(diagnosisCodeObj));
  };

  useEffect(() => {
    if (diagnosisCodeAdded?.ok) {
      dispatch(resetAddDiagnosisCode());
      setOpenDiagnosisAddModal(false);
      setMedicalCode([]);
      dispatch(getCaseDetailByCaseId(patientId));
    }
  }, [diagnosisCodeAdded]);

  const deleteTimeEntryHandler = (timeEntryId) => {
    setTimeEntryId(timeEntryId);
    setOpenDeleteTimeEntryModal(true);
    setModalContentData({
      title: "Are you sure?",
      subtitle: `This will delete the time entry.`,
    });
  };

  const deleteTimeLogHandler = () => {
    const deleteTimeEntryObj = {
      time_entry_id: timeEntryId,
    };
    dispatch(deleteTimeEntryFunc(deleteTimeEntryObj));
  };

  useEffect(() => {
    if (deletedTimeEntry?.ok) {
      dispatch(resetDeleteTimeEntry());
      setOpenDeleteTimeEntryModal(false);
      getTimeEntryDetails();
    }
  }, [deletedTimeEntry]);

  // const onSubmitEditTimeHandler = (timeEntryObj) => {
  //   dispatch(updateTimeEntryFunc(timeEntryObj));
  // };

  useEffect(() => {
    if (updatedTimeEntry?.ok) {
      dispatch(resetUpdateTimeEntry());
      setOpenDrawer(false);
      getTimeEntryDetails();
      setSuccessMessageContent("Time log edited successfully");
      setShowSuccessMessage(true);
    }
  }, [updatedTimeEntry]);

  // const onSubmitAddTimeEntryHandler = (timeEntryObj) => {
  //   dispatch(createTimeEntryFunc(timeEntryObj));
  // };

  useEffect(() => {
    if (createdTimeEntry?.ok) {
      dispatch(resetCreateTimeEntry());
      getTimeEntryDetails();
      setOpenDrawer(false);
      setSuccessMessageContent("Time log added successfully");
      setShowSuccessMessage(true);
    } else if (createdTimeEntry !== undefined && !createdTimeEntry?.ok) {
      setShowErrorMessage(true);
      setErrorMessage(createdTimeEntry?.msg);
    }
  }, [createdTimeEntry]);

  const generatedCodes = allCptCodesArr?.filter(
    (item) => item?.approved === true
  );

  const totalAmount = generatedCodes?.reduce((total, code) => {
    const price = cptCodePrices[code?.cpt_code] || 0; // Default to 0 if code not found
    return total + price;
  }, 0);

  const inProgressCodes = allCptCodesArr?.filter(
    (item) => item?.approved === false
  );

  return (
    <>
      <AlertDialog
        submitID="confirm-delete-diagnosis"
        openModal={openDeleteModal}
        isLoading={loadingRemoveDiagnosisCode}
        closeModal={() => setOpenDeleteModal(false)}
        data={modalContentData}
        formSubmitFunction={deleteDiagnosisHandler}
        color="error"
      />

      {errorMessage && (
        <AlertMessage
          message={errorMessage}
          severity="error"
          open={showErrorMessage}
          handleClose={handleClose}
        />
      )}

      <AlertDialog
        submitID="confirm-delete-timeEntry"
        openModal={openDeleteTimeEntryModal}
        closeModal={() => setOpenDeleteTimeEntryModal(false)}
        data={modalContentData}
        formSubmitFunction={deleteTimeLogHandler}
        color="error"
        isLoading={loadingdeleteTimeEntry}
      />

      {successMessageContent && (
        <AlertMessage
          message={successMessageContent}
          severity="success"
          open={showSuccessMessage}
          handleClose={() => setShowSuccessMessage(false)}
        />
      )}

      <AlertDialog
        submitID="add-diagnosis-modal"
        isLoading={loadingDiagnosisCode}
        disabled={!medicalCode?.length}
        openModal={openDiagnosisAddModal}
        closeModal={() => {
          setOpenDiagnosisAddModal(false);
          setMedicalCode([]);
        }}
        data={{ title: "Add Diagnosis Code" }}
        submitText="Add"
        formSubmitFunction={addDiagnosisCodesHandler}
      >
        <LoadingAutoComplete
          medicalCode={medicalCode}
          setMedicalCode={setMedicalCode}
        />
      </AlertDialog>

      <CptDrawer
        openDrawer={openCptDrawer}
        onClose={() => setOpenCptDrawer(false)}
        anchor={"right"}
        setOpenDrawer={setOpenCptDrawer}
        cptCodeDetail={cptCodeDetail}
        patientDetail={caseDetail?.patient}
        drawerType={drawerType}
        selectValue={selectValue}
        allCptCodesArr={allCptCodesArr}
      />
      <Stack width="100%" gap={2} paddingTop={1}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
        >
          <CustomSelectFilter
            disabled={
              loadingCptCodes || loadingGetTimeEntry || loadingCaseDetail
            }
            menuItems={monthFilterOptions}
            changeSelectValueHandler={changeMonthFilterHandler}
            selectValue={selectValue}
            id="month"
            minWidth={177}
            size="small"
          />
          {/* <Box display="flex" alignItems="center" gap="12px">
            <Button variant="contained" size="small">
              Export Report
            </Button>
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            </div>
          </Box> */}
        </Box>

        {/* TOTAL BILL AND TIME CARD */}
        <Grid container spacing="18px">
          <Grid item xs={4}>
            <TimeBillCard
              cardNum={totalAmount?.toFixed(2)}
              cardHeading="Total Bill"
              dollarSign={true}
              loadingTimeEntry={loadingCptCodes}
            />
          </Grid>
          <Grid item xs={4}>
            <TimeBillCard
              cardNum={formatElapsedTime(totalTimeInSeconds * 1000)}
              cardHeading="Total Time"
              loadingTimeEntry={loadingGetTimeEntry}
            />
          </Grid>

          <Grid item xs={4}>
            <TimeBillCard
              diagnosisCode={true}
              cardHeading="Diagnosis Code"
              openDiagnosisAddModalHandler={openDiagnosisAddModalHandler}
              loadingTimeEntry={loadingCaseDetail}
            >
              <Box display="flex" gap="8px" flexWrap="wrap" overflow="auto">
                {chips?.map((chip) => (
                  <HoverableChip
                    key={chip?.id}
                    label={`${chip?.code} - ${chip?.description}`}
                    onDelete={() => openDeleteModalHandler(chip)}
                  />
                ))}
              </Box>
            </TimeBillCard>
          </Grid>
        </Grid>

        {/* GENERATED CPT CARD AND IN PROGRESS CPT CARD */}
        <Box display="flex" gap="18px">
          <Stack
            boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
            bgcolor="#fff"
            padding="24px"
            gap="16px"
            borderRadius="8px"
            maxWidth="457px"
            width="100%"
            height="292px"
          >
            <Typography
              variant="body1"
              color="secondary"
              fontWeight={500}
              sx={{ textTransform: "uppercase" }}
            >
              Generated Codes ({generatedCodes?.length})
            </Typography>
            {loadingCptCodes ? (
              <RectangleSkeletons totalRows={2} height={40} />
            ) : (
              <Stack overflow="auto" gap="16px">
                {generatedCodes?.length === 0 ? (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    height="348px"
                  >
                    <Avatar
                      src={EmptyBox}
                      alt="no time entries"
                      sx={{
                        width: "128px",
                        height: "128px",
                        paddingBottom: "24px",
                      }}
                    />
                    <Typography variant="body1" color="secondary" pb={2}>
                      No data to show{" "}
                    </Typography>
                  </Stack>
                ) : (
                  generatedCodes?.map((item, index) => (
                    <CptCard
                      key={index}
                      cptItem={item}
                      cptCode={item?.cpt_code}
                      approvedAt={item?.approved_at}
                      openDrawerHandler={openDrawerHandler}
                    />
                  ))
                )}
              </Stack>
            )}
          </Stack>

          <Stack
            boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
            bgcolor="#fff"
            padding="24px"
            gap="16px"
            borderRadius="8px"
            width="100%"
            height="292px"
          >
            <Typography
              variant="body1"
              color="secondary"
              fontWeight={500}
              sx={{ textTransform: "uppercase" }}
            >
              In progress ({inProgressCodes?.length}){" "}
            </Typography>

            {loadingCptCodes ? (
              <RectangleSkeletons height={40} totalRows={2} />
            ) : (
              <Stack overflow="auto" gap="16px">
                {inProgressCodes?.length === 0 ? (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    height="348px"
                  >
                    <Avatar
                      src={EmptyBox}
                      alt="no time entries"
                      sx={{
                        width: "128px",
                        height: "128px",
                        paddingBottom: "24px",
                      }}
                    />
                    <Typography variant="body1" color="secondary" pb={2}>
                      No data to show{" "}
                    </Typography>
                  </Stack>
                ) : (
                  inProgressCodes?.map((item, index) => (
                    <CptProgressCard
                      key={index}
                      cptItem={item}
                      cptCode={item?.cpt_code}
                      openDrawerHandler={openDrawerHandler}
                      timeSpent={item?.time_spent}
                      transmissions={item?.transmissions}
                    />
                  ))
                )}
              </Stack>
            )}
          </Stack>
        </Box>

        {/* TIME SHEET CARD */}
        <Stack
          boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
          bgcolor="#fff"
          padding="24px"
          gap="16px"
          width="100%"
        >
          <Typography variant="body1" fontWeight={500} color="secondary">
            TIME SHEET
          </Typography>
          {loadingGetTimeEntry ? (
            <RectangleSkeletons height={348} totalRows={1} />
          ) : timeEntry?.time_entries?.length > 0 ? (
            <TimeEntriesComponent
              groupedEntries={groupedEntries}
              openTimeDrawerHandler={openTimeDrawerHandler}
              deleteTimeEntryHandler={deleteTimeEntryHandler}
              // patientDetail={patientDetail}
            />
          ) : (
            <Stack alignItems="center" justifyContent="center" height="348px">
              <Avatar
                src={EmptyBox}
                alt="no time entries"
                sx={{
                  width: "128px",
                  height: "128px",
                  paddingBottom: "24px",
                }}
              />
              <Typography variant="body1" color="secondary" pb={2}>
                No data to show{" "}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default BillingTab;
