import React from "react";

const ConsentIcon = ({ color = "#0E0D1E" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M8.7418 2.35781L4.58346 3.92448C3.62513 4.28281 2.8418 5.41615 2.8418 6.43281V12.6245C2.8418 13.6078 3.4918 14.8995 4.28346 15.4911L7.8668 18.1661C9.0418 19.0495 10.9751 19.0495 12.1501 18.1661L15.7335 15.4911C16.5251 14.8995 17.1751 13.6078 17.1751 12.6245V6.43281C17.1751 5.40781 16.3918 4.27448 15.4335 3.91615L11.2751 2.35781C10.5668 2.09948 9.43346 2.09948 8.7418 2.35781Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.54102 10.3911L8.88268 11.7327L12.466 8.14941"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ConsentIcon;
