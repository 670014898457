// import { LinearProgress, linearProgressClasses } from "@mui/material";
// import React from "react";

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: "100%",
//   //   position: "relative",
//   borderRadius: 19,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     height: "100%",
//     backgroundColor:
//       theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 19,
//     height: "100%",
//     background:
//       "linear-gradient(270deg, #7CD8A7 0%, #A3DD75 26.56%, #F9A84B 51.56%, #F87E4A 76.04%, #F45454 100%)",
//   },
// }));

// const LinearProgressbar = () => {
//   return <BorderLinearProgress variant="determinate" value={100} />;
// };

// export default LinearProgressbar;
