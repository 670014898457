import React from "react";
import AppMobileInfoCard from "../AppMobileInfoCard";

const ActiveIconContent = ({
  firstLogin,
  lastAppOpened,
  appVersion,
  osVersion,
}) => {
  return (
    <AppMobileInfoCard
      firstLogin={firstLogin}
      lastAppOpened={lastAppOpened}
      appVersion={appVersion}
      osVersion={osVersion}
    />
  );
};

export default ActiveIconContent;
