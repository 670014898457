import axios from "axios";
import {
  ADD_INTAKE_ANSWER_FAIL,
  ADD_INTAKE_ANSWER_REQUEST,
  ADD_INTAKE_ANSWER_SUCCESS,
  CREATE_PRESET_FAIL,
  CREATE_PRESET_REQUEST,
  CREATE_PRESET_SUCCESS,
  DELETE_PRESET_FAIL,
  DELETE_PRESET_REQUEST,
  DELETE_PRESET_SUCCESS,
  GET_CLIENT_INTAKE_LISTS_FAIL,
  GET_CLIENT_INTAKE_LISTS_REQUEST,
  GET_CLIENT_INTAKE_LISTS_SUCCESS,
  GET_PRESET_FAIL,
  GET_PRESET_REQUEST,
  GET_PRESET_SUCCESS,
  GET_QUESTIONS_BY_CTYPE_FAIL,
  GET_QUESTIONS_BY_CTYPE_REQUEST,
  GET_QUESTIONS_BY_CTYPE_SUCCESS,
  INTAKE_QUESTIONS_ANSWERS_FAIL,
  INTAKE_QUESTIONS_ANSWERS_REQUEST,
  INTAKE_QUESTIONS_ANSWERS_SUCCESS,
  INTAKE_REMINDER_FAIL,
  INTAKE_REMINDER_REQUEST,
  INTAKE_REMINDER_SUCCESS,
  RESET_ADD_INTAKE_ANSWER,
  RESET_CREATE_PRESET,
  RESET_DELETE_PRESET,
  RESET_GET_QUESTIONS_BY_CTYPE,
  RESET_INTAKE_QUESTIONS_ANSWERS,
  RESET_INTAKE_REMINDER,
  RESET_PRESET_DETAIL,
  RESET_UPDATE_PRESET,
  UPDATE_PRESET_FAIL,
  UPDATE_PRESET_REQUEST,
  UPDATE_PRESET_SUCCESS,
} from "../../assets/constants/clientIntakeConstants";
import { BASE_URL } from "..";
import Cookies from "js-cookie";

// ============= GET CLIENT INTAKE LISTS ACTIONS  ========================
export const getClientIntakeListsFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CLIENT_INTAKE_LISTS_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/intake/get-presets?agency_id=${agencyId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_CLIENT_INTAKE_LISTS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CLIENT_INTAKE_LISTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ============= GET PRESET DETAIL ACTIONS  ========================
export const getPresetDetailFunc = (preset_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRESET_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/intake/get-preset?intake_preset_id=${preset_id}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_PRESET_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_PRESET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetPresetDetail = () => ({
  type: RESET_PRESET_DETAIL,
});

// ============= CREATE PRESET ACTIONS  ========================
export const createPresetFunc = (presetObj) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PRESET_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/intake/create-preset`,
        presetObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: CREATE_PRESET_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_PRESET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetCreatePreset = () => ({
  type: RESET_CREATE_PRESET,
});

// ============= UPDATE PRESET ACTIONS  ========================
export const updatePresetFunc = (presetObj) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRESET_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/intake/update-preset`,
        presetObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: UPDATE_PRESET_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_PRESET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetUpdatePreset = () => ({
  type: RESET_UPDATE_PRESET,
});

// ============= DELETE PRESET ACTIONS  ========================
export const deletePresetFunc = (presetObj) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRESET_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/intake/delete-preset`,
        presetObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: DELETE_PRESET_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_PRESET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetDeletePreset = () => ({
  type: RESET_DELETE_PRESET,
});

// ============= INTAKE QUESTIONS ANSWERS ACTIONS  ========================
export const intakeQuestionsAnswersFunc =
  (clientId, caseId) => async (dispatch) => {
    try {
      dispatch({ type: INTAKE_QUESTIONS_ANSWERS_REQUEST });

      const token = Cookies.get("token");

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/intake/get-intake-responses?client_id=${clientId}&case_id=${caseId}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: INTAKE_QUESTIONS_ANSWERS_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: INTAKE_QUESTIONS_ANSWERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetIntakeQuestionsAnswers = () => (dispatch) =>
  dispatch({
    type: RESET_INTAKE_QUESTIONS_ANSWERS,
  });

// ============= INTAKE REMINDER ACTIONS  ========================
export const intakeReminderFunc = (intakeObj) => async (dispatch) => {
  try {
    dispatch({ type: INTAKE_REMINDER_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/intake/send-intake-reminder`,
        intakeObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: INTAKE_REMINDER_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: INTAKE_REMINDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetIntakeReminder = () => (dispatch) =>
  dispatch({
    type: RESET_INTAKE_REMINDER,
  });

// ============= GET QUESTIONS BY CTYPE ACTIONS  ========================
export const getQuestionsByCaseTypeFunc = (caseType) => async (dispatch) => {
  try {
    dispatch({ type: GET_QUESTIONS_BY_CTYPE_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let response;

      if (caseType) {
        if (caseType === "Slip & Fall") {
          response = await axios.get(
            `${BASE_URL}/intake/get-questions-by-case-type?case_type=Slip%20%26%20Fall`,
            config
          );
        } else {
          response = await axios.get(
            `${BASE_URL}/intake/get-questions-by-case-type?case_type=${caseType}`,
            config
          );
        }
      } else {
        response = await axios.get(
          `${BASE_URL}/intake/get-questions-by-case-type?default_questions=true`,
          config
        );
      }

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_QUESTIONS_BY_CTYPE_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_QUESTIONS_BY_CTYPE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetQuestionsByCaseType = () => (dispatch) =>
  dispatch({
    type: RESET_GET_QUESTIONS_BY_CTYPE,
  });

// ============= ADD INTAKE ANSWERS ACTIONS  ========================
export const addIntakeAnswersFunc = (answersObj) => async (dispatch) => {
  try {
    dispatch({ type: ADD_INTAKE_ANSWER_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/intake/add-multiple-responses`,
        answersObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: ADD_INTAKE_ANSWER_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_INTAKE_ANSWER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetIntakeAnswers = () => (dispatch) =>
  dispatch({
    type: RESET_ADD_INTAKE_ANSWER,
  });
