import React from "react";

import { Drawer, Stack, createTheme } from "@mui/material";

const CommonDrawer = ({
  anchor,
  open,
  onClose,
  setOpen,
  children,
  height,
  padding = "32px",
  width = "748px",
}) => {
  const theme = createTheme();
  const drawer__width = {
    width: width,
    [theme.breakpoints.down("md")]: {
      width: "50vw",
    },
  };

  return (
    <Drawer anchor={anchor} open={open} onClose={onClose}>
      <Stack style={drawer__width} height={height} padding={padding} gap="24px">
        {children}
      </Stack>
    </Drawer>
  );
};

export default CommonDrawer;
