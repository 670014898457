import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TimerIcon } from "../../assets/constants";
import WaitingClockIcon from "../../icons/WaitingClockIcon";
import DateTimeCard from "../DateTimeCard";
import {
  dateFormat,
  formatElapsedTime,
  getCptDateRanges,
  groupedEntriesFunc,
  onlyDateFormat,
} from "../../utils/helpers";
import TimeEntriesComponent from "../TimeEntriesComponent";
import { useDispatch } from "react-redux";
import {
  resetUpdateCptCodes,
  updateCptCodeFunc,
} from "../../actions/CptCodesActions";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          fontSize="12px"
          color="secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

const Cpt98980Content = ({
  cptCodeDetail,
  selectValue,
  onclose,
  patientDetail,
}) => {
  const [showBox, setShowBox] = useState(true);
  const [isChecked, setIsChecked] = useState(
    cptCodeDetail?.interactive_communication_done
  );

  const { startDate, endDate } = getCptDateRanges(selectValue);

  const entries = groupedEntriesFunc(cptCodeDetail?.time_entries);

  const agentId = Cookies.get("agent_id_vita");

  const dispatch = useDispatch();
  const updateCptCode = useSelector((state) => state.updateCptCode);
  const { updatedCptCode } = updateCptCode;

  const updateCptCodeHandler = (e) => {
    const checkedBox = e.target.checked;
    setIsChecked(checkedBox);
    let cptCodeObj;
    if (checkedBox && cptCodeDetail?.time_spent >= 1200) {
      cptCodeObj = {
        cpt_code_id: cptCodeDetail?.id,
        updated_cpt_code: {
          interactive_communication_done: true,
          approved: true,
          approved_at: Date.now(),
          approved_by: agentId,
        },
        patient_id: patientDetail?.id,
      };
    } else if (checkedBox && cptCodeDetail?.time_spent < 1200) {
      cptCodeObj = {
        cpt_code_id: cptCodeDetail?.id,
        updated_cpt_code: {
          interactive_communication_done: true,
        },
        patient_id: patientDetail?.id,
      };
    } else if (!checkedBox && cptCodeDetail?.time_spent >= 1200) {
      cptCodeObj = {
        cpt_code_id: cptCodeDetail?.id,
        updated_cpt_code: {
          interactive_communication_done: false,
          approved: false,
        },
        patient_id: patientDetail?.id,
      };
    } else if (!checkedBox && cptCodeDetail?.time_spent < 1200) {
      cptCodeObj = {
        cpt_code_id: cptCodeDetail?.id,
        updated_cpt_code: {
          interactive_communication_done: false,
        },
        patient_id: patientDetail?.id,
      };
    }

    dispatch(updateCptCodeFunc(cptCodeObj));
  };

  useEffect(() => {
    if (updatedCptCode?.ok) {
      dispatch(resetUpdateCptCodes());
    }
  }, [updatedCptCode]);

  return (
    <>
      <Stack gap="24px">
        <Typography
          variant="body1"
          fontWeight={500}
          color="secondary"
          textTransform="uppercase"
        >
          Code requirements{" "}
        </Typography>
        <Box
          display="flex"
          border="1px solid #EBEBEB"
          alignItems="flex-start"
          gap="12px"
          bgcolor="#FCFCFC"
          borderRadius="8px"
          padding="8px"
        >
          <FormControlLabel
            sx={{ marginRight: "0", flex: "1", alignItems: "flex-start" }}
            control={
              <Checkbox
                color="success"
                checked={isChecked}
                onChange={updateCptCodeHandler}
                sx={{ padding: "1px 9px" }}
              />
            }
            label="At least one interactive communication via phone or video with the patient during the month"
          />
        </Box>

        <Stack
          border="1px solid #EBEBEB"
          bgcolor="#fff"
          borderRadius="8px"
          padding="8px"
          gap="24px"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap="12px"
            bgcolor="#FCFCFC"
            borderRadius="8px"
          >
            <FormControlLabel
              sx={{ marginRight: "0", flex: "1", cursor: "default" }}
              control={
                <Checkbox
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  checked={cptCodeDetail?.time_spent >= 1200}
                  color="success"
                  sx={{ paddingLeft: "17px", cursor: "default" }}
                />
              }
              label={
                <Box component="span" fontSize="14px">
                  20 minutes of treatment time per calendar month
                </Box>
              }
            />
            <LinearProgressWithLabel
              color="success"
              value={
                cptCodeDetail?.time_spent
                  ? (cptCodeDetail?.time_spent / 1200) * 100
                  : 0
              }
              sx={{ width: "74px", borderRadius: "10px" }}
            />
            <Button
              variant="text"
              sx={{ textTransform: "initial", cursor: "pointer" }}
              onClick={() => setShowBox((prev) => !prev)}
            >
              {showBox ? "Hide details" : "Show details"}
            </Button>
            {/* <IconButton
              aria-label="more"
              id="long-button"
              // aria-controls={open ? "long-menu" : undefined}
              // aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              // onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton> */}
          </Box>

          {showBox && (
            <Stack gap="12px" bgcolor="#FCFCFC">
              <Typography padding="0 12px" variant="body2" color="secondary">
                {onlyDateFormat(startDate)} to {onlyDateFormat(endDate)}{" "}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="12px"
                bgcolor="#fff"
              >
                <Typography variant="subtitle1" fontSize="16px" color="#212121">
                  Total Time
                </Typography>
                <Typography
                  variant="body1"
                  fontSize="19px"
                  color="secondary"
                  fontWeight={500}
                >
                  {formatElapsedTime(cptCodeDetail?.time_spent * 1000)}
                </Typography>
              </Box>

              <TimeEntriesComponent
                groupedEntries={entries}
                showMoreVertIcon={false}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default Cpt98980Content;
