import React, { useEffect, useState } from "react";
import BgBox from "../../../common/BgBox";
import CardUI from "../../../common/Card";
import MccLogo from "../../../components/MccLogo";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import HeaderText from "../../../common/HeaderText";
import { LoadingButton } from "@mui/lab";
import Textfield from "../../../common/Textfield";
import PasswordConstraint from "../../../common/PasswordConstraint";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../../utils/helpers";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { register, resetRegister } from "../../../actions/Auth";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  createOrganizationFunc,
  resetCreateOrgFunc,
} from "../../../actions/OrganizationActions";
import AlertMessage from "../../../common/AlertMessage";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*.?])[A-Za-z\d!@#$%^&*.?]{8,}$/;

const SignUpCareManagement = () => {
  const [checkedTos, setCheckedTos] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [validPassword, setValidPassword] = useState(true);
  const [fieldVals, setFieldVals] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  const createOrganization = useSelector((state) => state.createOrganization);
  const {
    loading: loadingOrg,
    error: errorOrg,
    organization,
  } = createOrganization;

  const emailChangeHandler = (e) => {
    const emailInputValue = e.target.value;
    setEmail(emailInputValue);

    const emailIsValid = validateEmail(emailInputValue);
    if (!emailIsValid) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };

  const changePasswordHandler = (event) => {
    const { name, value } = event?.target;
    setFieldVals({ ...fieldVals, [name]: value });
    setValidPassword(passwordRegex.test(event.target.value));
  };

  const changeConfirmPasswordHandler = (event) => {
    const { name, value } = event?.target;
    setFieldVals({ ...fieldVals, [name]: value });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const submitRegisterHandler = (e) => {
    e.preventDefault();

    if (fieldVals.password !== fieldVals.confirmPassword) {
      setErrorMsg("Password should match");
      return;
    }

    setErrorMsg("");
    setEmailErrorMsg("");

    const registerObj = {
      agent_email: email,
      first_name: fullName,
      password: fieldVals?.password,
      role: "Organization Admin",
    };

    dispatch(register(registerObj));
  };

  useEffect(() => {
    if (userInfo?.ok) {
      dispatch(resetRegister());
      const organizationObj = {
        organization: {
          name: organizationName,
          primary_email: email,
        },
      };
      dispatch(createOrganizationFunc(organizationObj));
    }
  }, [userInfo]);

  useEffect(() => {
    if (!loading && !userInfo?.ok) {
      setEmailErrorMsg(userInfo?.message);
      setOpen(true);
    }
  }, [userInfo]);

  useEffect(() => {
    if (organization?.ok) {
      dispatch(resetCreateOrgFunc());
      navigate("/otp-verification", {
        state: { email: email, createAgency: true, fromCareGroup: true },
      });
    }
  }, [organization]);

  return (
    <>
      {!loading && emailErrorMsg && (
        <AlertMessage
          message={emailErrorMsg}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      <BgBox>
        <CardUI paddingTop="66px">
          {/* MCC LOGO */}
          <MccLogo />
          <Grid container component="main">
            <Grid component="form" item xs={12}>
              <Stack gap="40px">
                <Stack>
                  <HeaderText
                    variant="h3"
                    fontSize={34}
                    textContent="Fill Your details to continue"
                    lineHeight="24px"
                    textAlign="center"
                  />
                  <Typography
                    variant="body1"
                    className="d-flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize={16}
                    mt="16px"
                    color="#566875"
                  >
                    We suggest using the email address you use at work.
                  </Typography>
                </Stack>
                <Stack gap="16px">
                  <Textfield
                    value={organizationName}
                    onChange={(e) => setOrganizationName(e.target.value)}
                    variant="outlined"
                    name="organization_name"
                    label="Organization Name"
                    placeholder="Organization Name"
                    width="100%"
                    //   errorContent={
                    //     error && !firstName && "Please enter first name"
                    //   }
                  />
                  <Textfield
                    name="full_name"
                    label="Full Name"
                    placeholder="Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  <Textfield
                    name="email"
                    label="Email"
                    value={email}
                    onChange={emailChangeHandler}
                    placeholder="example@gmail.com"
                    variant="outlined"
                    errorContent={emailError && "Enter valid email"}
                    helperText={emailError && "Enter valid email"}
                  />
                  <Textfield
                    width="100%"
                    variant="outlined"
                    errorContent={!validPassword && "Enter valid password"}
                    helperText={
                      !validPassword &&
                      "Password must be a combination of uppercase and lowercase characters, numbers and special characters(!, @, $, %, &, *, ?)"
                    }
                    value={fieldVals?.password}
                    onChange={(e) => changePasswordHandler(e)}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    name="password"
                    placeholder="Password"
                    label="Password"
                  />

                  <Textfield
                    autoComplete
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    helperText={errorMsg && "Password does not match"}
                    errorContent={errorMsg && "Password does not match"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={fieldVals?.confirmPassword}
                    name="confirmPassword"
                    onChange={(e) => changeConfirmPasswordHandler(e)}
                    type={showPassword ? "text" : "password"}
                    width="100%"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value={checkedTos}
                        onChange={(e) => setCheckedTos(e.target.checked)}
                      />
                    }
                    label={
                      <Typography variant="body2" fontSize="1rem">
                        I have read and agree to the{" "}
                        <Box
                          component="a"
                          href="https://vitatrax.co/practice-terms-of-services/"
                          target="_blank"
                          color="#0C80F3"
                          sx={{ cursor: "pointer" }}
                        >
                          Terms of Service
                        </Box>
                      </Typography>
                    }
                  />

                  <LoadingButton
                    id="login-submit"
                    loading={loading}
                    fullWidth
                    onClick={submitRegisterHandler}
                    disabled={
                      !organizationName ||
                      !email ||
                      !fullName ||
                      !fieldVals?.password ||
                      !checkedTos
                    }
                    //   loading={loading}
                    variant="contained"
                    sx={{ padding: "8px 22px", fontSize: 15 }}
                  >
                    Continue{" "}
                  </LoadingButton>
                </Stack>
              </Stack>

              {/* <Box my="16px" textAlign="center">
          <Typography>or</Typography>
        </Box>

        <Button
          fullWidth
          color="secondary"
          startIcon={<GoogleBtn />}
          className={clsx(classes.google_btn)}
          variant="outlined"
        >
          Sign in With Google
        </Button> */}

              {/* <Button
        className="w-100"
        fontSize="15px"
        color="primary"
        
        title="Sign In"
        variant="contained"
        width="100%"
        height="48px"
        // disabled={!fieldVals?.email || !fieldVals?.password} // Disabling it off on purpose
        // onClick={() => onSignIn()}
        // loading={signInLoading}
        loadingColor="primary"
      /> */}
            </Grid>
          </Grid>
        </CardUI>
      </BgBox>
    </>
  );
};

export default SignUpCareManagement;
