// import { Box, Checkbox, Chip, Divider, Stack, Typography } from "@mui/material";
// import React from "react";
// import AvatarWithName from "../AvatarWithName";
// import { caseStatusType, onlyDateFormat } from "../../utils/helpers";

// const useStyles = makeStyles(() => {
//   return {
//     list_container: {
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "space-between",
//       gap: "12px",
//       background: "#fff",
//       cursor: "pointer",
//       "&:hover": {
//         background: "#EBEBEB",
//       },
//     },
//   };
// });

// const BillingCaseList = ({ caseItem, changeCheckboxHandler, isSelected }) => {
//   const classes = useStyles();

//   return (
//     <>
//       <Box
//         component="a"
//         p="16px 0"
//         display="flex"
//         alignItems="center"
//         justifyContent="space-between"
//         gap="16px"
//         className={classes.list_container}
//         // onClick={(e) => changeCheckboxHandler(e, caseItem)}
//       >
//         <Box>
//           <Checkbox
//             checked={isSelected(caseItem)}
//             onChange={(e) => changeCheckboxHandler(e, caseItem)}
//           />
//         </Box>

//         <Stack gap="2px" flex="1">
//           <Typography variant="subtitle1" fontSize="14px" fontWeight="400">
//             {caseItem?.title}
//           </Typography>

//           {caseItem?.case_number && (
//             <Typography
//               // className={classes.doc_title}
//               variant="body2"
//               // fontSize="16px"
//               fontWeight="400"
//               color="rgba(0, 0, 0, 0.38)"
//             >
//               {caseItem?.case_number}{" "}
//             </Typography>
//           )}
//         </Stack>
//         <Box display="flex" alignItems="center" gap="2px" flex="1">
//           <AvatarWithName
//             name={caseItem?.client?.name}
//             imageURL={caseItem?.client?.profile_picture_url}
//           />
//         </Box>
//         <Box flex="1">
//           <Chip
//             size="small"
//             label={caseItem?.status}
//             color={caseStatusType(caseItem?.status)}
//             variant="outlined"
//           />
//         </Box>
//         <Box flex="1" display="flex" justifyContent="flex-end">
//           {caseItem?.case_opening_date ? (
//             <Typography
//               // className={classes.doc_title}
//               variant="body2"
//               // fontSize="16px"
//               fontWeight="400"
//               color="rgba(0, 0, 0, 0.38)"
//             >
//               {onlyDateFormat(caseItem?.case_opening_date)}{" "}
//             </Typography>
//           ) : (
//             <Typography
//               // className={classes.doc_title}
//               variant="body2"
//               // fontSize="16px"
//               fontWeight="400"
//               color="rgba(0, 0, 0, 0.38)"
//             >
//               -
//             </Typography>
//           )}
//         </Box>
//       </Box>
//       <Divider />
//     </>
//   );
// };

// export default BillingCaseList;
