import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL } from "..";
import {
  ADD_DIAGNOSIS_CODE_FAIL,
  ADD_DIAGNOSIS_CODE_REQUEST,
  ADD_DIAGNOSIS_CODE_SUCCESS,
  GET_DIAGNOSIS_OPTIONS_FAIL,
  GET_DIAGNOSIS_OPTIONS_REQUEST,
  GET_DIAGNOSIS_OPTIONS_SUCCESS,
  REMOVE_DIAGNOSIS_CODE_FAIL,
  REMOVE_DIAGNOSIS_CODE_REQUEST,
  REMOVE_DIAGNOSIS_CODE_SUCCESS,
  RESET_ADD_DIAGNOSIS_CODE,
  RESET_REMOVE_DIAGNOSIS_CODE,
} from "../../assets/constants/diagnosisConstants";

// ================== GET DIAGNOSIS OPTIONS ACTIONS  =========================
export const getDiagnosisOptionsFunc =
  (search = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_DIAGNOSIS_OPTIONS_REQUEST });

      const token = Cookies.get("token");
      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/patient/get-diagnostic-codes?search=${search}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: GET_DIAGNOSIS_OPTIONS_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_DIAGNOSIS_OPTIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// ================== ADD DIAGNOSIS CODE ACTION  =========================
export const addDiagnosisCodeFunc = (diagnosisCodeObj) => async (dispatch) => {
  try {
    dispatch({ type: ADD_DIAGNOSIS_CODE_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/patient/add-diagnostic-codes`,
        diagnosisCodeObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: ADD_DIAGNOSIS_CODE_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_DIAGNOSIS_CODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetAddDiagnosisCode = () => async (dispatch) => {
  dispatch({ type: RESET_ADD_DIAGNOSIS_CODE });
};

export const resetRemoveDiagnosisCode = () => async (dispatch) => {
  dispatch({ type: RESET_REMOVE_DIAGNOSIS_CODE });
};

// ================== REMOVE DIAGNOSIS CODE ACTION  =========================
export const removeDiagnosisCodeFunc =
  (diagnosisCodeObj) => async (dispatch) => {
    try {
      dispatch({ type: REMOVE_DIAGNOSIS_CODE_REQUEST });

      const token = Cookies.get("token");
      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.post(
          `${BASE_URL}/patient/remove-diagnostic-code`,
          diagnosisCodeObj,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: REMOVE_DIAGNOSIS_CODE_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: REMOVE_DIAGNOSIS_CODE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
