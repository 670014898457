import { Box, Paper, createTheme } from "@mui/material";

const theme = createTheme();

const responsive_card = {
  [theme.breakpoints.down("lg")]: {
    maxWidth: "100% !important",
  },
};

const CardUI = ({
  children,
  maxWidth = 540,
  dashboard = false,
  responsive = "false",
  height,
  overflow,
  paddingTop = "86px",
  padding = "68px 40px",
  borderRadius = "8px",
  boxShadow = "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
  paddingBottom,
}) => {
  return (
    <Paper
      style={responsive === "true" ? responsive_card : {}}
      sx={{
        maxWidth: maxWidth,
        width: "100%",
        borderRadius: borderRadius,
        padding: dashboard ? "32px" : padding,
        paddingTop: dashboard ? "32px" : paddingTop,
        WebkitBoxShadow: boxShadow,
        background: "#fff",
        height: height && height,
        overflow: overflow ? overflow : dashboard ? "auto" : "inherit",
        position: "relative",
        paddingBottom: paddingBottom && paddingBottom,
      }}
    >
      {children}
    </Paper>
  );
};

export default CardUI;
