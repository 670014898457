import React from "react";

const PlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M2.33301 6.99998V4.92331C2.33301 2.34498 4.15884 1.28915 6.39301 2.57831L8.19551 3.61665L9.99801 4.65498C12.2322 5.94415 12.2322 8.05581 9.99801 9.34498L8.19551 10.3833L6.39301 11.4216C4.15884 12.7108 2.33301 11.655 2.33301 9.07665V6.99998Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlayIcon;
