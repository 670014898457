import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { stringAvatar } from "../../utils/helpers";

const PractiseBoxCard = ({
  icon,
  src,
  title,
  practiseId,
  open,
  switchWorkspaceHandler,
  mb = "12px",
  showEllipsis = false,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap="12px"
      mb={mb}
      width="100%"
    >
      <Box display="flex" alignItems="center" gap="12px">
        <Avatar
          variant="rounded"
          sx={{ bgcolor: "#B0D6FE" }}
          {...stringAvatar(title)}
        />
        <Stack>
          {showEllipsis ? (
            <Tooltip title={title} placement="top-end">
              <Typography
                variant="body1"
                fontWeight={500}
                sx={{
                  textOverflow: "ellipsis !important",
                  whiteSpace: "nowrap !important",
                  width: "130px !important",
                  overflow: "hidden !important",
                }}
              >
                {title}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body1" fontWeight={500}>
              {title}
            </Typography>
          )}
          <Typography variant="caption" color="secondary">
            Practice ID : {practiseId}
          </Typography>
        </Stack>
      </Box>

      <IconButton
        sx={{ padding: 0 }}
        onClick={switchWorkspaceHandler}
        aria-controls={open ? "workspace-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        // disableRipple
      >
        {icon}
      </IconButton>
    </Box>
  );
};

export default PractiseBoxCard;
