import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Profile from "../pages/Profile";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import EmailSent from "../pages/EmailSent";
import ChangedPassword from "../pages/ChangedPassword";
import OtpVerification from "../pages/Signup/OtpVerification";
import JoinWorkspace from "../pages/Signup/JoinWorkspace";
import CreateWorkspace from "../pages/Signup/CreateWorkspace";
import CreatePassword from "../pages/Signup/CreatePassword";
import PasswordCreated from "../pages/Signup/PasswordCreated";
import AppLayout from "../components/AppLayout";
import Settings from "../pages/Settings";
import DashboardComponent from "../components/Dashboard";
import Clients from "../pages/Clients";
import Marketing from "../pages/Marketing";
import Referrals from "../pages/Referrals";
import Overview from "../pages/Clients/Overview";
import CircularProgressLoading from "../common/CircularProgress";
import { useSelector } from "react-redux";
import CaseOverview from "../pages/Cases/CaseOverview";
import CreateAgentName from "../pages/Signup/CreateAgentName";
import { getMyValue } from "../utils/helpers";
import ProtectedRoute from "../ProtectedRoute";
import LoadingSpinner from "../components/LoadingSpinner";
import CrashScreen from "../components/CrashScreen.js";
import Integrations from "../pages/Integrations/index.js";
import IntegrationSuccessPage from "../components/IntegrationSuccessPage/index.js";
import CaseSummaryPdfPage from "../pages/CaseSummaryPdfPage/index.js";
import AccountType from "../pages/Signup/AccountType/index.jsx";
import TermsOfService from "../pages/Signup/TermsOfService/index.jsx";
import SignUpCareManagement from "../pages/Signup/SignUpCareManagement/index.jsx";
import BillingPage from "../pages/BillingPage/index.jsx";

// import Layout from '../Layout';
// import Protected from './protected';
// import Loader from '../common/Loader';
// import UnProtected from './unProtected';

const Signup = lazy(() => import("../pages/Signup"));
const Signin = lazy(() => import("../pages/Signin"));
const Cases = lazy(() => import("../pages/Cases"));

const MainRouts = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const [showPdfSummary, setShowPdfSummary] = useState(false);

  const { userInfo } = userLogin;
  const [cookieValues, setCookieValues] = useState({
    token: "",
    agentId: "",
    agencyId: "",
  });

  useEffect(() => {
    setCookieValues((prevState) => ({
      // ...prevState,
      // token: userInfo?.token,
      // agentId: userInfo?.agent?.id,
      // agencyId: userInfo?.agent?.agencyId,
      ...prevState,
      token: getMyValue(userInfo?.token),
      agentId: getMyValue(userInfo?.agentId),
      agencyId: getMyValue(userInfo?.agencyId),
    }));
  }, [userInfo]);

  const isAuthenticated =
    cookieValues?.token && cookieValues?.agentId && cookieValues?.agencyId;

  // const [userInfo, setUserInfo] = useState({
  //   token: "",
  //   agencyId: "",
  //   agentId: "",
  // });

  // useEffect(() => {
  //   const token = Cookies.get("token");
  //   const agentId = Cookies.get("agentId");

  //   if (
  //     token !== userInfo.token ||
  //     agencyId !== userInfo.agencyId ||
  //     agentId !== userInfo.agentId
  //   ) {
  //     setUserInfo({
  //       token: token || "",
  //       agencyId: agencyId || "",
  //       agentId: agentId || "",
  //     });
  //   }
  // }, []);

  // console.log("USERINFO", userInfo);

  return (
    <BrowserRouter>
      <Suspense fallback={<CircularProgressLoading />}>
        <Routes>
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route exact path="/" element={<DashboardComponent />} />
            <Route
              exact
              path="/patients"
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Cases />
                </Suspense>
              }
            />
            <Route exact path="/patient/:id" element={<CaseOverview />} />
            {/* <Route exact path="/integrations" element={<Integrations />} /> */}
            {/* <Route exact path="/billing-report" element={<BillingPage />} /> */}
            {/*    <Route exact path="/clients" element={<Clients />} />
            <Route exact path="/client/:id" element={<Overview />} />
            <Route exact path="/referrals" element={<Referrals />} />
             */}
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/settings" element={<Settings />} />
          </Route>

          {/* <Route
            path="/integration-success"
            element={<IntegrationSuccessPage />}
          />
          <Route
            exact
            path="/client-summary-report/:id"
            element={<CaseSummaryPdfPage />}
          /> */}
          <Route
            path="/signin"
            element={
              isAuthenticated ? (
                <Navigate to="/" />
              ) : (
                <Suspense fallback={<LoadingSpinner />}>
                  <Signin />
                </Suspense>
              )
            }
          />
          <Route
            path="/forgot-password"
            element={isAuthenticated ? <Navigate to="/" /> : <ForgotPassword />}
          />
          {/* <Route path="/checkmail" element={<EmailSent />} /> */}
          <Route path="/password-changed" element={<ChangedPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/account-type"
            element={
              isAuthenticated ? (
                <Navigate to="/" />
              ) : (
                <Suspense fallback={<LoadingSpinner />}>
                  <AccountType />
                </Suspense>
              )
            }
          />
          <Route
            path="/register"
            element={
              isAuthenticated ? (
                <Navigate to="/" />
              ) : (
                <Suspense fallback={<LoadingSpinner />}>
                  <Signup />
                </Suspense>
              )
            }
          />
          <Route
            path="/register-care-management"
            element={
              isAuthenticated ? (
                <Navigate to="/" />
              ) : (
                <Suspense fallback={<LoadingSpinner />}>
                  <SignUpCareManagement />
                </Suspense>
              )
            }
          />
          <Route path="/create-password" element={<CreatePassword />} />
          <Route path="/otp-verification" element={<OtpVerification />} />
          <Route path="/join-workspace" element={<JoinWorkspace />} />
          <Route path="/create-workspace" element={<CreateWorkspace />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/create-agentname" element={<CreateAgentName />} />
          <Route path="/password-created" element={<PasswordCreated />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
export default MainRouts;
