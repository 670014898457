// import React, { useEffect, useState, useRef } from "react";
// import {
//   Box,
//   Button,
//   IconButton,
//   Stack,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Typography,
// } from "@mui/material";
// import pdfLogo from "../../assets/images/pdfLogo.png";
// import pdfGradient from "../../assets/images/pdfGradient.png";
// import {
//   bodyPainWord,
//   dateFormat,
//   exportPdfPainData,
//   getCaseValueText,
//   onlyDateFormat,
// } from "../../utils/helpers";
// import "../../index.css";
// import CustomPieChart from "../../components/CustomPieChart";
// import { Document, Page } from "react-pdf";
// import { pdfjs } from "react-pdf";
// import CurveChart from "../../components/CurveChart";
// import dayjs from "dayjs";
// import PdfBackArrow from "../../icons/PdfBackArrow";
// import { useNavigate } from "react-router-dom";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const useStyles = makeStyles(() => {
//   return {
//     header: {
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "space-between",
//       padding: "16px 40px",
//       background: "#fff",
//       position: "fixed",
//       top: "0",
//       left: "0",
//       width: "100%",
//       height: "70px",
//       boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
//       "@media print": {
//         display: "none",
//       },
//     },
//     container: {
//       padding: "50px",
//       "@media print": {
//         padding: "40px 0",
//       },
//     },
//     pdfViewerContainer: {
//       width: "100%" /* Full width of the parent */,
//       // paddingTop: "141.42%" /* Height / Width * 100 for A4 aspect ratio */,
//       // position: "relative",
//     },
//     subitem_gap: {
//       padding: "0 16px",
//       fontSize: "20px",
//     },
//     page_break: {
//       "@media print": {
//         padding: "0",
//         pageBreakAfter: "always",
//       },
//     },
//     padding_40: {
//       padding: "40px 0",
//       // "@media print": {
//       //   padding: "40px 0",
//       // },
//     },
//     bgColor: {
//       "& > :nth-child(odd)": {
//         backgroundColor: "#F4F6F8",
//       },
//       "@media print": {
//         "& > :nth-child(odd)": {
//           backgroundColor: "#F4F6F8",
//         },
//       },
//     },
//     pdfBgImage: {
//       backgroundImage: `url(${pdfGradient}) `,
//       backgroundRepeat: "no-repeat",
//       backgroundSize: "cover",
//       backgroundPosition: "right top",
//       display: "flex",
//       width: "100%",
//       display: "flex",
//       gap: "40px",
//       padding: "40px",
//       "@media print": {
//         height: "100vh",
//         padding: "40px 0",
//         backgroundImage: `url(${pdfGradient})`,
//         pageBreakAfter: "always",
//       },
//     },
//   };
// });

// function MyPDFViewer({ file }) {
//   const [numPages, setNumPages] = useState(null);

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages); // Update state with the number of pages
//   };

//   // Use static values to test the PDF display
//   const staticWidth = 595;
//   const staticHeight = 842;

//   return (
//     <div
//     // style={{
//     //   width: `${staticWidth}px`,
//     //   height: `${staticHeight}px`,
//     //   overflow: "auto",
//     // }}
//     >
//       <Document
//         file={file}
//         onLoadSuccess={({ numPages }) => setNumPages(numPages)}
//       >
//         {Array.from(new Array(numPages), (el, index) => (
//           <Page
//             key={`page_${index + 1}`}
//             pageNumber={index + 1}
//             width={staticWidth}
//           />
//         ))}
//       </Document>
//     </div>
//   );
// }

// const CaseSummaryPdfPage = () => {
//   const classes = useStyles();

//   const navigate = useNavigate();

//   const localStorageState = JSON.parse(localStorage.getItem("newTabPageState"));

//   const clientAndCaseDetail = localStorageState?.caseDetail;
//   const painLogsDetail = localStorageState?.painLogs;
//   const intakeAnswers = localStorageState?.intakeQuestionsAnswers;
//   const pieChartStatsState = localStorageState?.pieChartStatsState;
//   const document_urls = localStorageState?.document_urls;
//   const formState = localStorageState?.formState;

//   const allBodyParts = formState?.bodyParts;
//   const endDate = dayjs(formState?.endDate);
//   const startDate = dayjs(formState?.startDate);
//   const showPainLogsTable = formState?.showPainLogsTable;

//   const datesInRange = [];
//   let currentDate = endDate;
//   while (
//     currentDate?.isAfter(startDate, "day") ||
//     currentDate?.isSame(startDate, "day")
//   ) {
//     datesInRange?.push(currentDate?.format("YYYY-MM-DD"));
//     currentDate = currentDate?.subtract(1, "day");
//   }

//   const filteredPains = datesInRange?.reverse()?.map((date) => {
//     const existingEntry = painLogsDetail?.find((item) => item?.date === date);
//     if (existingEntry) {
//       const bodyPartsData = JSON.parse(existingEntry?.body_parts_data);
//       return {
//         ...existingEntry,
//         body_parts_data: bodyPartsData,
//       };
//     } else {
//       return {
//         date,
//         agency_id: null,
//         global_pain_level: null,
//         body_parts_data: [],
//       };
//     }
//   });
//   // .sort((a, b) => {
//   //   // Parse the 'created_at' strings into Date objects for comparison.
//   //   const dateA = new Date(a.date);
//   //   const dateB = new Date(b.date);

//   //   // Compare the dates using the getTime() method.
//   //   return dateA.getTime() - dateB.getTime();
//   // });

//   const pieRef = useRef();

//   const [loadImage, setLoadImage] = useState(false);

//   const allQuestions =
//     clientAndCaseDetail?.case?.intake_questions &&
//     JSON.parse(clientAndCaseDetail?.case?.intake_questions);

//   const painTableData = exportPdfPainData(painLogsDetail);

//   // useEffect(() => {
//   //   if (loadImage) {
//   //     // window.print();
//   //   }
//   // }, [loadImage]);

//   const loadImageHandler = () => {
//     setLoadImage(true);
//   };

//   const generatePdfHandler = () => {
//     if (loadImage) {
//       window.print();
//     }
//   };

//   const totalPains =
//     pieChartStatsState?.worst_pain_entries +
//     pieChartStatsState?.mild_pain_entries +
//     pieChartStatsState?.severe_pain_entries +
//     pieChartStatsState?.no_pain_entries;
//   const worstPainPercentage = pieChartStatsState?.worst_pain_entries
//     ? (pieChartStatsState?.worst_pain_entries / totalPains) * 100
//     : 0;
//   const mildPainPercentage = pieChartStatsState?.mild_pain_entries
//     ? (pieChartStatsState?.mild_pain_entries / totalPains) * 100
//     : 0;
//   const severePainPercentage = pieChartStatsState?.severe_pain_entries
//     ? (pieChartStatsState?.severe_pain_entries / totalPains) * 100
//     : 0;
//   const noPainPercentage = pieChartStatsState?.no_pain_entries
//     ? (pieChartStatsState?.no_pain_entries / totalPains) * 100
//     : 0;

//   return (
//     <div className="pdfViewerContainer">
//       <Box component="header" className={classes.header}>
//         <Box display="flex" alignItems="center" gap="24px">
//           <IconButton
//             onClick={() =>
//               navigate(`/case/${clientAndCaseDetail?.case?.id}?tab=overview`)
//             }
//           >
//             <PdfBackArrow />
//           </IconButton>
//           <Typography variant="body1" fontSize="14px" fontWeight="500">
//             SummaryReport_{clientAndCaseDetail?.case?.client?.name}
//           </Typography>
//         </Box>
//         <Button onClick={generatePdfHandler} variant="contained">
//           Generate PDF
//         </Button>
//       </Box>

//       <div
//       // style={{
//       //   position: "absolute",
//       //   top: 0,
//       //   left: 0,
//       //   height: "100%",
//       //   width: "100%",
//       // }}
//       >
//         <Stack
//           bgcolor="#fff"
//           paddingTop="74px"
//           sx={{ "@media print": { paddingTop: "0" } }}
//         >
//           <Stack className={classes.pdfBgImage}>
//             <Stack paddingLeft="40px">
//               <Typography
//                 variant="body1"
//                 fontWeight="500"
//                 fontSize="20px"
//                 ml="16px"
//               >
//                 Powered By
//               </Typography>
//               <Box width="347px">
//                 <img
//                   src={pdfLogo}
//                   onLoad={loadImageHandler}
//                   width="100%"
//                   alt="mycasecare logo"
//                 />
//               </Box>
//             </Stack>
//             <Typography
//               color="#0D467F"
//               variant="h1"
//               fontSize="80px"
//               width="460px"
//               lineHeight="116%"
//               pb="32px"
//               paddingLeft="40px"
//             >
//               Client Summary Report
//             </Typography>
//             <Box
//               borderRadius="24px 24px 24px 0px"
//               bgcolor="#0D467F"
//               padding="20px"
//               width="fit-content"
//               marginLeft="40px"
//             >
//               <Typography
//                 variant="h2"
//                 fontSize="32px"
//                 fontWeight="300"
//                 color="#fff"
//               >
//                 {clientAndCaseDetail?.case?.client?.name}{" "}
//                 {clientAndCaseDetail?.case?.opposing_party &&
//                   ` vs ${clientAndCaseDetail?.case?.opposing_party}`}
//               </Typography>
//             </Box>
//             <Box
//               borderRadius="0px 65px 0px 0px"
//               maxWidth="660px"
//               width="100%"
//               bgcolor="#fff"
//               padding="40px"
//             >
//               <Typography variant="body1" fontSize="16px" fontWeight="400">
//                 A concise overview of the individual's pain experiences,
//                 including trends, frequency, duration, and impact on daily
//                 activities. This report is essential for presenting a compelling
//                 argument in personal injury claims.
//               </Typography>
//             </Box>
//             <Box
//               display="flex"
//               alignItems="flex-end"
//               justifyContent="space-between"
//               gap="8px"
//               padding="0 40px"
//             >
//               <Stack gap="4px">
//                 {clientAndCaseDetail?.case?.agency?.name && (
//                   <Typography variant="h5" fontSize="24px" color="#0D467F">
//                     {clientAndCaseDetail?.case?.agency?.name}
//                   </Typography>
//                 )}
//                 {clientAndCaseDetail?.case?.agency?.primary_contact_number && (
//                   <Typography
//                     variant="h6"
//                     fontSize="18px"
//                     color="#0D467F"
//                     fontWeight="400"
//                   >
//                     {clientAndCaseDetail?.case?.agency?.primary_contact_number}
//                   </Typography>
//                 )}
//                 {clientAndCaseDetail?.case?.agency?.primary_email && (
//                   <Typography
//                     variant="h6"
//                     fontSize="18px"
//                     color="#0D467F"
//                     fontWeight="400"
//                   >
//                     {clientAndCaseDetail?.case?.agency?.primary_email}
//                   </Typography>
//                 )}
//                 {clientAndCaseDetail?.case?.agency?.website && (
//                   <Typography
//                     variant="h6"
//                     fontSize="18px"
//                     color="#0D467F"
//                     fontWeight="400"
//                   >
//                     {clientAndCaseDetail?.case?.agency?.website}
//                   </Typography>
//                 )}
//               </Stack>
//               <Typography
//                 component="a"
//                 variant="h6"
//                 fontSize="18px"
//                 color="#3B3B3B"
//               >
//                 www.mycasecare.com
//               </Typography>
//             </Box>
//           </Stack>

//           <Stack className={classes.container}>
//             {/* TABLE OF CONTENTS */}
//             <Stack gap="28px" padding="0 40px" className={classes.page_break}>
//               <Typography variant="subtitle2" fontSize="50px" pb="18px">
//                 Table of Contents
//               </Typography>
//               <Typography variant="subtitle2" fontSize="22px">
//                 1. Introduction
//               </Typography>
//               <Stack gap="16px">
//                 <Typography variant="subtitle2" fontSize="22px">
//                   2. Victim Information
//                 </Typography>
//                 <Typography variant="subtitle2" className={classes.subitem_gap}>
//                   2.1. Basic Information{" "}
//                 </Typography>
//                 <Typography variant="subtitle2" className={classes.subitem_gap}>
//                   2.2. Work Details{" "}
//                 </Typography>
//                 <Typography variant="subtitle2" className={classes.subitem_gap}>
//                   2.3. Hobbies & Lifestyle{" "}
//                 </Typography>
//               </Stack>
//               <Stack gap="16px">
//                 <Typography variant="subtitle2" fontSize="22px">
//                   3. Case Details
//                 </Typography>
//                 <Typography variant="subtitle2" className={classes.subitem_gap}>
//                   3.1. Basic Information{" "}
//                 </Typography>
//                 <Typography variant="subtitle2" className={classes.subitem_gap}>
//                   3.2. Intake Information{" "}
//                 </Typography>
//               </Stack>
//               <Stack gap="16px">
//                 <Typography variant="subtitle2" fontSize="22px">
//                   4. Injuries and Sufferings
//                 </Typography>
//                 <Typography variant="subtitle2" className={classes.subitem_gap}>
//                   4.1. Pain Report{" "}
//                 </Typography>
//                 <Typography variant="subtitle2" className={classes.subitem_gap}>
//                   4.1.1. Pain Trends{" "}
//                 </Typography>
//                 <Typography variant="subtitle2" className={classes.subitem_gap}>
//                   4.1.2. Pain Frequency{" "}
//                 </Typography>
//                 <Typography variant="subtitle2" className={classes.subitem_gap}>
//                   4.1.3. Additional Statistics{" "}
//                 </Typography>
//               </Stack>
//               <Typography
//                 variant="subtitle2"
//                 fontSize="22px"
//                 // sx={{
//                 //   "@media print": {
//                 //     pageBreakBefore: "always",
//                 //   },
//                 // }}
//               >
//                 5. Financial Impact
//               </Typography>
//               <Typography variant="subtitle2" fontSize="22px">
//                 6. Detailed Pain Logs
//               </Typography>
//               <Typography variant="subtitle2" fontSize="22px">
//                 7. Detailed Event Logs
//               </Typography>
//               <Typography variant="subtitle2" fontSize="22px">
//                 8. Supporting Documents
//               </Typography>
//             </Stack>

//             <Stack
//               gap="32px"
//               component="section"
//               className={classes.padding_40}
//             >
//               {/* BASIC INFORMATION */}
//               <Stack gap="16px">
//                 <Typography variant="subtitle2" fontSize="22px">
//                   1. Introduction
//                 </Typography>
//                 <Typography
//                   variant="subtitle2"
//                   fontSize="16px"
//                   fontWeight="400"
//                 >
//                   {`This comprehensive report meticulously outlines the intricate
//                 details of ${clientAndCaseDetail?.case?.client?.name}'s personal
//                 injury incident on ${dateFormat(
//                   clientAndCaseDetail?.case?.incident_date
//                 )}. It is crafted to present a
//                 thorough understanding of the medical journey, pain management,
//                 appointments, and the profound impact on ${
//                   clientAndCaseDetail?.case?.client?.name
//                 }'s daily
//                 life. The primary objective is to substantiate ${
//                   clientAndCaseDetail?.case?.client?.name
//                 }'s
//                 case for compensation and legal action.`}{" "}
//                 </Typography>
//               </Stack>

//               {/* VICTIM INFORMATION */}
//               <Stack gap="16px">
//                 <Typography variant="subtitle2" fontSize="22px">
//                   2. Victim Information
//                 </Typography>
//                 <Typography variant="subtitle2" fontSize="20px" px="16px">
//                   2.1. Basic Information
//                 </Typography>
//                 <Stack px="16px" className={classes.bgColor}>
//                   <Box display="flex" alignItems="center" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Name
//                     </Typography>
//                     <Typography variant="body1">
//                       {clientAndCaseDetail?.case?.client?.name}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" alignItems="center" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Email
//                     </Typography>
//                     <Typography variant="body1">
//                       {clientAndCaseDetail?.case?.client?.email
//                         ? clientAndCaseDetail?.case?.client?.email
//                         : "-"}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" alignItems="center" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Contact Number
//                     </Typography>
//                     <Typography variant="body1">
//                       {clientAndCaseDetail?.case?.client?.contact_number
//                         ? clientAndCaseDetail?.case?.client?.contact_number
//                         : "-"}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" alignItems="center" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Address
//                     </Typography>
//                     <Typography variant="body1">
//                       {clientAndCaseDetail?.case?.client?.address
//                         ? clientAndCaseDetail?.case?.client?.address
//                         : "-"}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" alignItems="center" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Country
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.client?.country
//                         ? clientAndCaseDetail?.case?.client?.country
//                         : "-"}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" alignItems="center" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       State
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.client?.region
//                         ? clientAndCaseDetail?.case?.client?.region
//                         : "-"}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" alignItems="center" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       City
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.client?.city
//                         ? clientAndCaseDetail?.case?.client?.city
//                         : "-"}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" alignItems="center" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Zip Code
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.client?.zipcode
//                         ? clientAndCaseDetail?.case?.client?.zipcode
//                         : "-"}
//                     </Typography>
//                   </Box>
//                 </Stack>
//                 <Typography variant="subtitle2" fontSize="20px" px="16px">
//                   2.2. Work Details{" "}
//                 </Typography>
//                 <Stack px="16px" className={classes.bgColor}>
//                   <Box display="flex" alignItems="center" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Job Title
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.client?.job_title
//                         ? clientAndCaseDetail?.case?.client?.job_title
//                         : "-"}{" "}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" alignItems="center" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Company Name
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.client?.company_name
//                         ? clientAndCaseDetail?.case?.client?.company_name
//                         : "-"}{" "}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" alignItems="center" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Annual Income
//                     </Typography>
//                     <Typography variant="body1">
//                       {clientAndCaseDetail?.case?.client?.annual_income
//                         ? `$${clientAndCaseDetail?.case?.client?.annual_income}`
//                         : "-"}{" "}
//                     </Typography>
//                   </Box>
//                 </Stack>
//                 <Typography variant="subtitle2" fontSize="20px" px="16px">
//                   2.3. Hobbies & Lifestyle{" "}
//                 </Typography>
//                 <Stack px="16px" className={classes.bgColor}>
//                   <Box display="flex" alignItems="center" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Hobbies
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.client?.hobbies
//                         ? JSON.parse(
//                             clientAndCaseDetail?.case?.client?.hobbies
//                           )?.join(", ")
//                         : "-"}{" "}
//                     </Typography>
//                   </Box>
//                 </Stack>
//               </Stack>

//               {/* CASE DETAILS  */}
//               <Stack gap="16px" className={classes.page_break}>
//                 <Typography variant="subtitle2" fontSize="22px">
//                   3. Case Details
//                 </Typography>
//                 <Typography variant="subtitle2" fontSize="20px" px="16px">
//                   3.1. Basic Details{" "}
//                 </Typography>
//                 <Stack px="16px" className={classes.bgColor}>
//                   <Box display="flex" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Case Open Date
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.client?.case_opening_date
//                         ? `$${clientAndCaseDetail?.case?.client?.case_opening_date}`
//                         : "-"}{" "}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Case Filing Date
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.client?.case_registration_date
//                         ? `$${clientAndCaseDetail?.case?.client?.case_registration_date}`
//                         : "-"}{" "}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Statute of Limitation{" "}
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.client?.statute_of_limitation
//                         ? `$${clientAndCaseDetail?.case?.client?.statute_of_limitation}`
//                         : "-"}{" "}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Date Of Incident
//                     </Typography>
//                     <Typography variant="body1">
//                       {clientAndCaseDetail?.case?.incident_date
//                         ? dateFormat(clientAndCaseDetail?.case?.incident_date)
//                         : "-"}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Case Type
//                     </Typography>
//                     <Typography
//                       variant="body1"
//                       sx={{ textTransform: "capitalize" }}
//                     >
//                       {" "}
//                       {clientAndCaseDetail?.case?.case_type
//                         ? getCaseValueText(clientAndCaseDetail?.case?.case_type)
//                         : "-"}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Opposing Party
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.opposing_party
//                         ? clientAndCaseDetail?.case?.opposing_party
//                         : "-"}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Location of Incident
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.incident_location
//                         ? clientAndCaseDetail?.case?.incident_location
//                         : "-"}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" p="6px 16px">
//                     <Typography width="185px" variant="body1" fontWeight="600">
//                       Jurisdiction
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.jurisdiction
//                         ? clientAndCaseDetail?.case?.jurisdiction
//                         : "-"}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" p="6px 16px">
//                     <Typography
//                       maxWidth="185px"
//                       width="100%"
//                       variant="body1"
//                       fontWeight="600"
//                     >
//                       Description
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.description
//                         ? clientAndCaseDetail?.case?.description
//                         : "-"}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" p="6px 16px">
//                     <Typography
//                       maxWidth="185px"
//                       width="100%"
//                       variant="body1"
//                       fontWeight="600"
//                     >
//                       Discovery Information
//                     </Typography>
//                     <Typography variant="body1">
//                       {" "}
//                       {clientAndCaseDetail?.case?.discovery_information
//                         ? clientAndCaseDetail?.case?.discovery_information
//                         : "-"}
//                     </Typography>
//                   </Box>
//                 </Stack>

//                 {/* INTAKE QUESTIONS */}
//                 {allQuestions?.length && (
//                   <>
//                     <Typography variant="subtitle2" fontSize="20px" px="16px">
//                       3.2. Intake Information{" "}
//                     </Typography>
//                     <Stack px="16px" className={classes.bgColor}>
//                       <Box display="flex" p="6px 16px" gap="8px">
//                         <Typography
//                           width="50%"
//                           variant="body1"
//                           fontWeight="600"
//                         >
//                           Question
//                         </Typography>
//                         <Typography variant="body1" width="50%">
//                           Answer given by the client
//                         </Typography>
//                       </Box>

//                       {allQuestions?.map((question) => {
//                         const correspondingAnswer = intakeAnswers?.find(
//                           (answer) =>
//                             answer?.intake_question_id === question?.id
//                         );

//                         return (
//                           <Box
//                             display="flex"
//                             p="6px 16px"
//                             key={question?.id}
//                             gap="8px"
//                           >
//                             <Typography
//                               width="50%"
//                               variant="body1"
//                               fontWeight="600"
//                             >
//                               {question?.question_text}
//                             </Typography>
//                             <Typography variant="body1" width="50%">
//                               {correspondingAnswer
//                                 ? correspondingAnswer?.answer_text
//                                 : "-"}{" "}
//                             </Typography>
//                           </Box>
//                         );
//                       })}
//                     </Stack>
//                   </>
//                 )}
//               </Stack>

//               {/* Pie Chart */}

//               <Stack gap="18px" pb="40px">
//                 <Typography variant="subtitle2" fontSize="22px">
//                   4. Injuries and Sufferings
//                 </Typography>
//                 <Typography variant="subtitle2" fontSize="20px" px="16px">
//                   4.1. Overall Pain Statistics{" "}
//                 </Typography>
//                 {pieChartStatsState?.worst_pain_entries ||
//                 pieChartStatsState?.mild_pain_entries ||
//                 pieChartStatsState?.severe_pain_entries ||
//                 pieChartStatsState?.no_pain_entries ? (
//                   <div style={{ width: "250px" }} ref={pieRef}>
//                     <CustomPieChart pieChartStatsState={pieChartStatsState} />
//                   </div>
//                 ) : (
//                   <></>
//                 )}
//                 <Stack>
//                   <Box p="6px 16px" display="flex" alignItems="center">
//                     <Box width="12px" height="12px" bgcolor="#F45454"></Box>
//                     <Typography
//                       variant="body1"
//                       mx="8px"
//                       fontWeight="600"
//                       width="176px"
//                     >
//                       Days with Worst Pain
//                     </Typography>
//                     <Typography variant="body1">
//                       {pieChartStatsState?.worst_pain_entries || 0} days (
//                       {worstPainPercentage.toFixed(2)}% of the recorded period){" "}
//                     </Typography>
//                   </Box>
//                   <Box p="6px 16px" display="flex" alignItems="center">
//                     <Box width="12px" height="12px" bgcolor="#AAE77A"></Box>
//                     <Typography
//                       variant="body1"
//                       mx="8px"
//                       fontWeight="600"
//                       width="176px"
//                     >
//                       Days with Mild Pain{" "}
//                     </Typography>
//                     <Typography variant="body1">
//                       {pieChartStatsState?.mild_pain_entries || 0} days (
//                       {mildPainPercentage.toFixed(2)}% of the recorded period){" "}
//                     </Typography>
//                   </Box>
//                   <Box p="6px 16px" display="flex" alignItems="center">
//                     <Box width="12px" height="12px" bgcolor="#FFDD66"></Box>
//                     <Typography
//                       variant="body1"
//                       mx="8px"
//                       fontWeight="600"
//                       width="176px"
//                     >
//                       Days with Severe{" "}
//                     </Typography>
//                     <Typography variant="body1">
//                       {pieChartStatsState?.severe_pain_entries || 0} days (
//                       {severePainPercentage.toFixed(2)}% of the recorded period){" "}
//                     </Typography>
//                   </Box>
//                   <Box p="6px 16px" display="flex" alignItems="center">
//                     <Box width="12px" height="12px" bgcolor="#0C80F3 "></Box>
//                     <Typography
//                       variant="body1"
//                       mx="8px"
//                       fontWeight="600"
//                       width="176px"
//                     >
//                       Days with No Pain{" "}
//                     </Typography>
//                     <Typography variant="body1">
//                       {pieChartStatsState?.no_pain_entries || 0} days (
//                       {noPainPercentage.toFixed(2)}% of the recorded period){" "}
//                     </Typography>
//                   </Box>
//                   {/* <Box p="6px 16px" display="flex" alignItems="center">
//                     <Typography
//                       variant="body1"
//                       mx="8px"
//                       fontWeight="600"
//                       width="176px"
//                     >
//                       Average Pain Intensity
//                     </Typography>
//                     <Typography variant="body1">3.7 out of 5</Typography>
//                   </Box> */}
//                 </Stack>
//               </Stack>

//               {/* LINE CHARTS  */}
//               <Stack gap="18px" className={classes.page_break}>
//                 <Typography variant="subtitle2" fontSize="20px" px="16px">
//                   4.2. Pain Trends{" "}
//                 </Typography>
//                 {allBodyParts?.map((bodyPart, index) => (
//                   <Box
//                     bgcolor="#fcfcfc"
//                     padding="0.625rem 0.5rem 0.625rem 1.125rem"
//                     borderRadius="8px"
//                     key={index}
//                     maxWidth="654px"
//                     // height="256px"
//                   >
//                     <CurveChart
//                       allDates={filteredPains}
//                       selectedBodyParts={[bodyPart]}
//                       fromPdf={true}
//                     />
//                   </Box>
//                 ))}
//               </Stack>

//               {/* PAIN LOGS */}
//               <Stack gap="12px">
//                 <Typography variant="h6" fontSize="24px" fontWeight="600">
//                   Pain Logs
//                 </Typography>
//                 <Stack>
//                   <Box display="flex" alignItems="center" gap="16px">
//                     <Typography
//                       variant="body1"
//                       fontSize="16px"
//                       fontWeight="600"
//                       width="150px"
//                     >
//                       Name:
//                     </Typography>
//                     <Typography variant="body1" fontSize="16px">
//                       {clientAndCaseDetail?.case?.client?.name}
//                     </Typography>
//                   </Box>
//                   <Box display="flex" alignItems="center" gap="16px">
//                     <Typography
//                       variant="body1"
//                       fontSize="16px"
//                       fontWeight="600"
//                       width="150px"
//                     >
//                       Contact:
//                     </Typography>
//                     {(clientAndCaseDetail?.case?.client?.email ||
//                       clientAndCaseDetail?.case?.client?.contact_number) && (
//                       <Typography variant="body1" fontSize="16px">
//                         {clientAndCaseDetail?.case?.client?.email}
//                         {clientAndCaseDetail?.case?.client?.contact_number &&
//                           ` | ${clientAndCaseDetail?.case?.client?.contact_number}`}
//                       </Typography>
//                     )}
//                   </Box>
//                   {clientAndCaseDetail?.case?.incident_date && (
//                     <Box display="flex" alignItems="center" gap="16px">
//                       <Typography
//                         variant="body1"
//                         fontSize="16px"
//                         fontWeight="600"
//                         width="150px"
//                       >
//                         Date of Incident:{" "}
//                       </Typography>
//                       <Typography variant="body1" fontSize="16px">
//                         {onlyDateFormat(
//                           clientAndCaseDetail?.case?.incident_date
//                         )}
//                       </Typography>
//                     </Box>
//                   )}
//                 </Stack>
//               </Stack>

//               {/* PAIN LOG TABLE */}
//               {showPainLogsTable && painTableData?.length !== 0 && (
//                 <TableContainer className={classes.page_break}>
//                   <Table aria-label="simple table">
//                     <TableHead>
//                       <TableRow>
//                         <TableCell width="70px">Sr No</TableCell>
//                         <TableCell width="110px" align="left">
//                           Date
//                         </TableCell>
//                         <TableCell align="left" width="100px">
//                           Time
//                         </TableCell>
//                         <TableCell align="left" width="130px">
//                           Body Part
//                         </TableCell>
//                         <TableCell align="left" width="130px">
//                           Pain Intensity
//                         </TableCell>
//                         <TableCell align="left" width="130px">
//                           Comments
//                         </TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody className={classes.bgColor}>
//                       {painTableData?.map((row, index) => (
//                         <TableRow
//                           key={index}
//                           sx={{
//                             "&:last-child td, &:last-child th": { border: 0 },
//                           }}
//                         >
//                           <TableCell component="th" scope="row">
//                             {row[0]}
//                           </TableCell>
//                           <TableCell align="left">
//                             {dateFormat(row[1])}
//                           </TableCell>
//                           <TableCell align="left">{row[2]}</TableCell>
//                           <TableCell
//                             align="left"
//                             sx={{ textTransform: "capitalize" }}
//                           >
//                             {bodyPainWord(row[3])}
//                           </TableCell>
//                           <TableCell align="left">{row[4]}</TableCell>
//                           <TableCell align="left">{row[5]}</TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//               )}

//               {document_urls?.map((document, index) => {
//                 return (
//                   <Stack className={classes.page_break} key={index}>
//                     <Typography variant="subtitle2" fontSize="20px">
//                       Document Name: {document.name}
//                     </Typography>
//                     <MyPDFViewer file={document.url} />
//                   </Stack>
//                 );
//               })}
//             </Stack>
//           </Stack>
//         </Stack>
//       </div>
//     </div>
//   );
// };

// export default CaseSummaryPdfPage;
