import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import DashboardIcon from "../../icons/DashboardIcon";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
// import Style from "./style";
import MccVerticalLogo from "../../assets/images/mcc-logo-texticon.png";
import Cookies from "js-cookie";
import CasesIcon from "../../icons/CasesIcon";
import ClientsIcon from "../../icons/ClientsIcon";
import MarketingIcon from "../../icons/MarketingIcon";
import ReferralsIcon from "../../icons/ReferralsIcon";
import { useDispatch } from "react-redux";
import {
  changedAgencyIdFunc,
  closeTimeModal,
  openTimeModal,
  resetAllBodyParts,
  resetUserFeedNotifications,
  setEndTime,
  setGoBackFunc,
  setIsRunning,
  setIsStopped,
  setNavigateItemFunc,
  setPausedTime,
  setPauseStartTime,
} from "../../actions/AppConfig";
import IntegrationIcon from "../../icons/IntegrationIcon";
import SettingIcon from "../../icons/SettingIcon";
import {
  resetAllCases,
  resetCaseDetailByCaseId,
  resetClientPainlogs,
} from "../../actions/Cases";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import AlertDialog from "../AlertDialog";
import EndTimeModalContent from "../EndTimeModalContent";
import dayjs from "dayjs";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import PractiseBoxCard from "../PractiseBoxCard";
import {
  AddPractiseIconBlack,
  BillingReceiptIcon,
  TickCircleIcon,
} from "../../assets/constants";
import {
  createWorkspaceFunc,
  getAllAgenciesListFunc,
  inviteAgentFunc,
  resetAgentInvite,
  resetCreateWorkSpace,
} from "../../actions/Agency";
import RectangleSkeletons from "../RectangleSkeletons";
import AddPractiseContent from "../AddPractiseContent";
import { validateEmail } from "../../utils/helpers";
import AlertMessage from "../../common/AlertMessage";
import BillingPageIcon from "../../icons/BillingPageIcon";
import BillingModalContent from "../BillingModalContent";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const dashboardLists = [
  {
    title: "Dashboard",
    icon: <DashboardIcon />,
    to: "/",
  },
  {
    title: "Patients",
    icon: <CasesIcon />,
    to: "/patients",
    alternateName: "/patient",
  },
  // {
  //   title: "Clients",
  //   icon: <ClientsIcon />,
  //   to: "/clients",
  //   alternateName: "/client",
  // },
  // {
  //   title: "Marketing",
  //   icon: <MarketingIcon />,
  //   to: "/marketing?tab=branding",
  //   alternateName: "/marketing",
  // },
  // { title: "Integrations", icon: <IntegrationIcon />, to: "/integrations" },
  { title: "Billing Report", icon: <BillingPageIcon />, to: "/billing-report" },
  {
    title: "Settings",
    icon: <SettingIcon />,
    to: "/settings?tab=general",
    alternateName: "/settings",
    className: "settingBottom",
  },
];

// const Styles = styled((theme) => Style(theme));

const StyledMenuItem = styled(MenuItem)(() => ({
  padding: "16px 28px !important",
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&.activeTab": {
    "&:hover": {
      "& svg path": {
        stroke: "#fff !important",
      },
    },
    "&& .Mui-selected, && .Mui-selected:hover": {
      backgroundColor: "#0C80F3 !important",
      borderRadius: "8px !important",
      "&, & .MuiListItemIcon-root": {
        color: "#fff !important",
      },
    },
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: "26px !important",
}));

const StyledListItemText = styled(ListItemText)(() => ({
  "& span": {
    fontSize: "15px !important",
  },
  "&.activeColor": {
    color: "#fff !important",
  },
}));

const Sidedrawer = ({ drawerWidth, selectedPath, setSelectedPath }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const organizationId = Cookies.get("org_id_vita");

  // console.log("LL", location);

  // const classes = Styles();
  const pathName = location?.pathname;
  const { id } = useParams();
  // const [selectedList, setSelectedList] = useState(
  //   parseInt(localStorage.getItem("selectedIndex")) || 0
  // );
  const dispatch = useDispatch();
  const [goBackTimer, setGoBackTimer] = useState(false);
  const [allPractices, setAllPractices] = useState([]);
  const [openMorePractiseModal, setOpenMorePractiseModal] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [practiseName, setPractiseName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  // const [selectedPath, setSelectedPath] = useState(pathName);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [OpenInviteMessage, setOpenInviteMessage] = useState(false);
  const [successInviteMessage, setSuccessInviteMessage] = useState(null);
  const [openBillingModal, setOpenBillingModal] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenModal(false);
  };

  const timerModal = useSelector((state) => state.timerModal);
  const { isRunning, isPaused, isStopped } = timerModal;

  const getAllAgencies = useSelector((state) => state.getAllAgencies);
  const {
    loading: loadingAllAgencies,
    error: errorAllAgencies,
    allAgencies,
  } = getAllAgencies;

  const agentProfile = useSelector((state) => state.agentProfile);
  const {
    loading: loadingAgentProfile,
    error: errorAgentProfile,
    agentUser,
  } = agentProfile;

  const createWorkspace = useSelector((state) => state.createWorkspace);
  const {
    loading: loadingAgency,
    error: errorAgency,
    workspace,
  } = createWorkspace;

  const inviteAgent = useSelector((state) => state.inviteAgent);
  const {
    loading: loadingAgent,
    error: errorAgent,
    invitedAgent,
  } = inviteAgent;

  const currentAgencyId = Cookies.get("agency_id_vita");

  // useEffect to handle refreshing the page
  // useEffect(() => {
  //   const storedIndex = parseInt(Cookies.get("selectedIndex"));
  //   if (storedIndex !== selectedList) {
  //     setSelectedList(storedIndex || 0);
  //   }
  // }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const switchWorkspaceHandler = (event) => {
    if (!isRunning && !isPaused && isStopped && pathName === `/patient/${id}`) {
      navigate("/patients");
    } else if (!isRunning && !isPaused) {
      setAnchorEl(event.currentTarget);
    } else {
      openStopTimeModalHandler();
      dispatch(setGoBackFunc(true));
    }
  };
  const closeWorkSpaceHandler = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setSelectedPath(pathName);
    let pageTitle = "VitaTrax";
    if (pathName === "/") {
      pageTitle = "VitaTrax | Dashboard";
    } else if (pathName === "/patients") {
      pageTitle = "VitaTrax | Patients";
    } else if (pathName === "/billing-report") {
      pageTitle = "VitaTrax | Billing Report";
    }
    // else if (pathName === "/clients") {
    //   pageTitle = "VitaTrax | Clients";
    // } else if (pathName === "/marketing") {
    //   pageTitle = "VitaTrax | Marketing";
    // } else if (pathName === "/referrals") {
    //   pageTitle = "VitaTrax | Referrals";
    // } else if (pathName === "integrations") {
    //   pageTitle = "VitaTrax | Integrations";
    else if (pathName === "/settings") {
      pageTitle = "VitaTrax | Settings";
    }
    // } else if (pathName === "/profile") {
    //   pageTitle = "VitaTrax | Profile";
    // }

    document.title = pageTitle;
  }, [pathName]);

  // useEffect(() => {
  //   dispatch(openTimeModal());
  // }, [pathName]);
  useEffect(() => {
    dispatch(getAllAgenciesListFunc());
  }, [workspace]);

  useEffect(() => {
    if (allAgencies?.ok) {
      setAllPractices(allAgencies?.agent?.agencies);
    }
  }, [allAgencies]);

  const currentPractiseAgency = allPractices?.find(
    (item) => item?.id === Number(currentAgencyId)
  );

  const openStopTimeModalHandler = () => {
    dispatch(setIsStopped(false));
    dispatch(setGoBackFunc(false));
    dispatch(setEndTime(dayjs()));
    dispatch(openTimeModal());

    // if (!isPaused) {
    //   dispatch(setIsRunning(false));
    //   dispatch(setPauseStartTime(Date.now()));
    //   dispatch(setPausedTime(0));
    // }
    // setSelectValue("chat_communication");
  };

  const listItemHandler = (to) => {
    if (selectedPath === to) {
      return;
    }

    if (to === "/patients") {
      dispatch(resetAllCases());
    }

    if (!isRunning && !isPaused) {
      setSelectedPath(to);
      navigate(`${to}`);
      // dispatch(setEndTime(dayjs()));
      // dispatch(setPauseStartTime(Date.now()));
      // dispatch(setPausedTime(0));
      // dispatch(openTimeModal());
      // dispatch(setNavigateItemFunc(to));
    } else {
      openStopTimeModalHandler();
      dispatch(setNavigateItemFunc(to));
    }
    // dispatch(resetUserFeedNotifications());
    // dispatch(resetClientPainlogs());
    // dispatch(resetAllBodyParts());
    // dispatch(resetCaseDetailByCaseId());
    // dispatch(openTimeModal());
  };

  const openPractiseModalHandler = () => {
    setOpenMorePractiseModal(true);
    closeWorkSpaceHandler();
  };

  const openBillingModalHandler = () => {
    setOpenBillingModal(true);
    closeWorkSpaceHandler();
  };

  const emailChangeHandler = (e) => {
    const emailInputValue = e.target.value;
    setEmail(emailInputValue);

    const emailIsValid = validateEmail(emailInputValue);
    if (emailInputValue && !emailIsValid) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };

  const changePractiseHandler = (practiseId) => {
    Cookies.set("agency_id_vita", practiseId);
    closeWorkSpaceHandler();
  };

  // ================   ADD PRACTICE HANDLER ========================
  const addPracticeHandler = () => {
    const agencyObj = {
      agency: {
        name: practiseName,
        primary_email: email,
      },
      organization_id: organizationId,
    };

    dispatch(createWorkspaceFunc(agencyObj));
    setEmailErrorMsg("");
  };

  useEffect(() => {
    if (!loadingAgency && !workspace?.ok) {
      setEmailErrorMsg(workspace?.message);
      setOpenModal(true);
    }
  }, [workspace]);

  useEffect(() => {
    if (!loadingAgent && !invitedAgent?.ok) {
      setPractiseName("");
      setEmail("");
      setOwnerName("");
      setEmailErrorMsg(invitedAgent?.message);
      setOpenModal(true);
    }
  }, [invitedAgent]);

  useEffect(() => {
    if (workspace?.data?.ok) {
      dispatch(resetCreateWorkSpace());
      const agentObj = {
        agency_id: workspace?.data?.agency?.id,
        send_credentials: true,
        agent_obj: {
          first_name: ownerName,
          email: email,
          role: "Owner",
          permissions: {
            access_patient_details: {
              create: true,
              read: true,
              edit: true,
              delete: true,
            },
            can_chat_client: true,
            can_access_billing: false,
          },
        },
      };
      dispatch(inviteAgentFunc(agentObj));
      setEmailErrorMsg("");
    }
  }, [workspace]);

  useEffect(() => {
    if (invitedAgent?.ok) {
      setOpenInviteMessage(true);
      setSuccessInviteMessage(invitedAgent?.message);
      setPractiseName("");
      setEmail("");
      setOwnerName("");
      setOpenMorePractiseModal(false);
      setEmailError(false);
      setEmailErrorMsg("");
      setOpenMorePractiseModal(false);
      dispatch(resetAgentInvite());
    }
  }, [invitedAgent]);

  // ================   ADD PRACTICE HANDLER ========================

  return (
    <>
      {!loadingAgency && emailErrorMsg && (
        <AlertMessage
          message={emailErrorMsg}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {OpenInviteMessage && (
        <AlertMessage
          message={successInviteMessage}
          severity="success"
          open={OpenInviteMessage}
          handleClose={() => setOpenInviteMessage(false)}
        />
      )}

      {/* OPEN STOP TIME MODAL */}
      {openMorePractiseModal && (
        <AlertDialog
          data={{ title: "Add Practice" }}
          openModal={openMorePractiseModal}
          disabled={!practiseName && !email && !ownerName}
          isLoading={loadingAgency || loadingAgent}
          closeModal={() => {
            setPractiseName("");
            setEmail("");
            setOwnerName("");
            setOpenMorePractiseModal(false);
            setEmailError(false);
          }}
          moveFileDialog={true}
          formSubmitFunction={addPracticeHandler}
          submitText="Add Practice"
          maxWidth="538px"
          titlePadding="24px !important"
          closeOverlay={false}
          padding="24px"
          contentPadding="16px 24px"
          height="668px"
        >
          <AddPractiseContent
            email={email}
            emailChangeHandler={emailChangeHandler}
            emailError={emailError}
            practiseName={practiseName}
            setPractiseName={setPractiseName}
            ownerName={ownerName}
            setOwnerName={setOwnerName}
          />
        </AlertDialog>
      )}

      {/* OPEN BILLING MODAL  */}
      {openBillingModal && (
        <AlertDialog
          data={{
            title: "Billing",
            lightText: "Manage your plan and billing here",
          }}
          openModal={openBillingModal}
          borderRadius="8px"
          closeModal={() => {
            setOpenBillingModal(false);
          }}
          moveFileDialog={true}
          cancelIcon={<HighlightOffOutlinedIcon sx={{ color: "#0E0D1E" }} />}
          maxWidth="1258px"
          titlePadding="24px !important"
          closeOverlay={false}
          padding="24px"
          // contentPadding="16px 24px"
          height="846px"
          dialogActions={false}
          showCancelIcon={true}
        >
          <BillingModalContent allPractices={allPractices} />
        </AlertDialog>
      )}

      <Box>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              padding: "1rem",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          {loadingAllAgencies ? (
            <RectangleSkeletons height={45} totalRows={1} />
          ) : (
            <PractiseBoxCard
              title={currentPractiseAgency?.name}
              open={open}
              switchWorkspaceHandler={switchWorkspaceHandler}
              practiseId={currentPractiseAgency?.id}
              icon={<ExpandMoreRoundedIcon />}
              showEllipsis={true}
              // icon={allPractices?.length > 1 && <ExpandMoreRoundedIcon />}
            />
          )}

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={closeWorkSpaceHandler}
            PaperProps={{
              elevation: 0,
              sx: {
                top: "72px !important",
                overflow: "auto",
                borderRadius: "8px",
                width: "310px",
                boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
                "& .MuiAvatar-root": {
                  // width: "310px",
                  // height: 32,
                  ml: -0.5,
                  // mr: 1,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Stack gap="10px" padding="8px 16px 10px 16px">
              <Typography variant="caption" color="secondary">
                {" "}
                Switch Workspace{" "}
              </Typography>
            </Stack>
            <Stack
              paddingBottom="8px"
              height={allPractices?.length > 5 ? "282px" : "auto"}
              overflow="auto"
            >
              {allPractices?.map((item, index) => (
                <MenuItem
                  onClick={() => {
                    changePractiseHandler(item?.id);
                    dispatch(changedAgencyIdFunc(true));
                  }}
                  key={index}
                >
                  <PractiseBoxCard
                    mb={0}
                    title={item?.name}
                    open={open}
                    // switchWorkspaceHandler={switchWorkspaceHandler}
                    practiseId={item?.id}
                    icon={
                      item?.id === Number(currentAgencyId) && <TickCircleIcon />
                    }
                  />
                </MenuItem>
              ))}
            </Stack>
            <Divider />

            <StyledMenuItem onClick={openPractiseModalHandler}>
              <ListItemIcon sx={{ marginRight: "10px" }}>
                <AddPractiseIconBlack />{" "}
              </ListItemIcon>
              Add Practice
            </StyledMenuItem>
            {(agentUser?.agent?.role === "Organization Admin" || agentUser?.agent?.permissions?.can_access_billing) && (
              <StyledMenuItem onClick={openBillingModalHandler}>
                <ListItemIcon sx={{ marginRight: "10px" }}>
                  <BillingReceiptIcon />{" "}
                </ListItemIcon>
                Billing{" "}
              </StyledMenuItem>
            )}
          </Menu>
          <Divider />

          <List
            sx={{
              height: "100%",
              paddingTop: "24px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {dashboardLists.map((text, index) => (
              <StyledListItem
                key={index}
                disablePadding
                sx={{
                  borderRadius: "8px !important",
                  color: "#566875 !important",
                  "&:hover": {
                    background: index !== 2 && "rgba(12, 128, 243, 0.10)",
                    color: index !== 2 && "#0C80F3 !important",
                    "& svg path": {
                      stroke: index !== 2 && "#0C80F3",
                    },
                  },
                  position: text.className && "absolute",
                  bottom: text.className && "0 !important",
                }}
                className={`${
                  index !== 2 &&
                  (selectedPath === text.to ||
                    selectedPath?.startsWith(text.alternateName))
                    ? "activeTab"
                    : ""
                }`}
              >
                <ListItemButton
                  disableRipple
                  selected={
                    index !== 2 &&
                    (selectedPath === text.to ||
                      selectedPath?.startsWith(text.alternateName))
                  }
                  onClick={() => index !== 2 && listItemHandler(text.to)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                >
                  <StyledListItemIcon>
                    {React.cloneElement(text.icon, {
                      color:
                        selectedPath === text.to ||
                        selectedPath?.startsWith(text.alternateName)
                          ? "#fff"
                          : "#566875",
                    })}
                  </StyledListItemIcon>
                  <StyledListItemText
                    primary={text.title}
                    className={`${
                      selectedPath === text.to ||
                      selectedPath?.startsWith(text.alternateName)
                        ? "activeColor"
                        : ""
                    }`}
                  />
                </ListItemButton>
                {index === 2 && (
                  <Box
                    position="absolute"
                    top="0"
                    right="0"
                    bgcolor="#566875"
                    borderRadius="0px 0px 8px 8px"
                    padding="0 4px"
                  >
                    <Typography variant="caption" color="#fff">
                      Coming Soon
                    </Typography>
                  </Box>
                )}
              </StyledListItem>
            ))}
          </List>
        </Drawer>
      </Box>
    </>
  );
};

export default Sidedrawer;
