import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { onlyDateFormat } from "../../utils/helpers";

const StepCountDetailCard = ({ stepCount, date, distance }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="12px"
      bgcolor="#FCFCFC"
      borderRadius="8px"
      gap="8px"
    >
      <Stack>
        <Typography variant="body2" color="secondary" fontWeight={400}>
          Date
        </Typography>
        <Typography variant="caption" color="#212121" fontWeight={400}>
          {onlyDateFormat(date)}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="body2" color="secondary" fontWeight={400}>
          Step Count{" "}
        </Typography>
        <Typography variant="body1" fontWeight={500} fontSize="18px">
          {stepCount}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="body2" color="secondary" fontWeight={400}>
          Distance{" "}
        </Typography>
        <Typography variant="caption" color="#212121" fontWeight={400}>
          {distance}
        </Typography>
      </Stack>
    </Box>
  );
};

export default StepCountDetailCard;
