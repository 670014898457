import {
  ADD_DIAGNOSIS_CODE_FAIL,
  ADD_DIAGNOSIS_CODE_REQUEST,
  ADD_DIAGNOSIS_CODE_SUCCESS,
  REMOVE_DIAGNOSIS_CODE_FAIL,
  REMOVE_DIAGNOSIS_CODE_REQUEST,
  REMOVE_DIAGNOSIS_CODE_SUCCESS,
  RESET_REMOVE_DIAGNOSIS_CODE,
  GET_DIAGNOSIS_OPTIONS_FAIL,
  GET_DIAGNOSIS_OPTIONS_REQUEST,
  GET_DIAGNOSIS_OPTIONS_SUCCESS,
  RESET_ADD_DIAGNOSIS_CODE,
} from "../../assets/constants/diagnosisConstants";

// GET DIAGNOSIS OPTIONS REDUCER
export const getDiagnosisOptionsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DIAGNOSIS_OPTIONS_REQUEST:
      return { loading: true };
    case GET_DIAGNOSIS_OPTIONS_SUCCESS:
      return { loading: false, diagnosisOptions: action.payload };
    case GET_DIAGNOSIS_OPTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// ADD DIAGNOSIS CODE REDUCER
export const addDiagnosisCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_DIAGNOSIS_CODE_REQUEST:
      return { loading: true };
    case ADD_DIAGNOSIS_CODE_SUCCESS:
      return { loading: false, diagnosisCodeAdded: action.payload };
    case ADD_DIAGNOSIS_CODE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ADD_DIAGNOSIS_CODE:
      return {};
    default:
      return state;
  }
};

// REMOVE DIAGNOSIS CODE REDUCER
export const removeDiagnosisCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_DIAGNOSIS_CODE_REQUEST:
      return { loading: true };
    case REMOVE_DIAGNOSIS_CODE_SUCCESS:
      return { loading: false, removedDiagnosisCode: action.payload };
    case REMOVE_DIAGNOSIS_CODE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_REMOVE_DIAGNOSIS_CODE:
      return {};
    default:
      return state;
  }
};
