// import React, { useEffect, useState } from "react";

// import clsx from "clsx";
// import PropTypes from "prop-types";
// import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";

// import Style from "./style";
// import {
//   BrandingIcon,
//   EmailMarketingIcon,
//   PushNotificationIcon,
// } from "../../assets/constants";
// import Branding from "./Branding";
// import EmailMarketing from "./EmailMarketing";
// import PushNotifications from "./PushNotifications";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { getAgencyDetailFunc } from "../../actions/Agency";
// import { segmentTrack } from "../../utils/helpers/tracking_utils";
// import AlertDialog from "../../components/AlertDialog";

// const allTabs = [
//   {
//     icon: <BrandingIcon />,
//     title: "Branding",
//     value: "branding",
//   },
//   {
//     icon: <PushNotificationIcon />,
//     title: "Push Notifications",
//     value: "push_notifications",
//   },
//   {
//     icon: <EmailMarketingIcon />,
//     title: "Email Marketing",
//     value: "email_marketing",
//   },
// ];

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ pt: 3, height: "100%" }}>
//           <>{children}</>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// const Styles = makeStyles((theme) => Style(theme));

// export default function Marketing() {
//   const dispatch = useDispatch();
//   const [activeTab, setActiveTab] = useState("branding");
//   const [openLeavePageBox, setOpenLeavePageBox] = useState(false);
//   const [modalContentData, setModalContentData] = useState({});
//   const [newTabValue, setNewTabValue] = useState(null);
//   const [changesMade, setChangesMade] = useState(false);

//   const [searchParams] = useSearchParams();
//   const selectedTab = searchParams.get("tab") || "branding";

//   const navigate = useNavigate();

//   const handleChange = (event, newValue) => {
//     if (changesMade && newValue !== "branding") {
//       setOpenLeavePageBox(true);
//       setModalContentData({
//         title: "Changes Require Publishing",
//         subtitle: `Your changes won't be saved unless you hit the "Publish Changes" button. Be sure to click it to keep your modifications!`,
//       });
//       setNewTabValue(newValue);
//     } else {
//       searchParams.set("tab", newValue);
//       navigate({ search: `?${searchParams.toString()}` });
//       setActiveTab(newValue);
//     }
//   };

//   const leavePageHandler = () => {
//     setChangesMade(false);
//     searchParams.set("tab", newTabValue);
//     navigate({ search: `?${searchParams.toString()}` });
//     setActiveTab(newTabValue);
//     modalCloseHandler();
//   };

//   const agentProfile = useSelector((state) => state.agentProfile);
//   const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

//   const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
//   const {
//     loading: loadingAgency,
//     error: errorAgency,
//     agencyDetail,
//   } = getAgencyDetail;

//   useEffect(() => {
//     if (agentUser?.agent && agencyDetail?.agency) {
//       segmentTrack({
//         event: "marketing",
//         agent: agentUser?.agent,
//         agency: agencyDetail?.agency,
//       });
//     }
//   }, [agentUser, agencyDetail]);

//   const modalCloseHandler = () => {
//     setOpenLeavePageBox(false);
//   };

//   // useEffect(() => {
//   //   dispatch(getAgencyDetailFunc());
//   // }, []);

//   useEffect(() => {
//     if (selectedTab === "branding") {
//       setActiveTab("branding");
//     } else if (selectedTab === "push_notifications") {
//       setActiveTab("push_notifications");
//     } else {
//       setActiveTab("email_marketing");
//     }
//   }, [selectedTab]);

//   const classes = Styles();
//   return (
//     <>
//       {openLeavePageBox && (
//         <AlertDialog
//           openModal={openLeavePageBox}
//           closeModal={modalCloseHandler}
//           data={modalContentData}
//           submitText="Leave Page"
//           formSubmitFunction={leavePageHandler}
//           color="error"
//         />
//       )}
//       <Stack>
//         <Typography variant="h5">Marketing</Typography>
//         <Stack pt={2}>
//           <Tabs
//             className={clsx(classes.tabs__bar)}
//             value={activeTab}
//             onChange={handleChange}
//             aria-label="basic tabs example"
//           >
//             {allTabs?.map((tab, index) => (
//               <Tab
//                 iconPosition="start"
//                 icon={tab.icon}
//                 label={tab.title}
//                 key={index}
//                 sx={{ minHeight: "0 !important" }}
//                 disableRipple
//                 value={tab.value}
//                 // to={`/marketing?tab=${tab.value}`}
//                 // {...a11yProps(key++)}
//               />
//             ))}
//           </Tabs>
//           {/* <TabPanel
//           value={activeTab}
//           index={activeTab}
//           className={clsx(classes.tab__panel)}
//         >
//           {allTabs[activeTab].component}
//         </TabPanel> */}
//         </Stack>

//         {/* TABS CONTENT  */}
//         {/* TAB 1 OVERVIEW SECTION  */}
//         <Stack pt={3}>
//           {selectedTab === "email_marketing" && <EmailMarketing />}
//           {selectedTab === "push_notifications" && <PushNotifications />}
//           {selectedTab === "branding" && (
//             <Branding
//               changesMade={changesMade}
//               setChangesMade={setChangesMade}
//             />
//           )}
//         </Stack>
//       </Stack>
//     </>
//   );
// }
