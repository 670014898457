import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { dateTimeFormat, onlyDateFormat } from "../../utils/helpers";

const OverlapTimeEntryCard = ({
  heading,
  notesValue,
  startTime,
  endTime,
  date,
}) => {
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="space-between"
      bgcolor="#FFE1E1"
      gap="12px"
      padding="8px 16px"
      borderRadius="4px"
    >
      <Stack width="265px">
        <Typography variant="body1" fontWeight={500}>
          {heading}
        </Typography>
        <Typography variant="caption" color="secondary">
          {notesValue}
        </Typography>
      </Stack>
      <Stack width="79px">
        <Typography variant="body2" color="secondary">
          Start time{" "}
        </Typography>
        <Typography variant="caption" color="#212121">
          {dayjs(startTime)?.format("hh:mm A")}
        </Typography>
      </Stack>
      <Stack width="80px">
        <Typography variant="body2" color="secondary">
          End Time{" "}
        </Typography>
        <Typography variant="caption" color="#212121">
          {dayjs(endTime)?.format("hh:mm A")}
        </Typography>
      </Stack>
      <Stack width="80px">
        <Typography variant="body2" color="secondary">
          Date
        </Typography>
        <Typography variant="caption" color="#212121">
          {onlyDateFormat(date)}
        </Typography>
      </Stack>
    </Box>
  );
};

export default OverlapTimeEntryCard;
