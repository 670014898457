import { Avatar, Box, Chip, Stack, Typography } from "@mui/material";
import React from "react";
import {
  dateTimeFormat,
  getNotesBackgroundColor,
  getNotesColor,
} from "../../utils/helpers";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const NoteLogsCard = ({
  time,
  startTime,
  endTime,
  type,
  notesText,
  agentId,
}) => {
  const getAgentsList = useSelector((state) => state.getAgentsList);
  const { allAgents } = getAgentsList;

  const agentDetail = allAgents?.agents_list?.find(
    (item) => item?.id === agentId
  );

  // console.log("DETA", agentDetail);

  return (
    <Stack
      gap="7px"
      border="1px solid #EBEBEB"
      bgcolor="#FCFCFC"
      padding="16px"
      borderRadius="8px"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap="7px">
          <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
            {dateTimeFormat(time)}
          </Typography>
          <Stack gap="4px" direction="row" alignItems="center">
            <Avatar
              alt="name"
              sx={{ width: 16, height: 16 }}
              src={agentDetail?.profile_picture_url}
            />
            <Typography variant="body2">{agentDetail?.name}</Typography>
          </Stack>
          {/* {type === "timer"}  */}

          <Chip
            size="small"
            label={
              type === "time_entry"
                ? `Session - ${dayjs(startTime)?.format("hh:mm A")} to ${dayjs(
                    endTime
                  )?.format("hh:mm A")}`
                : type === "custom_note"
                ? "Custom Notes"
                : "Enrollment"
            }
            // color={getNotesColor(type)}
            sx={{
              color: getNotesColor(type),
              bgcolor: getNotesBackgroundColor(type),
              border: "none",
            }}
            variant="outlined"
          />
        </Box>
        {/* MOREVERT ICON  */}
      </Box>
      <Typography variant="body2">{notesText}</Typography>
    </Stack>
  );
};

export default NoteLogsCard;
