import { Box, Stack, Toolbar, Typography, createTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import NavbarUI from "../NavbarUI";
import Sidedrawer from "../SideDrawer";
import styled from "@emotion/styled";
import AlertDialog from "../AlertDialog";
import EndTimeModalContent from "../EndTimeModalContent";
import { useSelector } from "react-redux";
import {
  closeTimeModal,
  elpasedTimeFunc,
  resetAllBodyParts,
  resetAllNotifications,
  resetTimerItemsFunc,
  resetUserFeedNotifications,
  setGoBackFunc,
  setIsPaused,
  setIsRunning,
  setIsStopped,
  setLogoutClickedFunc,
  setNavigateItemFunc,
  setPausedTime,
  setPauseStartTime,
  setPreviousPageFunc,
  timeChangedFunc,
} from "../../actions/AppConfig";
import { useDispatch } from "react-redux";
import AlertMessage from "../../common/AlertMessage";
import { logout } from "../../actions/Auth";
import {
  getCaseDetailByCaseId,
  resetAllCases,
  resetCaseClientDocs,
  resetUnpaginatedCases,
} from "../../actions/Cases";
import { resetStepCounts } from "../../actions/StepsCountActions";
import { resetGetBillingSubscription } from "../../actions/BillingSubscription";
import { resetAgentsList } from "../../actions/Agency";
import dayjs from "dayjs";
import {
  createTimeEntryFunc,
  getNotesFunc,
  getTimeEntryFunc,
  resetCreateTimeEntry,
} from "../../actions/TimeEntry";
import { calculateElapsedTime } from "../../utils/helpers";
import OverlapTimeEntryCard from "../OverlapTimeEntryCard";

const drawerWidth = "255px";
const StyledBox = styled(Box)(() => ({
  display: "flex",
  overflow: "auto",
  height: "100%",
  // background: "green",
}));

const StyledPage = styled(Box)(() => ({
  width: "100%",
  // padding: "20px 40px",
  // paddingBottom: "40px",
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  toolbar: theme.mixins.toolbar,
}));

const AppLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const agencyId = Cookies.get("agency_id_vita");

  const [selectedPath, setSelectedPath] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showTimeEntryModal, setShowTimeEntryModal] = useState(false);
  const [endTimeError, setEndTimeError] = useState(false);
  const [overlapTimeEntries, setOverlapTimeEntries] = useState([]);

  const createTimeEntry = useSelector((state) => state.createTimeEntry);
  const {
    loading: loadingCreateTimeEntry,
    error,
    createdTimeEntry,
  } = createTimeEntry;

  const timerModal = useSelector((state) => state.timerModal);
  const {
    isRunning,
    openModal,
    elapsedTime,
    goBackClicked,
    pausedStartTime,
    pausedTime,
    navigateItem,
    isPaused,
    isStopped,
    logoutClicked,
    startTimeValue,
    endTimeValue,
    logTypeValue,
    notesValue,
    communicationInteraction,
    patientId,
    timeChanged,
  } = timerModal;

  const saveTimerHandler = () => {
    if (
      endTimeValue &&
      startTimeValue &&
      endTimeValue?.isBefore(startTimeValue)
    ) {
      setEndTimeError(true);
      return;
    }

    setEndTimeError(false);

    const timeEntryObj = {
      patient_id: patientId,
      agency_id: agencyId,
      date: dayjs()?.utcOffset(0, true),
      start_time: startTimeValue,
      end_time: endTimeValue,
      time_in_seconds: timeChanged
        ? calculateElapsedTime(startTimeValue, endTimeValue)
        : Math.floor(elapsedTime / 1000),
      category: logTypeValue,
      notes: notesValue,
      contains_interaction: communicationInteraction,
    };

    dispatch(createTimeEntryFunc(timeEntryObj));
  };

  useEffect(() => {
    if (createdTimeEntry?.ok) {
      setEndTimeError(false);
      // dispatch(
      //   getTimeEntryFunc(
      //     patientId,
      //     dayjs().subtract(31, "day").format("YYYY-MM-DD"),
      //     dayjs().format("YYYY-MM-DD")
      //   )
      // );
      dispatch(resetCreateTimeEntry());
      dispatch(setIsRunning(false));
      dispatch(setIsPaused(false));
      dispatch(setIsStopped(true));
      dispatch(timeChangedFunc(false));

      // dispatch(getCaseDetailByCaseId(patientId));
      // const pauseDuration = Date.now() - pausedStartTime;
      // dispatch(setPausedTime(pausedTime + pauseDuration));
      // dispatch(setPauseStartTime(null));
      dispatch(elpasedTimeFunc(0));
      dispatch(closeTimeModal());
      // setShowSuccessMessage(true);
      // dispatch(setPreviousPageFunc(true));
      if (navigateItem === "/") {
        navigate("/");
        setSelectedPath(navigateItem);
      }
      if (navigateItem === "/patients") {
        navigate("/patients");
        setSelectedPath(navigateItem);
      }
      if (navigateItem === "/settings?tab=general") {
        navigate("/settings?tab=general");
        setSelectedPath(navigateItem);
      }
      if (navigateItem === "/profile") {
        navigate("/profile");
      }
      if (goBackClicked) {
        navigate("/patients");
        return;
      }
    } else if (error?.message) {
      setOverlapTimeEntries(error?.overlapEntries);
      if (isPaused) {
        dispatch(setIsRunning(false));
        dispatch(setIsPaused(true));
        dispatch(setIsStopped(false));
      }

      setShowErrorMessage(true);
    }
  }, [createdTimeEntry, error]);

  const resetTimerHandler = () => {
    dispatch(setIsRunning(false));
    dispatch(setIsPaused(false));
    dispatch(setIsStopped(true));
    dispatch(setPreviousPageFunc(false));
    dispatch(timeChangedFunc(false));
    dispatch(elpasedTimeFunc(0));
    dispatch(closeTimeModal());
    setShowTimeEntryModal(false);
    dispatch(resetCreateTimeEntry());

    if (goBackClicked) {
      navigate("/patients");
      dispatch(setGoBackFunc(false));
      return;
    }
    if (navigateItem === "/") {
      navigate("/");
      setSelectedPath(navigateItem);
      return;
    }

    if (navigateItem === "/profile") {
      navigate("/profile");
      setSelectedPath(navigateItem);
      return;
    }

    if (navigateItem === "/patients") {
      navigate("/patients");
      setSelectedPath(navigateItem);
      return;
    }

    if (navigateItem === "/integrations") {
      navigate("/integrations");
      setSelectedPath(navigateItem);
      return;
    }
    if (navigateItem === "/billing-report") {
      navigate("/billing-report");
      setSelectedPath(navigateItem);
      return;
    }

    if (navigateItem === "/settings?tab=general") {
      navigate("/settings");
      setSelectedPath(navigateItem);
      return;
    }

    if (logoutClicked) {
      dispatch(logout());
      dispatch(resetUserFeedNotifications());
      dispatch(resetAllNotifications());
      dispatch(resetAllCases());
      dispatch(resetCaseClientDocs());
      dispatch(resetAllBodyParts());
      dispatch(resetUnpaginatedCases());
      dispatch(resetAgentsList());
      dispatch(resetGetBillingSubscription());
      dispatch(resetStepCounts());
      dispatch(resetTimerItemsFunc());
      return;
    }
  };

  const showDiscardTimeEntryModalHandler = () => {
    setShowTimeEntryModal(true);
  };

  return (
    <>
      {/* OPEN STOP TIME MODAL */}
      {openModal && (
        <AlertDialog
          data={{ title: "End Session" }}
          openModal={openModal}
          isLoading={loadingCreateTimeEntry}
          showDiscardTimeEntryModalHandler={showDiscardTimeEntryModalHandler}
          closeModal={() => {
            setEndTimeError(false);
            // if (goBackTimer) {
            // navigate("/patients");
            //   setIsRunning(false);
            //   setIsPaused(false);
            // } else {
            //   setIsRunning(true);
            //   setIsPaused(false);
            //   window.history.pushState({ showModal: false }, "");
            // dispatch(setIsRunning(true));
            if (!isPaused) {
              dispatch(setIsRunning(true));
              const pauseDuration = Date.now() - pausedStartTime;
              dispatch(setPausedTime(pausedTime + pauseDuration));
              dispatch(setPauseStartTime(null));
            } else {
              dispatch(setIsPaused(true));
            }
            dispatch(closeTimeModal());
            dispatch(setGoBackFunc(false));
            dispatch(setLogoutClickedFunc(false));
            dispatch(setNavigateItemFunc(null));

            // }
          }}
          moveFileDialog={true}
          formSubmitFunction={saveTimerHandler}
          submitText="Save"
          cancelText={goBackClicked ? "Discard and Leave" : "Discard"}
          maxWidth="444px"
          titlePadding="16px 24px 0 24px !important"
          titlePaddingTop="16px"
          closeOverlay={false}
          showCancelIcon={true}
          resetTime={true}
        >
          <EndTimeModalContent
            setEndTimeError={setEndTimeError}
            endTimeError={endTimeError}
          />
        </AlertDialog>
      )}

      {showSuccessMessage && (
        <AlertMessage
          message="Time log added successfully"
          severity="success"
          open={showSuccessMessage}
          handleClose={() => setShowSuccessMessage(false)}
        />
      )}

      {showErrorMessage && (
        <AlertMessage
          width="682px"
          severity="error"
          open={true}
          autoHideDurationTime={60000}
          handleClose={() => setShowErrorMessage(false)}
          textAlign="normal"
        >
          <Stack gap="14px">
            <Stack>
              <Typography variant="body1" fontWeight={500} color="#5F2120">
                Session can't be saved
              </Typography>
              <Typography variant="body2" color="#5F2120">
                {error?.message}{" "}
              </Typography>
            </Stack>
            <Stack
              gap="8px"
              height={overlapTimeEntries?.length <= 3 ? "auto" : "190px"}
              overflow="auto"
            >
              {overlapTimeEntries?.map((item, index) => (
                <OverlapTimeEntryCard
                  key={index}
                  heading={item?.category}
                  notesValue={item?.notes}
                  startTime={item?.start_time}
                  endTime={item?.end_time}
                />
              ))}
            </Stack>
          </Stack>{" "}
        </AlertMessage>
      )}

      {showTimeEntryModal && (
        <AlertDialog
          submitID="confirm-delete-time-entry"
          maxWidth="400px"
          openModal={showTimeEntryModal}
          closeModal={() => setShowTimeEntryModal(false)}
          data={{
            title: "Are you sure you want to discard this time entry?",
          }}
          formSubmitFunction={resetTimerHandler}
          color="error"
          fontWeight="400"
        />
      )}

      <StyledBox>
        {/* APP BAR */}

        <NavbarUI drawerWidth={drawerWidth} />

        {/* SIDE DRAWER */}

        <Sidedrawer
          drawerWidth={drawerWidth}
          selectedPath={selectedPath}
          setSelectedPath={setSelectedPath}
        />

        <StyledPage>
          <StyledToolbar></StyledToolbar>
          <Box height="calc(100vh - 64px)">
            <Outlet />
          </Box>
        </StyledPage>
      </StyledBox>
    </>
  );
};

export default AppLayout;
