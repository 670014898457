import React from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import companyLogo from "../../assets/images/company-logo.jpg";
import {
  dateTimeFormat,
  getNotificationText,
  timeAgo,
} from "../../utils/helpers";

// HERE
const lightTextStyle = { color: "rgba(0, 0, 0, 0.38)" };
// const useStyles = styled((theme) => ({
//   lightText: {
//     color: "rgba(0, 0, 0, 0.38)",
//   },
// }));

const UserFeedCard = ({ data }) => {
  // const classes = useStyles();

  const displayText = getNotificationText(data, lightTextStyle);

  return (
    <Box
      display="flex"
      gap="12px"
      padding="10px"
      bgcolor="#F4F6F8"
      borderRadius="8px"
    >
      <Avatar
        sx={{ width: "30px", height: "30px" }}
        src={data?.client?.profile_picture_url}
        alt={data?.client?.name}
      />
      <Stack>
        <Typography variant="body2" fontSize="16px">
          {data?.activity_performed_by}{" "}
          {data?.log_type === "chat_activity" ? (
            <>
              <span style={lightTextStyle}>replied in chat</span>{" "}
            </>
          ) : (
            displayText
          )}
        </Typography>
        <Typography variant="caption" style={lightTextStyle}>
          {timeAgo(data?.created_at)}
        </Typography>
      </Stack>
    </Box>
  );
};

export default UserFeedCard;
