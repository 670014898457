import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import RectangleSkeletons from "../RectangleSkeletons";

const AlertDialog = ({
  submitID,
  data,
  openModal,
  closeModal,
  formSubmitFunction,
  submitText = "Yes",
  maxWidth = "xs",
  children,
  padding = "8px",
  isLoading = false,
  moveFileDialog = false,
  caseSummary = false,
  color = "primary",
  cancelColor = "#000",
  disabled = false,
  contentPadding,
  titlePadding,
  titlePaddingTop = "16px",
  contentPaddingTop = "16px !important",
  actionsPaddingTop,
  cancelText = "Cancel",
  closeOverlay = true,
  showCancelIcon = false,
  showDiscardTimeEntryModalHandler,
  resetTime = false,
  loadingConsent = false,
  fontWeight = "500 !important",
  height,
  borderRadius = "4px",
  dialogActions = true,
  cancelIcon = <CloseIcon />,
  titleFontSize = "20px",
}) => {
  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  return (
    <Dialog
      open={openModal}
      maxWidth={maxWidth}
      fullWidth={true}
      onClose={closeOverlay ? closeModal : null}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: borderRadius,
          width: "100%",
          maxWidth: `${maxWidth} !important`,
          height: height && height,
        },
      }}
    >
      {loadingConsent ? (
        <Stack padding="18px">
          <RectangleSkeletons height={20} totalRows={1} width="50%" />
        </Stack>
      ) : (
        <DialogTitle
          sx={{
            padding: titlePadding && titlePadding,
            paddingBottom: moveFileDialog ? "32px" : "0 !important",
            paddingTop: titlePaddingTop,
          }}
          id="alert-dialog-title"
          fontSize="20px"
        >
          {caseSummary ? (
            <Stack gap="4px">
              <Typography variant="h6" fontSize="20px">
                {data?.title}
              </Typography>

              <Typography
                variant="body2"
                fontSize="1rem"
                color="rgba(0, 0, 0, 0.38)"
              >
                {data?.lightText}
              </Typography>
            </Stack>
          ) : (
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Stack lineHeight="24px" fontWeight={fontWeight}>
                <Typography variant="h5" fontSize={titleFontSize}>
                  {data?.title}
                </Typography>{" "}
                {data?.lightText && (
                  <Typography
                    component="span"
                    style={{ fontWeight: "400 !important" }}
                    color="secondary"
                  >
                    {data?.lightText}
                  </Typography>
                )}
              </Stack>
              {showCancelIcon && (
                <IconButton onClick={closeModal}>{cancelIcon}</IconButton>
              )}
            </Box>
          )}
        </DialogTitle>
      )}
      <DialogContent
        sx={{
          padding: contentPadding && contentPadding,
          paddingTop: contentPaddingTop,
        }}
      >
        {data?.subtitle ? (
          <DialogContentText
            id="alert-dialog-description"
            color="rgba(0, 0, 0, 0.87)"
          >
            {data?.subtitle}
          </DialogContentText>
        ) : (
          children
        )}
      </DialogContent>
      {dialogActions && (
        <DialogActions sx={{ padding: padding, paddingTop: actionsPaddingTop }}>
          <Button
            sx={{ color: cancelColor }}
            onClick={resetTime ? showDiscardTimeEntryModalHandler : closeModal}
          >
            {cancelText}
          </Button>
          <LoadingButton
            id={submitID}
            disabled={disabled}
            loading={isLoading}
            variant="contained"
            onClick={formSubmitFunction}
            color={color}
          >
            {submitText}
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AlertDialog;
