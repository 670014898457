import React, { useState } from "react";
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { DocCircle, FolderIcon } from "../../assets/constants";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EmptyBox from "../../assets/images/empty-box.png";
import CircularProgressLoading from "../../common/CircularProgress";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FsLightbox from "fslightbox-react";
import { checkFileExtension, isSupportedType } from "../../utils/helpers";
import Button from "../../common/Button";
import DocumentFolderIcon from "../../icons/DocumentFolderIcon";
import DocumentGridViewCard from "../DocumentGridViewCard";
import WaitingClockIcon from "../../icons/WaitingClockIcon";
import SkeletonGrid from "../SkeletonGrid";
import DocumentMenuItems from "../DocumentMenuItems";

const StyledStack = styled(Stack)(({ theme }) => ({
  position: "relative",
  padding: "1rem",
  borderRadius: "8px",
  border: "1px solid #EBEBEB",
  background: "#fff",
  gap: "8px",
  width: "auto",
}));

const GridViewLayout = ({
  documentsRequest,
  documentRequestTitle,
  fromClientPage = false,
  loading,
  docsLoading,
  documents,
  toggleDrawer,
  setDrawerOpen,
  setSelectedDocument,
  selectedDocument,
  handleTitleClick,
  openDocumentMenu,
  openRequestDocMenu,
  handleDocumentClick,
  handleRequestDocClick,
  handleDownloadClick,
  setMenuFileIndex,
  setMenuFileDownloadURLs,
  toggler,
  lightboxSources,
  anchorDocumentEl,
  anchorRequestDocEl,
  handleDocumentClose,
  documentDeleteHandler,
  renameDocumentHandler,
  moveToNewFolderFunction,
  moveFileDialogHandler,
  selectedCheckedDoc,
  addMoreDocumentsHandler,
  fsLightBoxViewHandler,
  changeCheckBoxHandler,
  handleBoxClick,
  sendRequestDocReminderHandler,
  cancelDocRequestHandler,
  downloadImgAsPdfHandler,
  imageAsPdfRef,
}) => {
  // console.log("DOCUMENTS", documentsRequest);
  // console.log("LOADING", loading);
  // console.log("LOADING", docsLoading);
  // console.log("SELECTED", selectedDoc);

  return (
    <Stack gap="24px" height="100%">
      {/* REQUEST DOCUMENTS CENTER */}
      {!loading && !docsLoading && documentsRequest?.length > 0 ? (
        <Stack gap="8px">
          <Typography variant="subtitle2" color="secondary">
            Requested Documents
          </Typography>

          <Grid container spacing={3}>
            {documentsRequest?.map((doc, i) => (
              <Grid
                item
                key={i}
                xs={12}
                sm={fromClientPage ? 12 : 4}
                md={fromClientPage ? 6 : 4}
                lg={fromClientPage ? 6 : 3}
              >
                <StyledStack>
                  <Box
                    width="31px"
                    height="31px"
                    bgcolor="#fff"
                    borderRadius="8px"
                  >
                    <WaitingClockIcon />
                  </Box>
                  <Stack>
                    <Box component="a">
                      <Typography
                        variant="boxHeading"
                        color="disabled.customs"
                        sx={{
                          // cursor: "pointer",
                          textDecoration: "none",
                          // "&:hover": {
                          //   textDecoration: "underline",
                          // },
                        }}
                      >
                        {doc?.document_title}
                      </Typography>
                    </Box>
                    <Typography pb={1} variant="caption" color="#ED6C02">
                      Waiting for client to upload.
                    </Typography>
                  </Stack>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="8px"
                    justifyContent="space-between"
                  >
                    <Chip
                      label={
                        doc?.document_type === "client_level" //doc?.document_type
                          ? "Personal"
                          : fromClientPage
                          ? documentRequestTitle(doc)
                          : documentRequestTitle
                      }
                      size="small"
                      sx={{
                        alignSelf: "start",
                        marginLeft: 0,
                        padding: "4px",
                        color: `${
                          doc?.document_type === "client_level"
                            ? "#2196F3"
                            : "#3DC583"
                        }`,
                        background: `${
                          doc?.document_type === "client_level"
                            ? "rgba(33, 150, 243, 0.15)"
                            : "rgba(61, 197, 131, 0.15)"
                        }`,
                      }}
                    />
                    <IconButton
                      sx={{ padding: "0" }}
                      id="document-request"
                      aria-controls={
                        openRequestDocMenu ? "document-request" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openRequestDocMenu ? "true" : undefined}
                      onClick={(e) => {
                        setSelectedDocument(doc);
                        handleRequestDocClick(e);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                </StyledStack>
              </Grid>
            ))}
          </Grid>

          <Stack gap="8px">
            <Menu
              id="document-request"
              anchorEl={anchorRequestDocEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openRequestDocMenu}
              onClose={handleDocumentClose}
              MenuListProps={{
                "aria-labelledby": "document-request",
              }}
            >
              <MenuItem onClick={sendRequestDocReminderHandler}>
                Send Reminder
              </MenuItem>
              <MenuItem
                sx={{ color: "#FF2D2D" }}
                onClick={cancelDocRequestHandler}
              >
                Cancel Request
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>
      ) : (
        <></>
      )}

      {/* UPLOADED DOCUMENTS CENTER */}

      {!loading && !docsLoading && documents?.length === 0 ? (
        <Stack alignItems="center" justifyContent="center" height="100%">
          <Avatar
            src={EmptyBox}
            alt="coming soon"
            sx={{
              width: "128px",
              height: "128px",
              paddingBottom: "24px",
            }}
          />
          <Typography variant="body1" color="secondary" pb={2}>
            All documents will show up here. You can also send document request
            to clients
          </Typography>
          <Button
            startIcon={<AddRoundedIcon />}
            title="Add documents"
            color="primary"
            variant="contained"
            onClick={() => {
              toggleDrawer("right", true);
              setDrawerOpen(true);
            }}
          />
        </Stack>
      ) : docsLoading || loading ? (
        <SkeletonGrid itemCount={8} height={200} sm={6} md={4} spacing={3} />
      ) : (
        <Stack gap="8px">
          <Typography variant="subtitle2" color="secondary">
            Uploaded Documents
          </Typography>

          {/* <Box className={classes.doc_container}> */}
          {/*  */}
          <Grid container spacing={3}>
            {documents?.map((doc, i) => {
              return (
                <React.Fragment key={i}>
                  <DocumentGridViewCard
                    fromClientPage={fromClientPage}
                    handleTitleClick={handleTitleClick}
                    handleBoxClick={handleBoxClick}
                    changeCheckBoxHandler={changeCheckBoxHandler}
                    selectedCheckedDoc={selectedCheckedDoc}
                    openDocumentMenu={openDocumentMenu}
                    handleDocumentClick={handleDocumentClick}
                    setMenuFileIndex={setMenuFileIndex}
                    setMenuFileDownloadURLs={setMenuFileDownloadURLs}
                    setSelectedDocument={setSelectedDocument}
                    doc={doc}
                    index={i}
                    isSelected={selectedCheckedDoc?.includes(doc?.id)}
                    isFolder={doc?.type === "folder"}
                    fileName={doc?.file_name}
                    blob_name={doc?.blob_name}
                    fileUrl={doc?.file_url}
                    fileTitle={
                      doc?.type === "folder" ? doc?.name : doc?.file_name
                    }
                    numOfFiles={doc?.number_of_children}
                    fileSize={doc?.file_size}
                    documentType={
                      doc?.file_type === "client_level" ||
                      doc?.folder_type === "client_level"
                        ? "Personal"
                        : fromClientPage
                        ? documentRequestTitle(doc)
                        : documentRequestTitle
                    }
                    fsLightBoxViewHandler={fsLightBoxViewHandler}
                    fromInnerFolderView={false}
                    imageAsPdfRef={imageAsPdfRef}
                  />
                </React.Fragment>
              );
            })}
          </Grid>

          <Stack gap="8px">
            <Stack>
              <FsLightbox toggler={toggler} sources={lightboxSources} />
              <DocumentMenuItems
                anchorDocumentEl={anchorDocumentEl}
                openDocumentMenu={openDocumentMenu}
                handleDocumentClose={handleDocumentClose}
                addMoreDocumentsHandler={addMoreDocumentsHandler}
                selectedDocument={selectedDocument}
                moveFileDialogHandler={moveFileDialogHandler}
                moveToNewFolderFunction={moveToNewFolderFunction}
                renameDocumentHandler={renameDocumentHandler}
                handleDownloadClick={handleDownloadClick}
                documentDeleteHandler={documentDeleteHandler}
                downloadImgAsPdfHandler={downloadImgAsPdfHandler}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default GridViewLayout;

// <Stack
//   key={i}
//   component="a"
//   p={1.5}
//   onClick={() => handleTitleClick(doc.files)}
//   direction="row"
//   alignItems="flex-end"
//   justifyContent="space-between"
//   sx={{ background: "#F4F6F8", borderRadius: "8px" }}
// >
//   <Stack direction="row" alignItems="center">
//     <Box
//       width="48px"
//       height="48px"
//       bgcolor="#fff"
//       borderRadius="8px"
//     >
//       {doc.files.length > 1 ? (
//         <FolderIcon className={classes.icon} />
//       ) : (
//         <img
//           src={`${doc.icon}`}
//           alt={doc.title}
//           className={classes.icon}
//           width="100%"
//           height="100%"
//         />
//       )}
//     </Box>

//     <Stack pl={1.5}>
//       <Typography
//         variant="boxHeading"
//         color="disabled.customs"
//         sx={{
//           cursor: "pointer",
//           textDecoration: "none",
//           "&:hover": {
//             textDecoration: "underline",
//           },
//         }}
//       >
//         {doc.title}
//       </Typography>

//       <Typography
//         pb={1}
//         variant="tableSemiHeading"
//         color="disabled.boxHeading"
//       >
//         {doc.files.length}{" "}
//         {doc.files.length > 1 ? "files" : "file"}
//       </Typography>
//     </Stack>
//   </Stack>
//   <Box display="flex" alignItems="center">
//     <Chip
//       label={
//         doc?.document_type === "client_level"
//           ? "Personal"
//           : documentRequestTitle
//       }
//       size="small"
//       sx={{
//         marginLeft: 0,
//         padding: "4px",
//         color: `${
//           doc?.document_type === "client_level"
//             ? "#2196F3"
//             : "#3DC583"
//         }`,
//         background: `${
//           doc?.document_type === "client_level"
//             ? "rgba(33, 150, 243, 0.15)"
//             : "rgba(61, 197, 131, 0.15)"
//         }`,
//         // "& .MuiChip-deleteIcon": {
//         //   color: `${
//         //     doc?.document_type === "client_level"
//         //       ? "#2196F3"
//         //       : "#3DC583"
//         //   }`,
//         // },
//       }}
//     />
//     <IconButton
//       id="document-btn"
//       aria-controls={
//         openDocumentMenu ? "document-menu" : undefined
//       }
//       aria-haspopup="true"
//       aria-expanded={openDocumentMenu ? "true" : undefined}
//       onClick={(event) => {
//         handleDocumentClick(event);
//         setMenuFileIndex(i);
//         setMenuFileDownloadURLs(doc.files);
//         setSelectedDocument(doc);
//       }}
//     >
//       <MoreVertIcon />
//     </IconButton>
//   </Box>
// </Stack>
