import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { chatTabData } from "../../utils/fakeData";

const ChatTabTableCard = () => {
  return (
    <TableContainer sx={{ borderRadius: "4px", border: "1px solid #DEDEDE" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "110px" }}>Date</TableCell>
            <TableCell sx={{ width: "90px" }}>Time</TableCell>
            <TableCell sx={{ width: "134px" }}>User/Patient</TableCell>
            <TableCell flex="1">Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {chatTabData?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.calories}</TableCell>
              <TableCell>{row.fat}</TableCell>
              <TableCell>{row.carbs}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ChatTabTableCard;
