import React, { useEffect, useState } from "react";
import BgBox from "../../../common/BgBox";
import HeaderText from "../../../common/HeaderText";
import CardUI from "../../../common/Card";
import { Box, Grid, Typography, Link } from "@mui/material";
import Textfield from "../../../common/Textfield";
import { Link as LinkTo, useLocation, useNavigate } from "react-router-dom";
import AlertMessage from "../../../common/AlertMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  createWorkspaceFunc,
  updateAgentProfile,
} from "../../../actions/Agency";
import MccLogo from "../../../components/MccLogo";
import Cookies from "js-cookie";
import { LoadingButton } from "@mui/lab";

const CreateAgentName = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;

  const agentId = Cookies.get("agent_id_vita");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState(null);
  const [isTouched, setIsTouched] = useState(false);

  const dispatch = useDispatch();
  const agentUpdateProfile = useSelector((state) => state.agentUpdateProfile);
  const { loading, error: errorUpdate, agentInfo } = agentUpdateProfile;

  // console.log("STATE", state);

  useEffect(() => {
    if (!errorUpdate && agentInfo?.ok) {
      navigate("/password-created", { state: { createAgentName: true } });
    }
  }, [agentInfo]);

  useEffect(() => {
    if (state === null) {
      navigate(-1);
    }
  }, []);

  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const createNameHandler = async (e) => {
    e.preventDefault();

    setIsTouched(true);

    if (!firstName) {
      setError("Please enter your first name");
      return;
    }

    const data = {
      first_name: firstName,
      last_name: lastName,
    };

    const agentData = {
      agent_id: agentId,
      agent_profile: data,
    };

    dispatch(updateAgentProfile(agentData));
    setOpen(true);
    setError("");
  };

  if (state === null) {
    return;
  }

  return (
    <>
      {!loading && errorUpdate && (
        <AlertMessage
          message={errorUpdate}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {/* !loading && */}
      {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      <BgBox>
        <CardUI maxWidth={570} paddingTop="86px">
          {/* MCC LOGO */}
          <MccLogo />

          <Grid container component="main">
            <Box
              component="form"
              onSubmit={createNameHandler}
              xs={12}
              display="flex"
              gap="40px"
              flexDirection="column"
              width="100%"
            >
              <Box>
                <Typography
                  variant="body1"
                  className="d-flex"
                  fontSize={16}
                  mb="16px"
                  color="#566875"
                >
                  Step 2 of 2
                </Typography>
                <HeaderText
                  variant="h3"
                  fontSize={48}
                  textContent="What's your name?"
                  lineHeight="116.7%"
                  textAlign="initial"
                />
                <Typography
                  variant="body1"
                  className="d-flex"
                  fontSize={16}
                  mt="16px"
                  color="#566875"
                >
                  Adding your name and profile photo helps your teammates
                  recognize and connect with you more easily.
                </Typography>
              </Box>

              <Box display="flex" gap="16px" width="100%">
                <Textfield
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  variant="outlined"
                  label="First Name"
                  name="firstName"
                  width="100%"
                  errorContent={
                    error && !firstName && "Please enter first name"
                  }
                />
                <Textfield
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  variant="outlined"
                  label="Last Name"
                  name="lastName"
                  width="100%"
                />
              </Box>
              <LoadingButton
                fullWidth
                loading={loading}
                type="submit"
                variant="contained"
                sx={{ padding: "8px 22px", fontSize: 15 }}
              >
                Next
              </LoadingButton>

              {/* <Button
            className="w-100"
            fontSize="15px"
            color="primary"
    
            title="Sign In"
            variant="contained"
            width="100%"
            height="48px"
            // disabled={!fieldVals?.email || !fieldVals?.password} // Disabling it off on purpose
            // onClick={() => onSignIn()}
            // loading={signInLoading}
            loadingColor="primary"
          /> */}
            </Box>
          </Grid>
        </CardUI>
      </BgBox>
    </>
  );
};

export default CreateAgentName;
