import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { dateFormat } from "../../utils/helpers";

const ConsentScreenContent = ({
  initSetupChecked,
  setInitSetupChecked,
  initSetup2Checked,
  setInitSetup2Checked,
  patientDetail,
  cptCodeNotes,
  setCptCodeNotes,
}) => {
  return (
    <Stack gap="24px">
      <Alert severity="info" sx={{ width: "100%", textAlign: "center" }}>
        Please activate the patient before you proceed.{" "}
      </Alert>
      <Stack gap="8px" padding="8px" borderRadius="8px" bgcolor="#F4F6F8">
        <Typography variant="body1" color="#212121" fontWeight={500}>
          Patient Details
        </Typography>
        <Box display="flex" width="100%">
          <Stack gap="4px" width="50%">
            <Typography color="secondary" variant="body2">
              Name
            </Typography>
            <Typography variant="body1">
              {patientDetail?.patient?.name
                ? patientDetail?.patient?.name
                : "-"}
            </Typography>
          </Stack>
          <Stack gap="4px" width="50%">
            <Typography color="secondary" variant="body2">
              DOB
            </Typography>
            <Typography variant="body1">
              {" "}
              {patientDetail?.patient?.dob
                ? dateFormat(patientDetail?.patient?.dob)
                : "-"}
            </Typography>
          </Stack>
        </Box>
        <Box display="flex" width="100%">
          <Stack gap="4px" width="50%">
            <Typography color="secondary" variant="body2">
              Contact Number
            </Typography>
            <Typography variant="body1">
              {" "}
              {patientDetail?.patient?.contact_number
                ? patientDetail?.patient?.contact_number
                : "-"}
            </Typography>
          </Stack>
          <Stack gap="4px" width="50%">
            <Typography color="secondary" variant="body2">
              Email
            </Typography>
            <Typography variant="body1">
              {" "}
              {patientDetail?.patient?.email
                ? patientDetail?.patient?.email
                : "-"}
            </Typography>
          </Stack>
        </Box>
      </Stack>
      <Stack gap="16px">
        <FormControlLabel
          sx={{ marginRight: "0", flex: "1", alignItems: "flex-start" }}
          control={
            <Checkbox
              sx={{ padding: "1px 9px" }}
              checked={initSetupChecked}
              onChange={(e) => setInitSetupChecked(e.target.checked)}
            />
          }
          label="The initial setup of the equipment has been completed"
        />
        <FormControlLabel
          sx={{ marginRight: "0", flex: "1", alignItems: "flex-start" }}
          control={
            <Checkbox
              sx={{ padding: "1px 9px" }}
              checked={initSetup2Checked}
              onChange={(e) => setInitSetup2Checked(e.target.checked)}
            />
          }
          label="The patient has been educated on how to use the equipment"
        />
      </Stack>
      <TextField
        id="patient-interaction-notes"
        placeholder="Patient Interaction Notes"
        multiline
        rows={7}
        value={cptCodeNotes}
        onChange={(e) => setCptCodeNotes(e.target.value)}
      />
    </Stack>
  );
};

export default ConsentScreenContent;
