import React, { useState } from "react";
import { Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarIcon from "../../icons/CalendarIcon";

const CustomPainDateRange = ({
  fromCustomDate,
  toCustomDate,
  setFromCustomDate,
  setToCustomDate,
  isTouchedCustomDate,
}) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openToDatePicker, setOpenToDatePicker] = useState(false);

  return (
    <Stack gap="24px">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="From"
          value={fromCustomDate}
          onChange={(newValue) => setFromCustomDate(newValue)}
          onOpen={() => setOpenDatePicker(true)}
          onClose={() => setOpenDatePicker(false)}
          open={openDatePicker}
          slotProps={{
            textField: {
              fullWidth: true,
              error: isTouchedCustomDate && !fromCustomDate,
              onClick: () => setOpenDatePicker(true),
            },
          }}
          sx={{ marginTop: 1 }}
          disableFuture
          slots={{
            openPickerIcon: CalendarIcon,
          }}
          textField={(params) => <TextField {...params} readOnly />}
        />
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="To"
          value={toCustomDate}
          onChange={(newValue) => setToCustomDate(newValue)}
          onOpen={() => setOpenToDatePicker(true)}
          onClose={() => setOpenToDatePicker(false)}
          open={openToDatePicker}
          slotProps={{
            textField: {
              fullWidth: true,
              error: isTouchedCustomDate && !toCustomDate,
              onClick: () => setOpenToDatePicker(true),
            },
          }}
          disabled={fromCustomDate ? false : true}
          minDate={fromCustomDate}
          disableFuture
          textField={(params) => <TextField {...params} readOnly />}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
          sx={{ marginTop: 1 }}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default CustomPainDateRange;
